import { useMemo } from "react";
import * as React from "react";
import { View, StyleSheet } from "react-native";

import { VStack } from "@smartrent/ui";

import { useHub, useHubAccountQuery } from "@/api/hub";
import { useUnit } from "@/api/units";
import { useAccountQuery } from "@/api/zipato";
import { useIsDesktop } from "@/lib/hooks";

import Layout from "@/layout/Layout";
import { useUser } from "@/layout/Context";
import NoRecords from "@/common/NoRecords";
import { LinkTree, LinkItem } from "@/common/LinkTree";
import { ManagerQuickActions } from "@/common/links/SmartLinks";
import { useDocumentTitle } from "@/hooks/use-document-title";

import { OrgHeader } from "./OrgHeader";
import { ControlCenter } from "./ControlCenter";
import { QuickControls } from "./QuickControls";
import { HubInfo } from "./HubInfo";
import { SmartRentHubInfo } from "./SmartRentHubInfo";
import Headlines from "./Headlines";

export interface HubPageProps {
  serial: string;
}

export const HubPage: React.FC<React.PropsWithChildren<HubPageProps>> = ({
  serial,
}) => {
  useDocumentTitle("Hub Details");
  const isDesktop = useIsDesktop();
  const { permissions } = useUser();

  const { data: hub, isLoading: hubLoading } = useHub({
    serial,
  });
  const { data: hubAccount, isError: hubAccountNotFound } = useHubAccountQuery(
    { serial },
    { enabled: !hubLoading && hub?.type === "zipato" }
  );
  const { data: zipato } = useAccountQuery(
    { serial },
    { enabled: !!hub?.serial && hub?.type === "zipato" }
  );

  const { data: unit } = useUnit(hub?.unit_id);

  const organization = useMemo(() => unit?.organization, [unit]);

  const cmwAndZipatoOutOfSync = useMemo(
    () =>
      zipato && hubAccount && !!zipato.zipato_user_id !== hubAccount.attached,
    [zipato, hubAccount]
  );

  const linkTreeLinks = useMemo(() => {
    if (organization && !hub?.deleted_at) {
      const links: LinkItem[] = [
        {
          label: isDesktop ? organization?.name : "Org",
          href: `/organizations/${organization.id}`,
        },
        {
          label: isDesktop ? unit?.group?.marketing_name : "Group",
          href: `/groups/${unit?.group?.id}`,
        },
        {
          label: isDesktop ? unit?.unit.marketing_name : "Unit",
          href: `/units/${unit?.unit?.id}`,
        },
      ];
      if (isDesktop) {
        links.push({ label: serial });
      }
      return links;
    } else {
      return [{ label: serial }];
    }
  }, [organization, unit, serial, isDesktop, hub?.deleted_at]);

  const emptyMessage = useMemo(() => {
    return !permissions.troubleshoot
      ? "Hub Troubleshooting Permission Required"
      : "Hub Not Found";
  }, [permissions]);

  return (
    <Layout>
      <Headlines cmwAndZipatoOutOfSync={cmwAndZipatoOutOfSync} />
      <View style={styles.pageContainer}>
        <LinkTree
          links={linkTreeLinks}
          showCurrent={isDesktop || linkTreeLinks.length === 1}
        />
        {!permissions.troubleshoot ? (
          <View style={styles.noRecordContainer}>
            <NoRecords title={emptyMessage} text={serial} />
          </View>
        ) : (
          <VStack spacing={16}>
            <OrgHeader serial={serial} noHubAccount={!!hubAccountNotFound} />
            {unit?.unit?.id && permissions.core_platform_access ? (
              <ManagerQuickActions
                smartLinks={[
                  {
                    title: "Hub Overview",
                    href: `/manager/groups/${unit?.unit?.group_id}/units/${unit?.unit?.id}/installer/device-setup/add-new-zwave-device`,
                    organizationUrl: organization?.url,
                  },
                ]}
              />
            ) : null}
            <HubInfo hub={hub} serial={serial} />
            <ControlCenter unit={unit} hubSerial={serial} hub={hub} />
            {permissions.advanced_hub_troubleshooting ? (
              <SmartRentHubInfo hub={hub} />
            ) : null}
            {hub ? (
              <QuickControls unit={unit} hub={hub} hubAccount={hubAccount} />
            ) : null}
          </VStack>
        )}
      </View>
    </Layout>
  );
};

const styles = StyleSheet.create({
  pageContainer: {
    padding: 24,
    flexGrow: 1,
  },
  noRecordContainer: {
    flexGrow: 1,
    justifyContent: "center",
  },
});
