import { ColumnDef } from "@tanstack/react-table";

import { UnitResponse } from "@/_v2/api/units";
import { Checkbox } from "@/_v2/components/ui/checkbox";

export const columns: ColumnDef<UnitResponse>[] = [
  {
    id: "select",
    header: () => <Checkbox disabled={true} />,
    cell: ({ row }) => (
      <Checkbox
        checked={row.getIsSelected()}
        onCheckedChange={(value) => row.toggleSelected(!!value)}
        aria-label="Select group"
      />
    ),
    enableSorting: false,
    enableHiding: false,
    size: 25,
    maxSize: 25,
  },
  {
    accessorKey: "id",
    header: "Id",
    enableSorting: false,
    size: 50,
    maxSize: 50,
  },
  {
    accessorKey: "marketing_name",
    header: "Name",
    enableSorting: true,
    size: 200,
  },
  {
    accessorKey: "status",
    header: "Status",
    enableSorting: true,
    size: 100,
  },
  {
    accessorKey: "building",
    header: "Building",
    enableSorting: true,
    size: 100,
  },
  {
    accessorKey: "floor",
    header: "Floor",
    enableSorting: true,
    size: 100,
  },
];
