import { useMemo, useState, useCallback } from "react";
import * as React from "react";
import {
  FlatList,
  FlatListProps,
  ListRenderItem,
  StyleSheet,
  ScrollView,
  StyleProp,
  ViewStyle,
  View,
} from "react-native";
import { mapValues } from "lodash-es";

import { Box, LegacyCardFlatList, useTheme } from "@smartrent/ui";
import { ChevronRight } from "@smartrent/icons";

import { useIsDesktop } from "@/hooks/breakpoints";
import { SortDirection, SortChangeHandler } from "@/hooks/url-sort";

import {
  BaseRecord,
  Column,
  ColumnOptions,
  FilterValues,
  FilterChangeHandler,
} from "./types";

import { TitleHeader } from "./TitleHeader";
import { ColumnHeaders } from "./ColumnHeaders";
import { LoadingRow } from "./LoadingRow";
import { NoRecords } from "./NoRecords";
import { Row, RowProps } from "./Row";
import { GenericCardRow } from "./GenericCardRow";
import { RowSeparator, CardSeparator } from "./Separator";
import { TableFilters } from "./TableFilters";
import { TablePaginator } from "./TablePaginator";
import { checkColumnNameUniqueness } from "./utils";

export interface TableProps<TRecord extends BaseRecord> {
  /**
   * Column definitions for the table.
   *
   * **Must be memoized.**
   */
  columns: ColumnOptions<TRecord>[];

  /**
   * The data to display in the table.
   *
   * **Must be memoized.**
   */
  data: TRecord[];

  /**
   * Used to extract a unique key for a given item at the specified index.
   *
   * **Must be memoized.**
   */
  keyExtractor?: (value: TRecord, index: number) => string;

  /**
   * Title that appears in the table's header.
   */
  title?: string;

  /**
   * Action that appears in the table's header.
   */
  action?: React.ReactNode;

  /**
   * Additional content to display in the table footer.
   */
  footer?: React.ReactNode;

  /**
   * Whether to break to card views on non-desktop screens.
   *
   * @default true
   */
  responsive?: boolean;

  /**
   * Loading indicates whether data for the table is currently being fetched
   * **and** there is no cached data (stale or otherwise) to display.
   */
  loading?: boolean;

  /**
   * Fetching indicates whether data for the table is currently being fetched
   * regardless of whether there is cached data to display.
   */
  fetching?: boolean;

  /**
   * Short message displayed when there are no rows to display **and** fetching
   * is false.
   *
   * @default "No Records Found"
   */
  noRecordsText?: string;

  /**
   * If provided, rows will be clickable and a right chevron icon will be rendered
   * in the row's last column.
   *
   * **Must be memoized.**
   */
  onRowPress?: (record: TRecord) => void;

  /**
   * If provided, will be called when rendering each row to determine whether to
   * show the right chevron and enable the `onRowPress` handler.
   *
   * If this prop is not provided but `onRowPress` is, then all rows are assumed
   * to be pressable.
   */
  isRowPressable?: (record: TRecord) => boolean;

  /**
   * Number of records displayed per page. Currently, this only determines the
   * number of placeholder rows to display when the table is loading.
   *
   * @default 25
   */
  pageSize?: number;

  /**
   * The current page.
   *
   * @default 1
   */
  currentPage?: number;

  /**
   * The total number of pages. If 0 or 1, the paginator will not be rendered.
   *
   * @default 0
   */
  totalPages?: number;

  /**
   * The total number of records available.
   */
  totalRecords?: number;

  /**
   * Callback fired with the requested page when paginating.
   *
   * **Must be memoized.**
   */
  onPageChange?: (page: number) => void;

  /**
   * The column that is currently being sorted.
   */
  sortColumn?: string;

  /**
   * The current sort direction.
   *
   * @default "asc"
   */
  sortDirection?: SortDirection;

  /**
   * Callback fired with the requested sort column and direction.
   *
   * **Must be memoized.**
   */
  onSortChange?: SortChangeHandler;

  /**
   * The currently applied filter values.
   *
   * **Must be memoized.**
   */
  filters?: FilterValues;

  /**
   * Callback fired with the requested filter values.
   *
   * **Must be memoized.**
   */
  onFiltersChange?: FilterChangeHandler;

  /**
   * Title for the filter view. This is currently a Drawer, but may change to a
   * popover menu in the future.
   *
   * @default "Filter"
   */
  filterViewTitle?: string;

  /**
   * Additional styles to apply to the root element.
   */
  style?: StyleProp<ViewStyle>;

  /**
   * Add a clear filters action to drawer.
   *
   * @default false
   */
  drawerClearFiltersAction?: boolean;

  /**
   * Add a clear filters action to table.
   *
   * @default false
   */
  tableClearFiltersAction?: boolean;
}

export function Table<TRecord extends BaseRecord>({
  columns: originalColumns,
  data: originalData,
  keyExtractor: originalKeyExtractor,

  title,
  action,
  footer,
  responsive = true,
  loading,
  fetching,
  noRecordsText = "No Records Found",
  onRowPress,
  isRowPressable = () => true,
  drawerClearFiltersAction = false,
  tableClearFiltersAction = false,

  pageSize = 25,
  currentPage = 1,
  totalPages = 0,
  totalRecords = 0,
  onPageChange = () => undefined,

  sortColumn,
  sortDirection = "asc",
  onSortChange = () => undefined,

  filters,
  onFiltersChange,
  filterViewTitle = "Filter",

  style,
}: TableProps<TRecord>) {
  // Manage filter view visibility
  const [filtersVisible, setFiltersVisible] = useState(false);
  const { colors } = useTheme();

  const showFilters = useCallback(() => {
    setFiltersVisible(true);
  }, []);

  const hideFilters = useCallback(() => {
    setFiltersVisible(false);
  }, []);

  const breakpointIsDesktop = useIsDesktop();
  const isDesktop = responsive ? breakpointIsDesktop : true;

  // Memoize all columns
  const allColumns = useMemo(() => {
    // If onRowPress was provided, render a chevron in the rightmost column
    const columns =
      onRowPress && isDesktop
        ? originalColumns.concat([
            {
              name: "__chevron_right__",
              header: "",
              render: ({ row }) =>
                isRowPressable(row) ? <ChevronRight /> : null,
              maxWidth: 24,
              minWidth: 24,
            },
          ])
        : originalColumns;

    if (!import.meta.env.PROD) {
      checkColumnNameUniqueness(columns);
    }

    // Augment the input ColumnOptions with all required fields
    return columns.map((col): Column<TRecord> => {
      return {
        ...col,
        sortDirection:
          col.sortable && col.name === sortColumn ? sortDirection : undefined,
        relativeWidth: col.relativeWidth ?? 150,
      };
    });
  }, [
    onRowPress,
    isDesktop,
    originalColumns,
    isRowPressable,
    sortColumn,
    sortDirection,
  ]);

  // Filter out hidden columns
  const columns = useMemo(
    () => allColumns.filter((c) => !c.hidden),
    [allColumns]
  );

  const hasFilters = useMemo(
    () => allColumns.some((c) => c.filter),
    [allColumns]
  );

  // Memoize placeholder FlatList props
  const placeholderData = useMemo(() => new Array(pageSize), [pageSize]);
  const placeholdersRenderItem = useCallback(() => <LoadingRow />, []);
  const placeholdersKeyExtractor = useCallback(
    (_item: any, index: number) => String(index),
    []
  );

  const rows = useMemo(() => {
    return originalData.map(
      (value): RowProps<TRecord> => ({
        value,
        columns,
        onPress: isRowPressable(value) ? onRowPress : undefined,
      })
    );
  }, [originalData, columns, isRowPressable, onRowPress]);

  const isEmpty = rows.length === 0;
  const showPlaceholders = fetching || loading;

  // Memoize data FlatList props
  const dataKeyExtractor: FlatListProps<RowProps<TRecord>>["keyExtractor"] =
    useMemo(() => {
      if (originalKeyExtractor) {
        return (row, index) => originalKeyExtractor(row.value, index);
      }

      return (_row, index) => String(index);
    }, [originalKeyExtractor]);

  const dataRenderItem = useCallback<ListRenderItem<RowProps<TRecord>>>(
    ({ item }) =>
      isDesktop ? <Row {...item} /> : <GenericCardRow {...item} />,
    [isDesktop]
  );

  const handleClearFields = useCallback(() => {
    onFiltersChange && onFiltersChange(mapValues(filters, () => ""));
  }, [filters, onFiltersChange]);

  const header = useMemo(
    () => (
      <TitleHeader
        title={title}
        action={action}
        hasFilters={hasFilters}
        onFilterButtonPress={showFilters}
        tableClearFiltersAction={tableClearFiltersAction}
        handleClearFields={handleClearFields}
      />
    ),
    [
      title,
      action,
      hasFilters,
      showFilters,
      tableClearFiltersAction,
      handleClearFields,
    ]
  );

  const columnHeaders = useMemo(
    () => (
      <ColumnHeaders
        columns={isDesktop ? columns : undefined}
        onSortChange={onSortChange}
      />
    ),
    [columns, isDesktop, onSortChange]
  );

  const noRecords = useMemo(
    () => <NoRecords title={noRecordsText} />,
    [noRecordsText]
  );

  const paginator =
    onPageChange && totalPages > 1 ? (
      <TablePaginator
        totalRecords={totalRecords}
        currentPage={currentPage}
        totalPages={totalPages}
        pageSize={pageSize}
        isLoading={loading}
        isEmpty={isEmpty}
        onPageChange={onPageChange}
      />
    ) : null;

  return (
    <>
      {hasFilters ? (
        <TableFilters
          columns={allColumns}
          filters={filters}
          title={filterViewTitle}
          onFiltersChange={onFiltersChange}
          open={filtersVisible}
          onClose={hideFilters}
          drawerClearFiltersAction={drawerClearFiltersAction}
          onClearFilters={handleClearFields}
        />
      ) : null}
      {isDesktop ? (
        <Box style={[styles.root, style]}>
          {header}
          <ScrollView
            horizontal
            contentContainerStyle={styles.overflowScrollContentContainer}
          >
            <FlatList
              data={showPlaceholders ? placeholderData : rows}
              keyExtractor={
                showPlaceholders ? placeholdersKeyExtractor : dataKeyExtractor
              }
              renderItem={
                showPlaceholders ? placeholdersRenderItem : dataRenderItem
              }
              ItemSeparatorComponent={RowSeparator}
              ListHeaderComponent={columnHeaders}
              ListEmptyComponent={noRecords}
            />
          </ScrollView>
          {paginator || footer ? (
            <View
              style={[
                styles.footer,
                {
                  borderTopColor: colors.border,
                  backgroundColor: colors.raisedSurfaces,
                },
              ]}
            >
              {paginator}
              {footer}
            </View>
          ) : null}
        </Box>
      ) : (
        <Box style={[styles.root, style]}>
          <LegacyCardFlatList
            data={showPlaceholders ? placeholderData : rows}
            keyExtractor={
              showPlaceholders ? placeholdersKeyExtractor : dataKeyExtractor
            }
            renderItem={
              showPlaceholders ? placeholdersRenderItem : dataRenderItem
            }
            ItemSeparatorComponent={CardSeparator}
            ListHeaderComponent={header}
            ListEmptyComponent={noRecords}
            contentContainerStyle={style}
          />
          {paginator || footer ? (
            <View
              style={[
                styles.footer,
                {
                  borderTopColor: colors.border,
                  backgroundColor: colors.raisedSurfaces,
                },
              ]}
            >
              {paginator}
              {footer}
            </View>
          ) : null}
        </Box>
      )}
    </>
  );
}

const styles = StyleSheet.create({
  root: {
    padding: 0,
  },
  overflowScrollContentContainer: {
    minWidth: "100%",
  },
  footer: {
    borderTopWidth: 1,
  },
});
