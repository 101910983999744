import { useEffect } from "react";
import { MagnifyingGlassIcon } from "@radix-ui/react-icons";

import { useDebounce } from "@/lib/hooks";
import Helpers from "@/lib/helpers";

import { Input } from "@/_v2/components/ui/input";
import { cn } from "@/_v2/utils";

export default function SearchInput({
  setSearchResults,
  setSearchError,
  keywords,
  setKeywords,
  setSearchLoading,
}) {
  const debouncedKeywords = useDebounce(keywords, 800);

  useEffect(() => {
    if (debouncedKeywords.length <= 2) {
      return;
    }
    setSearchLoading(true);
    setSearchError(null);
    Helpers.fetch({
      path: `/api/search`,
      query: { keywords: debouncedKeywords },
    })
      .then(({ rows, error }) => {
        setSearchResults(rows);
        setSearchLoading(false);
        if (error) {
          setSearchError(error);
        }
      })
      .catch((err) => {
        setSearchError("An unknown error occurred. Please try again.");
      });
  }, [debouncedKeywords, setSearchError, setSearchLoading, setSearchResults]);

  return (
    <div className="flex items-center pl-2 border-border border border-solid bg-secondary rounded-md w-full lg:w-1/5 focus-within:bg-input transition-all">
      <MagnifyingGlassIcon className="h-4 w-4 shrink-0 opacity-50" />
      <Input
        className={cn(
          "flex h-[30px] w-full border-none rounded-md py-0.5 pl-2 pr-1 shadow-none text-sm outline-none",
          "placeholder:text-muted-foreground disabled:cursor-not-allowed disabled:opacity-50 focus-visible:ring-0"
        )}
        type="text"
        placeholder="Search for orgs, groups, users, hubs, etc."
        autoFocus={true}
        value={keywords}
        onChange={(e) => setKeywords(e.currentTarget.value)}
      />
    </div>
  );
}
