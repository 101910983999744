import { DataTable } from "@/_v2/components/composites/data-table/data-table";
import { useDataTableSorting } from "@/_v2/components/composites/data-table/data-table-sorting";
import { useDataTableFilters } from "@/_v2/components/composites/data-table/data-table-filters";
import { DataTableFiltersQuickSearch } from "@/_v2/components/composites/data-table/data-table-filters/data-table-filters-quick-search";

import { useAccountsQuery } from "@/api";

import { columns } from "./accounts-table-columns";

export const AccountsTable = () => {
  const sorting = useDataTableSorting([]);
  const filters = useDataTableFilters([]);

  const { data, isFetching } = useAccountsQuery({
    sortBy: sorting[0][0]?.id,
    sortDir: sorting[0][0]?.desc ? "desc" : "asc",
    filters: filters[0],
  });

  return (
    <DataTable
      columns={columns}
      data={data?.records || []}
      rowCount={data?.total_records || 0}
      loading={isFetching}
      sorting={sorting}
      filters={filters}
    >
      {(table) => (
        <DataTableFiltersQuickSearch
          table={table}
          columnId="email"
          placeholder="Search by email..."
        />
      )}
    </DataTable>
  );
};
