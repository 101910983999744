import { View, StyleSheet } from "react-native";

import { Swap } from "@smartrent/icons";
import { Stack } from "@smartrent/ui";

import Layout from "@/layout/Layout";
import { useUser } from "@/layout/Context";

import { GroupsTable } from "@/common/GroupsTable";
import { VendorTable } from "@/common/vendor-applications/VendorTable";
import { ManagerQuickActions, QuickActions } from "@/common/links/SmartLinks";
import { Accordion } from "@/common/Accordion";
import { useDrawerNav } from "@/common/AppDrawer";

import { useIsMobileCard } from "@/hooks/breakpoints";
import { useDocumentTitle } from "@/hooks/use-document-title";

import { useOrganization } from "@/pages/organization/OrganizationContext";

import { IntegrationCredentialsTable } from "./organization/IntegrationCredentials";

import { OrganizationDeviceBreakdownContainer } from "./organization/OrganizationDeviceBreakdownContainer";

// Unit Components
import OrganizationInfo from "./organization/OrganizationInfo";

import { OrganizationIntegrationsCard } from "./organization/OrganizationIntegrations/OrganizationIntegrationsCard";
import { OrganizationUsersCard } from "./organization/OrganizationUsersCard";

export default function Organization() {
  useDocumentTitle("Organization Details");

  const isMobileCard = useIsMobileCard();
  const { organization } = useOrganization();
  const { permissions } = useUser();
  const drawer = useDrawerNav();

  return (
    <Layout>
      <View style={styles.content}>
        <Stack
          width="100%"
          flexChildren={true}
          spacing={8}
          direction={isMobileCard ? "column" : "row"}
        >
          {permissions.core_platform_access ? (
            <ManagerQuickActions
              smartLinks={[
                {
                  title: "Organization Overview",
                  href: "/manager/groups",
                  organizationUrl: organization.url,
                },
              ]}
            />
          ) : null}
          {permissions.property_transfer ? (
            <QuickActions
              smartLinks={[
                {
                  title: "Unit Transfer",
                  onClick: () => drawer.push("unitTransfer", { organization }),
                  icon: Swap,
                },
                {
                  title: "Property Transfer",
                  onClick: () =>
                    drawer.push("propertyTransfer", { organization }),
                  icon: Swap,
                },
              ]}
            />
          ) : null}
        </Stack>
        <View style={styles.flexRow}>
          <View
            style={
              isMobileCard
                ? mobileStyles.cardWithActionsWrapper
                : styles.cardWithActionsWrapper
            }
          >
            <OrganizationInfo />
          </View>
          <View
            style={isMobileCard ? mobileStyles.cardWrapper : styles.cardWrapper}
          >
            <OrganizationIntegrationsCard />
          </View>
          <View
            style={isMobileCard ? mobileStyles.cardWrapper : styles.cardWrapper}
          >
            <OrganizationUsersCard />
          </View>
        </View>
        {permissions.integration_credentials_v2 ? (
          <View style={isMobileCard ? mobileStyles.table : styles.table}>
            <IntegrationCredentialsTable />
          </View>
        ) : null}
        {permissions?.view_groups ? (
          <View style={styles.table}>
            <GroupsTable />
          </View>
        ) : null}
        <View style={isMobileCard ? mobileStyles.table : styles.table}>
          <Accordion title="Organization IoT Health" initialOpenState={false}>
            <OrganizationDeviceBreakdownContainer />
          </Accordion>
        </View>
        {permissions?.view_vendor_applications ? (
          <View style={isMobileCard ? mobileStyles.table : styles.table}>
            <VendorTable />
          </View>
        ) : null}
      </View>
    </Layout>
  );
}

const mobileStyles = StyleSheet.create({
  cardWrapper: {
    width: "100%",
    marginRight: 8,
    marginBottom: 8,
  },
  cardWithActionsWrapper: {
    width: "100%",
    marginRight: 8,
    marginBottom: 8,
    zIndex: 9999,
  },
  table: {
    width: "100%",
    marginBottom: 8,
  },
});

const styles = StyleSheet.create({
  content: {
    margin: 16,
  },
  table: {
    marginBottom: 16,
  },
  flexRow: {
    flexDirection: "row",
    flexWrap: "wrap",
    flex: 1,
    zIndex: 999, // modal uses this as its container, so it needs to display over the tables
  },
  cardWrapper: {
    minWidth: 480,
    marginRight: 16,
    marginBottom: 16,
  },
  cardWithActionsWrapper: {
    minWidth: 480,
    marginRight: 16,
    marginBottom: 16,
    zIndex: 9999, // displays actions modal over all other cards
  },
});
