import * as React from "react";
import { View, StyleSheet } from "react-native";

import Layout from "@/layout/Layout";

import { UrlOrgGroupBreadcrumbs } from "@/common/breadcrumbs/UrlOrgGroupBreadcrumbs";
import { useDocumentTitle } from "@/hooks/use-document-title";

import { UsersTable } from "./UsersTable";

export const UsersContainer: React.VFC = () => {
  useDocumentTitle("Users");

  return (
    <Layout>
      <View style={styles.linkTree}>
        <UrlOrgGroupBreadcrumbs />
      </View>
      <View style={styles.container}>
        <UsersTable />
      </View>
    </Layout>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: 24,
  },
  linkTree: {
    padding: 24,
    alignItems: "flex-start",
    alignContent: "flex-start",
  },
});
