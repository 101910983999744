import { useContext, useState, useEffect } from "react";
import { View, StyleSheet } from "react-native";
import {
  ActivityIndicator,
  Button,
  Typography,
  Tooltip,
  useTheme,
} from "@smartrent/ui";
import { Refresh, QuestionCircleBorder } from "@smartrent/icons";

import format from "date-fns/format";

import Context from "@/layout/Context";
import { useApi, useInterval, useIsDesktop } from "@/lib/hooks";
import { EM_DASH } from "@/utils/chars";

interface NumberMetricProps {
  refetchInterval?: number;
  path?: string;
  dataKey: string;
  label?: string;
  defaultValue?: any;
  tooltip?: { title: string; name: string };
  canBeRefreshed?: boolean;
}

const formatNumber = (number: number) => {
  const lookup = [
    { value: 1e18, symbol: "E" },
    { value: 1e15, symbol: "P" },
    { value: 1e12, symbol: "T" },
    { value: 1e9, symbol: "G" },
    { value: 1e6, symbol: "M" },
    { value: 1e3, symbol: "k" },
    { value: 1, symbol: "" },
  ];
  // removes trailing zeroes
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  const item = lookup.find((item) => {
    return number >= item.value;
  });
  return item
    ? (number / item.value).toFixed(1).replace(rx, "$1") + item.symbol
    : "0";
};

export const NumberMetricWithLabel = ({
  refetchInterval,
  path,
  dataKey,
  label,
  defaultValue,
  tooltip,
  canBeRefreshed = true,
}: NumberMetricProps) => {
  const { location } = useContext(Context);
  const { colors } = useTheme();
  const isDesktop = useIsDesktop();
  const [data, setData] = useState(defaultValue || null);
  const { response, loading, reFetch } = useApi({
    url: `${path}${location.search ? location.search : ""}`,
    trigger: [location.search],
  });

  useEffect(() => {
    if (response && response.data && response.data[dataKey]) {
      setData(response.data[dataKey]);
    }
  }, [response, dataKey]);

  useInterval(() => {
    if (refetchInterval && reFetch) {
      reFetch();
    }
  }, refetchInterval);

  return (
    <View style={isDesktop ? styles.desktopBox : styles.mobileBox}>
      <View
        style={
          canBeRefreshed
            ? styles.tooltipAndRefreshIcons
            : styles.rightAlignTooltip
        }
      >
        {tooltip ? (
          <Tooltip title={tooltip.title} name={tooltip.name}>
            <View>
              <QuestionCircleBorder />
            </View>
          </Tooltip>
        ) : (
          <View />
        )}
        {canBeRefreshed ? (
          <Tooltip
            title={`Last Refresh: ${format(new Date(), "hh:mm:ss b")}`}
            name="last_refresh"
            placement="right"
          >
            <Button variation="plain" onPress={reFetch}>
              <Refresh size={20} color={colors.iconsWithinComponents} />
            </Button>
          </Tooltip>
        ) : null}
      </View>

      <View
        style={[
          styles.metric,
          {
            borderColor: data ? colors.secondary : colors.gray300,
            backgroundColor: colors.inputBackground,
          },
        ]}
      >
        {loading && !data ? (
          <View style={styles.loadingPlaceholder}>
            <ActivityIndicator size={64} />
          </View>
        ) : (
          <Tooltip
            name="actual_number"
            title={
              data
                ? parseInt(data, 10).toLocaleString()
                : "No result found. Please try again later."
            }
            placement="right"
          >
            <Typography type="title">
              {data ? formatNumber(data) : EM_DASH}
            </Typography>
          </Tooltip>
        )}
      </View>

      <Typography type="caption" color="textSecondary">
        {label}
      </Typography>
    </View>
  );
};

const styles = StyleSheet.create({
  desktopBox: { margin: 8, width: 250, alignItems: "center" },
  mobileBox: { margin: 8, alignItems: "center" },
  tooltipAndRefreshIcons: {
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
  },
  rightAlignTooltip: {
    flexDirection: "row",
    justifyContent: "flex-end",
    width: "100%",
  },
  loadingPlaceholder: {
    width: 150,
    height: 150,
    justifyContent: "center",
    alignItems: "center",
  },
  metric: {
    marginBottom: 16,
    width: 150,
    height: 150,
    borderRadius: 150 / 2,
    borderWidth: 3,
    justifyContent: "center",
    alignItems: "center",
  },
});
