import { createAxiosMutation, createAxiosQuery } from "@/hooks/react-query";
import { instance } from "@/lib/hooks";
import helpers from "@/lib/helpers";

import { AccountProps, Role } from "@/types";

export interface AccountsResponse {
  current_page: number;
  records: AccountProps[];
  total_records: number;
  total_pages: number;
}

export interface AccountQueryParams {
  // page: number;
  // limit: number;
  sortBy: string;
  sortDir: "asc" | "desc";
  filters: { id: string; value: any }[];
}

export const useAccountsQuery = createAxiosQuery(
  "accounts",
  async (filters?: AccountQueryParams) => {
    if (filters) {
      filters.sortBy = getSortBy(filters?.sortBy);
    }

    const sortQS = helpers.toQS({
      sortBy: filters?.sortBy,
      sortDir: filters?.sortDir,
    });

    const filtersQS = helpers.filtersToQS(filters?.filters);

    return instance.get<AccountsResponse>(
      `/accounts?${sortQS}${sortQS && filtersQS ? "&" : ""}${filtersQS}`
    );
  }
);

const accountFilters = [
  "id",
  "first_name",
  "last_name",
  "email",
  "inserted_at",
  "updated_at",
];
const roleFilters = ["name"];
const getSortBy = (from: string) => {
  if (from === "account_id") {
    return "accounts.id";
  }
  return accountFilters.includes(from)
    ? `accounts.${from}`
    : roleFilters.includes(from)
      ? `account_roles.${from}`
      : from;
};

export const useUpsertRoleMutation = createAxiosMutation(
  async (values: { name: Role }) => {
    return instance.patch(`/roles`, { role: values.name });
  },
  {
    successToast: () => ({
      message: "Changed role!",
    }),
    errorToast: () => ({
      message: "Failed to change role",
    }),
  }
);
