import * as React from "react";
import { Text, StyleSheet } from "react-native";
import { useTheme } from "@smartrent/ui";

interface TextCellProps {
  children: string;
  numberOfLines?: number;
}

export const TextCell: React.FC<React.PropsWithChildren<TextCellProps>> = ({
  children,
  numberOfLines = 1000,
}) => {
  const { colors } = useTheme();

  return (
    <Text
      numberOfLines={numberOfLines}
      style={(styles.textContent, { color: colors.textPrimary })}
    >
      {children}
    </Text>
  );
};

const styles = StyleSheet.create({
  textContent: {
    flexShrink: 1,
  },
});
