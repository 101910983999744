import { useState } from "react";
import { Drawer } from "vaul";
import groupBy from "lodash-es/groupBy";
import upperCase from "lodash-es/upperCase";

import { SideDrawer } from "@/_v2/components/composites/side-drawer/side-drawer";
import { Button } from "@/_v2/components/ui/button";
import { Checkbox } from "@/_v2/components/ui/checkbox";
import { Input } from "@/_v2/components/ui/input";
import { Label } from "@/_v2/components/ui/label";
import { CopyTextButton } from "@/_v2/components/composites/copy-text-button";

import { hostname } from "@/utils";
import { GROUPED_PERMISSIONS, PERMISSIONS } from "@/lib/permissions";

export const AccountsRoleBuilderDrawer = () => {
  const [roleName, setRoleName] = useState("");

  const [selectedPermissions, setSelectedPermissions] = useState<
    Record<string, boolean>
  >({});

  if (window.location.hostname === hostname.prod) {
    return null;
  }

  const groupedPermissions = groupBy(
    PERMISSIONS,
    (permission) => permission.type
  );

  const PermissionGroup = ({
    type,
    permissions,
  }: {
    type: string;
    permissions: GROUPED_PERMISSIONS[];
  }) => {
    return (
      <div className="w-full flex-wrap">
        <div className="border-b border-border mb-1">
          <p className="text-sm font-semibold">{upperCase(type)}</p>
        </div>
        <div className="flex flex-col space-y-1">
          {permissions.map(({ name, type, label }) => {
            return (
              <div
                key={`${name}-${type}`}
                className="flex space-x-1 items-center"
              >
                <Checkbox
                  id={name}
                  name={name}
                  checked={selectedPermissions[name]}
                  onCheckedChange={(checked) => {
                    if (checked) {
                      setSelectedPermissions({
                        ...selectedPermissions,
                        [name]: true,
                      });
                    } else {
                      const newObjectWithDeletedPermission = {
                        ...selectedPermissions,
                      };
                      delete newObjectWithDeletedPermission[name];

                      setSelectedPermissions(newObjectWithDeletedPermission);
                    }
                  }}
                />
                <label
                  htmlFor={name}
                  className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                >
                  {label}
                </label>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  return (
    <SideDrawer
      title="Role Builder"
      description="Select permissions for a new role"
      trigger={<Button>Build Role</Button>}
    >
      <div className="h-full w-full">
        <div className="w-full">
          <Label htmlFor="roleName">Role Name</Label>
          <Input
            id="roleName"
            value={roleName}
            onChange={(e) => setRoleName(e.target.value)}
            placeholder="Enter role name..."
            required
          />

          <div className="flex space-x-1 pt-4 py-2">
            <Button
              size="sm"
              className="flex-1"
              onClick={() => {
                setSelectedPermissions(
                  PERMISSIONS.reduce(
                    (acc, { name }) => {
                      acc[name] = true;
                      return acc;
                    },
                    {} as Record<string, boolean>
                  )
                );
              }}
            >
              Select All
            </Button>
            <Button
              size="sm"
              className="flex-1"
              onClick={() => {
                setSelectedPermissions({});
              }}
            >
              Clear All
            </Button>
          </div>

          <div className="w-full flex flex-col space-y-4 pb-4">
            {Object.entries(groupedPermissions).map(([key, value]) => (
              <PermissionGroup key={key} type={key} permissions={value} />
            ))}
          </div>
        </div>

        <div className="flex flex-col space-y-2">
          <div className="w-full relative">
            <CopyTextButton
              className="absolute top-0 right-0"
              iconClassName="text-background"
              text={JSON.stringify(
                { name: roleName, ...selectedPermissions },
                null,
                2
              )}
            />

            <div className="flex bg-foreground rounded overflow-hidden">
              <p className="p-4 text-sm whitespace-pre-wrap break-words break-all font-mono text-background rounded">
                {JSON.stringify(
                  { name: roleName, ...selectedPermissions },
                  null,
                  2
                )}
              </p>
            </div>
          </div>
          <Drawer.Close asChild>
            <div className="flex">
              <Button variant="secondary" className="flex-1">
                Close
              </Button>
            </div>
          </Drawer.Close>
        </div>
      </div>
    </SideDrawer>
  );
};
