import { useContext } from "react";
import { Link } from "react-router-dom";
import { startCase } from "lodash-es";
import classNames from "classnames";
import { cva } from "class-variance-authority";
import { Users, HighRiseBuilding, SmartHome, Home } from "@smartrent/icons";

import { cn } from "@/_v2/utils";

import { formatPhone } from "@/utils";

import Context from "../Context";

import Refurbished from "../../pages/unit/hub/Refurbished";

import ExtendedUserSearch from "./ExtendedUserSearch";

type Props = {
  result: {
    hub_serial: null | string;
    unit_name: null | string;
    group_name: null | string;
    resident_name: null | string;
    resident_moved_in: null | string;
    user_name: null | string;
    user_role: null | string;
    email: null | string;
    phone: null | string;
    device_name: null | string;
    organization_name: null | string;
    organization_id: null | string;
    prospect_id: null | string;
    maintenance_vendor_id: null | string;

    zipato_username?: string;
    zipato_environment?:
      | null
      | "Dev v2"
      | "QA / Release v2"
      | "Prod (deprecated)"
      | "Prod v2"
      | "Demo v2"
      | "Prod v3"
      | "Demo v3";

    unit_id: number;
    user_id: number;
    group_id: number;
    hub_id: number;
  };
  callbacks: {
    setKeywords: (string) => void;
    setSearchResults: (results: Object[]) => void;
  };
};

const linkVariants = cva(
  "text-primary underline hover:opacity-80 hover:no-underline transition-opacity",
  {
    variants: {
      size: {
        sm: "text-sm",
        lg: "text-2xl",
      },
    },
    defaultVariants: {
      size: "lg",
    },
  }
);

export default function SearchResult({
  result: {
    hub_serial,
    unit_name,
    group_name,
    resident_name,
    resident_moved_in,
    user_name,
    user_role,
    email,
    phone,
    device_name,
    organization_name,
    organization_id,
    unit_id,
    user_id,
    group_id,
    zipato_username,
    zipato_environment,
    prospect_id,
    maintenance_vendor_id,
  },
  callbacks: { setKeywords, setSearchResults },
}: Props) {
  const { isDesktop } = useContext(Context);

  return (
    <li className="list__item even:bg-background odd:bg-secondary">
      <div className="u-size-full">
        {device_name && <div>{device_name}</div>}
        {organization_name && organization_id ? (
          <div className="text-foreground font-bold text-sm capitalize">
            <Link
              className={cn(linkVariants({ size: "sm" }))}
              onClick={() => {
                setKeywords("");
                setSearchResults([]);
              }}
              to={`/organizations/${organization_id}`}
            >
              {organization_name}
            </Link>
            {prospect_id && " (Prospect)"}
          </div>
        ) : null}

        <div className="u-flex u-flex-justify-between u-flex-wrap u-flex-align-items-start u-size-full">
          <ExtendedUserSearch
            prospect_id={prospect_id}
            maintenance_vendor_id={maintenance_vendor_id}
            name={user_name}
            email={email}
            phone={phone}
            setSearchResults={setSearchResults}
            setKeywords={setKeywords}
          />
          {user_id && Number(user_id) !== -1 && (
            <div
              className="u-flex-grow1"
              style={{
                minWidth: "200px",
                marginBottom: isDesktop ? 0 : "32px",
              }}
            >
              <div className="flex items-center" style={{ lineHeight: 1 }}>
                <span className="u-mright4">
                  <Users size={24} color="hsl(var(--foreground))" />
                </span>
                {Number(user_id) > 0 && (
                  <Link
                    className={cn(linkVariants({ size: "lg" }))}
                    onClick={() => {
                      setKeywords("");
                      setSearchResults([]);
                    }}
                    to={`/users/${user_id}`}
                  >
                    {user_name || resident_name}
                  </Link>
                )}
                {Number(user_id) <= 0 && (
                  <span className="text-2xl text-muted-foreground">
                    {user_name || resident_name}
                  </span>
                )}
              </div>
              <div className="text-muted-foreground text-sm">
                {user_id ? "SmartRent User" : "Resident"}
              </div>
              <div className="u-line18 text-base">
                {resident_name && (
                  <div
                    className={classNames({
                      "u-text-symbolic-success": resident_moved_in,
                      "text-muted-foreground": !resident_moved_in,
                    })}
                  >
                    Moved {resident_moved_in ? "In" : "Out"}
                  </div>
                )}
                <div>
                  {email && (
                    <div className="text-muted-foreground">{email}</div>
                  )}
                  {phone && (
                    <div className="text-muted-foreground">
                      {formatPhone({ phone })}
                    </div>
                  )}
                </div>
                {user_role && (
                  <div className="text-muted-foreground">
                    {startCase(user_role)}
                  </div>
                )}
              </div>
            </div>
          )}

          {unit_id && Number(unit_id) !== -1 && (
            <div
              className="u-flex-grow1"
              style={{
                minWidth: "200px",
                marginBottom: isDesktop ? 0 : "32px",
              }}
            >
              <div>
                <div className="flex items-center" style={{ lineHeight: 1 }}>
                  <span className="u-mright4">
                    <Home size={24} color="hsl(var(--foreground))" />
                  </span>
                  <Link
                    className={cn(linkVariants({ size: "lg" }))}
                    onClick={() => {
                      setKeywords("");
                      setSearchResults([]);
                    }}
                    to={`/units/${unit_id}`}
                  >
                    {unit_name}
                  </Link>
                </div>
                <div className="text-muted-foreground text-sm">Unit</div>
                {hub_serial && (
                  <div className="text-base text-muted-foreground">
                    {hub_serial}
                  </div>
                )}
                {hub_serial && <Refurbished serial={hub_serial} />}
              </div>
            </div>
          )}

          {group_id && Number(group_id) !== -1 && (
            <div
              className="u-flex-grow1"
              style={{
                minWidth: "200px",
                marginBottom: isDesktop ? 0 : "32px",
              }}
            >
              <div className="flex items-center" style={{ lineHeight: 1 }}>
                <span className="u-mright4">
                  <HighRiseBuilding size={24} color="hsl(var(--foreground))" />
                </span>
                <Link
                  className={cn(linkVariants({ size: "lg" }))}
                  onClick={() => {
                    setKeywords("");
                    setSearchResults([]);
                  }}
                  to={`/groups/${group_id}`}
                >
                  {group_name}
                </Link>
              </div>
              <div className="text-muted-foreground text-sm">Property</div>
            </div>
          )}

          {zipato_username && hub_serial && (
            <div
              className="u-flex-grow1"
              style={{
                minWidth: "200px",
                marginBottom: isDesktop ? 0 : "32px",
              }}
            >
              <div className="flex items-center" style={{ lineHeight: 1 }}>
                <span className="u-mright4">
                  <SmartHome size={24} color="hsl(var(--foreground))" />
                </span>

                <span className="text-2xl text-muted-foreground">
                  {zipato_username}
                </span>
              </div>
              <div className="text-muted-foreground text-sm">
                Zipato Account
              </div>
              {hub_serial && (
                <div className="text-base text-muted-foreground">
                  {hub_serial}
                </div>
              )}
              {zipato_environment && (
                <div className="text-base text-muted-foreground">
                  Connected To: {zipato_environment}
                </div>
              )}
              {hub_serial && <Refurbished serial={hub_serial} />}
            </div>
          )}
        </div>
      </div>
    </li>
  );
}
