import { Text, Link } from "@smartrent/ui";
import { View } from "react-native";

import Layout from "@/layout/Layout";
import { useGlobalContext } from "@/layout/Context";
import helpers from "@/lib/helpers";
import { useDocumentTitle } from "@/hooks/use-document-title";
import { buildUrl } from "@/utils";

export default function ReportingContainer() {
  useDocumentTitle("Reporting");
  const { user } = useGlobalContext();

  if (!user.permissions.reporting) {
    return null;
  }

  const buildLinkWithSearchParams = (path: string) => {
    const { href } = buildUrl({
      pathname: path,
      search: helpers.qsFromLocation(location),
    });
    return href;
  };

  return (
    <Layout>
      <View style={{ margin: 24 }}>
        <Text type="title3">Reporting</Text>
        <View>
          <Link href={buildLinkWithSearchParams("/monthly-reports/cmw")}>
            CMW Monthly Reports
          </Link>
        </View>
        <View>
          <Link to="/integration-adoption">Integration Adoption</Link>
        </View>
        <View>
          <Link href={buildLinkWithSearchParams("/monthly-reports/tours")}>
            Tours Monthly Reports
          </Link>
        </View>
        <View>
          <Link href={buildLinkWithSearchParams("/monthly-reports/residents")}>
            Residents Monthly Reports
          </Link>
        </View>
      </View>
    </Layout>
  );
}
