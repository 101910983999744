import { useEffect, useState } from "react";
import { Row } from "@tanstack/react-table";
import { useHistory } from "react-router-dom";

import { DataTable } from "@/_v2/components/composites/data-table/data-table";
import { useDataTablePagination } from "@/_v2/components/composites/data-table/data-table-pagination";
import { useDataTableSorting } from "@/_v2/components/composites/data-table/data-table-sorting";
import { useDataTableFilters } from "@/_v2/components/composites/data-table/data-table-filters";
import { useDataTableSelection } from "@/_v2/components/composites/data-table/data-table-selection";
import { DataTableFiltersQuickSearch } from "@/_v2/components/composites/data-table/data-table-filters/data-table-filters-quick-search";
import { Button } from "@/_v2/components/ui/button";

import { useDebouncedValue } from "@/_v2/utils/hooks/use-debounced-value";

import { GroupResponse, useGroupsQuery } from "@/_v2/api/groups";
import {
  useConversationUpdateMutation,
  useCurrentConversation,
} from "@/_v2/api/intercom/conversation";

import { columns } from "./conversation-groups-table-columns";

export const ConversationGroupsTable = () => {
  const { data: conversation } = useCurrentConversation();

  const pagination = useDataTablePagination();
  const sorting = useDataTableSorting([]);
  const selection = useDataTableSelection({});
  const [filters, setFilters] = useDataTableFilters([]);

  useEffect(() => {
    if (conversation) {
      const orgId = conversation?.custom_attributes?.organization_uuid;
      const groupIds =
        conversation?.custom_attributes?.property_ids?.split(", ");

      const f: any = [];

      if (orgId) {
        f.push({ id: "organization_uuid", value: orgId });
      }

      if (groupIds) {
        f.push({ id: "group_uuids", value: groupIds });
      }

      setFilters(f);
    }
  }, [conversation, setFilters]);

  const queryFilters = useDebouncedValue(filters);

  const filterString = queryFilters.map(({ id }) => id).join(",");
  const { data, isFetching } = useGroupsQuery(
    {
      page: pagination[0]?.pageIndex,
      limit: pagination[0]?.pageSize,
      sortBy: sorting[0][0]?.id,
      sortDir: sorting[0][0]?.desc ? "desc" : "asc",
      filters: queryFilters,
    },
    {
      // doing this to ensure that the query is only made when the filters are present
      // we don't want to allow searching of all groups
      enabled:
        filterString.includes("organization_uuid") ||
        filterString.includes("group_uuids"),
    }
  );

  return (
    <DataTable
      columns={columns}
      data={data?.records || []}
      rowCount={data?.total_records || 0}
      getRowId={(row) => row.uuid}
      loading={isFetching}
      pagination={pagination}
      sorting={sorting}
      selection={selection}
      filters={[filters, setFilters]}
      enableMultiRowSelection={false}
      onRowClick={(row) => row.toggleSelected(!row.getIsSelected())}
    >
      {(table) => (
        <div className="flex justify-between">
          <DataTableFiltersQuickSearch
            table={table}
            columnId="marketing_name"
            placeholder="Search by name..."
          />

          <ConfirmButton row={table.getSelectedRowModel().rows[0]} />
        </div>
      )}
    </DataTable>
  );
};

const ConfirmButton = ({ row }: { row?: Row<GroupResponse> }) => {
  const history = useHistory();
  const { data: conversation } = useCurrentConversation();
  const [updateConveration] = useConversationUpdateMutation();
  const [loading, setLoading] = useState(false);

  return (
    <Button
      onClick={async () => {
        if (!conversation?.id || !row) {
          return;
        }

        setLoading(true);
        try {
          const redirectUrl = `/v2/conversation/${conversation.id}/unit`;
          await updateConveration({
            conversationId: conversation.id,
            property_ids: row.original.uuid,
            property_names: row.original.marketing_name,
            supportrent_url: window.location.origin + redirectUrl,
          });

          history.replace(redirectUrl);
          setLoading(false);
        } catch (e) {
          console.error(e);
          setLoading(false);
        }
      }}
      disabled={!conversation?.id || !row?.original.uuid}
      loading={loading}
    >
      Confirm
    </Button>
  );
};
