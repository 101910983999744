import { useContext } from "react";
import { View } from "react-native";
import { Box, LegacyFlatList, VStack, Typography, Grid } from "@smartrent/ui";
import { useWindowDimensionsQuery } from "@smartrent/hooks";

import { TourApiProps } from "@/types";

import Layout from "@/layout/Layout";
import Context, { useUser } from "@/layout/Context";

import { FullScreenLoader } from "@/common/Loader";
import { SmsWebhookCard } from "@/common/SmsWebhookCard";
import NoRecords from "@/common/NoRecords";
import ProspectActiveToursMap from "@/common/ProspectActiveToursMap";
import ProspectCard from "@/common/ProspectCard";
import ProspectVerificationResults from "@/common/ProspectVerificationResults";
import { useDocumentTitle } from "@/hooks/use-document-title";

import { useProspectQuery } from "@/api/prospect";
import { useWebhooksQuery } from "@/api/sms-webhooks";

export default function Prospect() {
  useDocumentTitle("Prospect");

  const { match } = useContext(Context);
  const { permissions } = useUser();

  const { small, medium } = useWindowDimensionsQuery();

  const prospectQuery = useProspectQuery(match?.params?.prospectId, {
    enabled: match?.params?.prospectId && permissions.resident_details,
  });

  const webhooksQuery = useWebhooksQuery(
    prospectQuery?.data?.prospect?.phone_number,
    {
      enabled: !!(
        permissions?.view_masked_user_detail &&
        prospectQuery?.data?.prospect?.phone_number
      ),
    }
  );

  if (prospectQuery.isLoading) {
    return <FullScreenLoader />;
  }

  if (!prospectQuery?.data?.prospect) {
    return <NoRecords title="No Prospect Found" />;
  } else {
    return (
      <Layout>
        <View style={{ flexDirection: "row", flexWrap: "wrap" }}>
          <ProspectCard
            prospect={prospectQuery?.data?.prospect}
            tours={prospectQuery?.data?.tours}
            group={prospectQuery?.data?.group}
            organization={prospectQuery?.data?.organization}
          />

          <VStack
            spacing={24}
            style={{
              flex: 1,
              marginVertical: 16,
              marginRight: 16,
              minWidth: 320,
              width: "100%",
            }}
          >
            <ProspectVerificationResults
              prospectVerifications={prospectQuery?.data?.prospectVerifications}
            />

            {!!prospectQuery?.data?.activeTours.length ? (
              <Box style={{ padding: 18, width: "100%" }}>
                <Typography type="title2" style={{ marginBottom: 16 }}>
                  Active Tours
                </Typography>

                <Grid numColumns={small || medium ? 1 : 2} gap={16}>
                  {prospectQuery?.data?.activeTours.map(
                    (tour: TourApiProps, index: number) => (
                      <View key={index} style={{ flex: 1, minWidth: 400 }}>
                        <ProspectActiveToursMap
                          tour={tour}
                          groupTourConfig={prospectQuery?.data?.groupTourConfig}
                          mapboxApiKey={prospectQuery?.data?.mapboxApiKey}
                        />
                      </View>
                    )
                  )}
                </Grid>
              </Box>
            ) : null}

            <Box style={{ height: 700, width: "100%" }}>
              <LegacyFlatList
                title="SMS Webhooks"
                data={webhooksQuery.reducedData}
                keyExtractor={(item, index) => index.toString()}
                renderItem={({ item }: { item: any }) => (
                  <SmsWebhookCard webhook={item} />
                )}
                onEndReachedThreshold={0.25}
                onEndReached={() => {
                  if (webhooksQuery.hasNextPage) {
                    webhooksQuery.fetchNextPage();
                  }
                }}
                ListEmptyComponent={
                  webhooksQuery.isLoading ? null : (
                    <View
                      style={{
                        height: 550,
                        justifyContent: "center",
                      }}
                    >
                      <NoRecords title="No SMS Webhooks Found" />
                    </View>
                  )
                }
              />
            </Box>
          </VStack>
        </View>
      </Layout>
    );
  }
}
