import * as React from "react";
import {
  QueryClientProvider as ReactQueryClientProvider,
  QueryClient,
} from "@tanstack/react-query";
import { reactQueryConfig } from "@smartrent/hooks";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      ...reactQueryConfig.defaultOptions?.queries,
      retry: false,
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      staleTime: 30 * 60 * 1000,
      cacheTime: 5 * 60 * 1000,
    },
  },
});

export const QueryClientProvider: React.FC<
  React.PropsWithChildren<unknown>
> = ({ children }) => {
  return (
    <ReactQueryClientProvider client={queryClient}>
      {children}
    </ReactQueryClientProvider>
  );
};
