import React, { useContext, useEffect } from "react";
import { View, StyleSheet } from "react-native";
import moment from "moment";
import { Link } from "react-router-dom";
import { get } from "lodash-es";
import { ActivityIndicator, Button } from "@smartrent/ui";
import { CheckCircledIcon, ReloadIcon } from "@radix-ui/react-icons";

import Helpers from "@/lib/helpers";
import Context from "@/layout/Context";
import { PermissionProps } from "@/types";

import { useManualVerificationsQuery } from "@/api";

interface MaybeLinkProps {
  verification: any;
  children: React.ReactNode;
}

function MaybeLink({ verification, children }: MaybeLinkProps) {
  if (verification?.id) {
    return (
      <Link
        to={`/verifications/${verification.id}`}
        className="u-link u-link-clean cursor--pointer"
      >
        {children}
      </Link>
    );
  }

  return (
    <div>
      {children}
      <div className="u-font12 u-text-symbolic-warning">
        Corresponding verification for this tour not found
      </div>
    </div>
  );
}

export default function ManualVerifications() {
  const context = useContext(Context);
  const permissions: PermissionProps = context.user.permissions;

  const { data, isLoading, isFetching, refetch } = useManualVerificationsQuery(
    {},
    {
      enabled: !!permissions.manual_verifications,
      refetchInterval: 30000,
    }
  );

  const verificationCount = data?.length;

  useEffect(() => {
    const newTitle = `(${verificationCount}) Manual Verifications | SupportRent`;

    if (document.title !== newTitle) {
      document.title = newTitle;
    }
  }, [verificationCount]);

  if (!permissions.manual_verifications) {
    return null;
  }

  return (
    <div className="p-2">
      <div className="u-flex u-flex-justify-between u-flex-align-items-center u-ptop4">
        <div className="h4 u-mbottom4">
          Manual Verifications ({verificationCount})
        </div>
        <Button onPress={() => refetch()} variation="plain">
          {isFetching ? (
            <ActivityIndicator size={20} />
          ) : (
            <ReloadIcon className="size-5 text-muted-foreground" />
          )}
        </Button>
      </div>
      {!verificationCount && (
        <div className="p-2 border border-border bg-background rounded-md shadow-sm">
          {/* check for isLoading because otherwise it says no verification found until data is loaded */}
          {!isLoading ? (
            <div className="u-p4">
              No verifications require your attention.{" "}
              <span role="img" aria-label="hands raised emoji">
                🙌
              </span>
            </div>
          ) : null}
          <div className="flex flex-wrap justify-end items-center pt-2 text-xs text-muted-foreground border-t border-border">
            <div className="mr-1 cursor-pointer" onClick={() => refetch()}>
              Last refresh: {moment().format("hh:mm:ss A")}
            </div>
            <div className="cursor-pointer" onClick={() => refetch()}>
              <ReloadIcon className="size-3 text-muted-foreground" />
            </div>
          </div>
        </div>
      )}
      {data?.map((verification) => {
        return (
          <div
            key={verification.id}
            className="mb-2 p-2 border border-border bg-background rounded-md shadow-sm"
          >
            <MaybeLink verification={verification}>
              <div className="flex flex-wrap items-center w-full space-x-4">
                <div className="text-base font-bold">
                  {verification.first_name} {verification.last_name}
                </div>
                <div className="flex space-x-3">
                  <div className="flex justify-between items-center space-x-2">
                    <IconForVerification
                      verification={verification}
                      type="front"
                    />{" "}
                    <p>Front</p>
                  </div>
                  <div className="flex justify-between items-center space-x-2">
                    <IconForVerification
                      verification={verification}
                      type="back"
                    />{" "}
                    <p>Back</p>
                  </div>
                  <div className="flex justify-between items-center space-x-2">
                    <IconForVerification
                      verification={verification}
                      type="selfie"
                    />{" "}
                    <p>Selfie</p>
                  </div>
                </div>
              </div>
              <View style={styles.verificationsContainer}>
                <div className="text-xs">
                  {"SCHEDULED START TIME: " +
                    (verification.scheduled_start
                      ? Helpers.formatDate(verification.scheduled_start)
                      : "N/A")}
                  {verification.scheduled_start_now ? ", TOUR NOW" : ""}
                </div>
              </View>
            </MaybeLink>
          </div>
        );
      })}
    </div>
  );
}

const styles = StyleSheet.create({
  verificationsContainer: {
    fontWeight: "600",
    fontSize: 12,
    flex: 1,
    flexWrap: "wrap",
    justifyContent: "space-between",
    color: Helpers.colors.gray,
    marginTop: 4,
  },
});

function IconForVerification({
  verification,
  type,
}: {
  verification: any;
  type: string;
}) {
  if (!verification) {
    return null;
  }

  const isUploaded = verification[`${type}_uploaded`];

  if (isUploaded) {
    return <CheckCircledIcon className="size-5 text-success" />;
  } else {
    const attemptsCount = (get(verification, "attempts_images") || []).filter(
      (attempt_image: any) => attempt_image.type === type
    ).length;
    return (
      <span className="border-2 border-destructive text-[10px] font-black px-1.5 py-0.5 rounded-full text-destructive align-top">
        {attemptsCount}
      </span>
    );
  }
}
