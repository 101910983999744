import Toast from "@/common/Toast";
import Loading from "@/common/Loading";

import { ScrollArea } from "@/_v2/components/ui/scroll-area";

import { Header } from "./layout-header/layout-header";

export const LayoutV2: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <>
      <Header />
      <Loading>
        <ScrollArea className="h-[calc(100%-40px)]">
          <div className="p-4">{children}</div>

          <div className="h-32" />
        </ScrollArea>
      </Loading>
      <Toast />
    </>
  );
};
