import { create } from "zustand";

interface SidenavStore {
  sidenavOpen: boolean;
  setSidenavOpen: (bool: boolean) => void;
}

export const useSidenavStore = create<SidenavStore>((set) => ({
  sidenavOpen: false,
  setSidenavOpen: (bool: boolean) => set({ sidenavOpen: bool }),
}));
