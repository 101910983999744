import { useParams } from "react-router-dom";

import { instance } from "@/lib/hooks";
import { createAxiosMutation, createAxiosQuery } from "@/hooks/react-query";
import { ConversationObject } from "@/_v2/types/intercom/conversation";

export const useCurrentConversation = () => {
  const { conversationId } = useParams<{ conversationId: string }>();

  return useConversationQuery({ conversationId });
};

export const useConversationQuery = createAxiosQuery(
  "intercom-conversation",
  async (filters?: { conversationId: string }) => {
    return instance.get<ConversationObject>(
      `/v2/intercom/conversation/${filters?.conversationId}`
    );
  }
);

interface useConversationUpdateMutation {
  conversationId: string;
  unit_id?: string;
  unit_name?: string;
  property_ids?: string;
  property_names?: string;
  supportrent_url?: string;
}

export const useConversationUpdateMutation = createAxiosMutation(
  async (params: useConversationUpdateMutation) => {
    return instance.put<ConversationObject>(
      `/v2/intercom/conversation/${params?.conversationId}`,
      params
    );
  },
  {
    onSuccess: (queryClient) => {
      queryClient.invalidateQueries(["intercom-conversation"]);
    },
    successToast: () => ({
      message: "Updated intercom conversation.",
    }),
    errorToast: () => ({
      message: "Failed to update intercom conversation.",
    }),
  }
);
