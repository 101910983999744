import * as React from "react";
import { StyleSheet, TouchableOpacity, View } from "react-native";

import { Button, Text, Typography, useTheme } from "@smartrent/ui";
import { Filter, CloseCircleSolid } from "@smartrent/icons";
export interface TitleHeaderProps {
  title?: string;
  action?: React.ReactNode;
  hasFilters?: boolean;
  onFilterButtonPress?: () => void;
  tableClearFiltersAction?: boolean;
  handleClearFields?: () => void;
}

export function TitleHeader({
  title,
  action,
  hasFilters,
  onFilterButtonPress,
  tableClearFiltersAction,
  handleClearFields,
}: TitleHeaderProps) {
  const { colors } = useTheme();

  return (
    <View
      style={[
        styles.root,
        {
          backgroundColor: colors.inputBackground,
        },
      ]}
    >
      <View style={styles.titleContainer}>
        {title ? <Text type="title3">{title}</Text> : <View />}
        <View style={styles.filterPosition}>
          {hasFilters ? (
            <View style={styles.filtersWrapper}>
              {tableClearFiltersAction ? (
                <Button
                  variation="plain"
                  style={{
                    backgroundColor: colors.raisedSurfaces,
                    marginRight: 16,
                  }}
                  textStyle={{
                    color: colors.error,
                    backgroundColor: colors.gray200,
                  }}
                  onPress={handleClearFields}
                >
                  <Typography style={styles.clearFilters}>
                    Clear Filters
                  </Typography>
                  <CloseCircleSolid />
                </Button>
              ) : null}
              <TouchableOpacity
                style={styles.filterIcon}
                onPress={onFilterButtonPress}
              >
                <Filter />
              </TouchableOpacity>
            </View>
          ) : null}
          {action}
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  filtersWrapper: {
    flexDirection: "row",
  },
  root: {
    paddingHorizontal: 16,
    paddingTop: 16,
  },
  titleContainer: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  filterPosition: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  clearFilters: { marginRight: 5 },
  filterIcon: { marginRight: 16 },
});
