import { useState, useEffect, useContext, useMemo } from "react";

import { Link } from "react-router-dom";
import classNames from "classnames";
import { orderBy, times, flatMap, map, sum, compact } from "lodash-es";
import "leaflet/dist/leaflet.css";
import "react-leaflet-markercluster/dist/styles.min.css";

import Layout from "@/layout/Layout";
import Context from "@/layout/Context";

import { SRMapMarker, SRMap } from "@/common/map";
import { FullScreenLoader } from "@/common/Loader";
import { useApi } from "@/lib/hooks";
import { useDocumentTitle } from "@/hooks/use-document-title";

import Helpers from "@/lib/helpers";

export function HubsMapPage() {
  useDocumentTitle("Hubs Map");

  const { location, history } = useContext(Context);
  const [loading, setLoading] = useState(true);
  const qs = Helpers.qsFromLocation(location);

  const report = qs.report || "offline";
  const interval = qs.interval || "20 minutes";

  const { response } = useApi({
    url: `/maps/hubs/${report}${
      location.search ? location.search : "?interval=20 minutes"
    }`,
    trigger: [report, location.search],
  });

  useEffect(() => setLoading(!response?.data), [response]);

  const markersDOM = useMemo(() => {
    const points = response?.data?.points || [];
    return compact(
      flatMap(points, ({ lat, lng, count, marketing_name, id }) => {
        if (!(lat && lng)) {
          return null;
        }

        return times(Number(count), (index) => (
          <SRMapMarker position={{ lat, lng }} key={`${id}-${index}`}>
            <Link
              to={`/groups/${id}`}
              className="u-link-clean h3"
              target="_blank"
            >
              {marketing_name}
            </Link>
            {count} hubs offline
          </SRMapMarker>
        ));
      })
    );
  }, [response?.data?.points]);

  const points = response?.data?.points || [];

  return (
    <Layout>
      <div className="u-flex u-flex-justify-between u-flex-wrap u-mleft8 u-mright16">
        <div className="u-text-center u-mtop4 u-mbottom4">
          <span
            className={classNames(
              "cursor--pointer",
              report === "offline" ? "strong" : ""
            )}
            onClick={() =>
              Helpers.updateQS({
                history,
                location,
                update: { report: "offline" },
              })
            }
          >
            Offline{" "}
            {!loading && report === "offline" && points.length
              ? `(${Number(sum(map(points, "count"))).toLocaleString()})`
              : ""}
          </span>
        </div>
        {report === "offline" && (
          <div className="u-text-center u-mtop4 u-mbottom4">
            <span
              className={classNames(
                "cursor--pointer",
                interval === "20 minutes" ? "strong" : ""
              )}
              onClick={() =>
                Helpers.updateQS({
                  history,
                  location,
                  update: { interval: "20 minutes" },
                })
              }
            >
              Last 20 minutes
            </span>
            <span> | </span>
            <span
              className={classNames(
                "cursor--pointer",
                interval === "60 minutes" ? "strong" : ""
              )}
              onClick={() =>
                Helpers.updateQS({
                  history,
                  location,
                  update: { interval: "60 minutes" },
                })
              }
            >
              Last 60 minutes
            </span>
            <span> | </span>
            <span
              className={classNames(
                "cursor--pointer",
                !interval || interval === "24 hours" ? "strong" : ""
              )}
              onClick={() =>
                Helpers.updateQS({
                  history,
                  location,
                  update: { interval: null },
                })
              }
            >
              Last 24 hours
            </span>
          </div>
        )}
      </div>

      {!loading && (
        <div className="u-flex u-flex-wrap">
          <div className="u-flex-grow2">
            <SRMap
              mapboxApiKey={response?.data?.mapboxApiKey}
              markersDOM={markersDOM}
              loading={loading}
            />
          </div>
          <div
            style={{
              height: "80vh",
              overflowY: "scroll",
              background: "#FFF",
              borderTop: "1px solid #eee",
            }}
            className="u-pleft8"
          >
            {map(
              orderBy(points, ["count"], ["desc"]),
              ({ marketing_name, count, city, state, id }) => (
                <Link
                  key={id}
                  to={`/groups/${id}`}
                  className="u-link u-link-clean a"
                >
                  <div>
                    {marketing_name} - {city}, {state}{" "}
                    <span className="u-font12">
                      ({Number(count).toLocaleString()})
                    </span>
                  </div>
                </Link>
              )
            )}
          </div>
        </div>
      )}
      {loading && <FullScreenLoader />}
    </Layout>
  );
}
