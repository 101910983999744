import { ColumnDef } from "@tanstack/react-table";
import { ExternalLinkIcon } from "@radix-ui/react-icons";

import { AccountProps } from "@/types";
import { formatDate, hostname } from "@/utils";

export const columns: ColumnDef<AccountProps>[] = [
  {
    accessorKey: "account_id",
    header: "Id",
    enableSorting: true,
  },
  {
    accessorKey: "first_name",
    header: "First Name",
    enableSorting: true,
  },
  {
    accessorKey: "last_name",
    header: "Last Name",
    enableSorting: true,
  },
  {
    accessorKey: "email",
    header: "Email",
    enableSorting: true,
  },
  {
    accessorKey: "name",
    header: "Role Name",
    enableSorting: true,
    cell: ({ row }) => {
      return (
        <p className={row.original.name ? "" : "italic"}>
          {row.original.name ? row.original.name : "Not Assigned"}
        </p>
      );
    },
  },
  {
    accessorKey: "inserted_at",
    header: "Created At",
    size: 50,
    maxSize: 200,
    enableSorting: true,
    cell: ({ row }) => (
      <p>{formatDate({ date: row.original.inserted_at, pattern: "P p" })}</p>
    ),
  },
  {
    accessorKey: "updated_at",
    header: "Updated At",
    size: 50,
    maxSize: 200,
    enableSorting: true,
    cell: ({ row }) => (
      <p>{formatDate({ date: row.original.updated_at, pattern: "P p" })}</p>
    ),
  },
  {
    accessorKey: "okta_id",
    header: "Okta Id",
    size: 50,
    maxSize: 200,
    enableSorting: false,
    cell: ({ row }) => {
      if (!row.original.okta_id) {
        return <p className="italic">Hasn't signed in yet</p>;
      }
      return (
        <a
          className="flex items-center text-primary underline space-x-1"
          href={getOktaLink(row.original.okta_id)}
          target="_blank"
          rel="noreferrer"
        >
          <span>{row.original.okta_id}</span>
          <ExternalLinkIcon />
        </a>
      );
    },
  },
];

const getOktaLink = (oktaId: string) => {
  let baseUrl =
    "https://smartrent-admin.oktapreview.com/admin/user/profile/view/";

  if (window.location.hostname === hostname.prod) {
    baseUrl = "https://smartrent-admin.okta.com/admin/user/profile/view/";
  }

  return `${baseUrl}${oktaId}`;
};
