import { Fragment } from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { useTheme } from "@smartrent/ui";

import Helpers from "@/lib/helpers";
import HoverText from "@/common/HoverText";
import ResidentMergeTool from "@/common/ResidentMergeTool";
import { formatPhone } from "@/utils";

import { PermissionProps, ResidentProps, UserProps } from "../../../types";

type Props = {
  resident: ResidentProps;
  user: UserProps;
  residentUserCount: number;
  permissions: PermissionProps;
  hideUserSection: boolean | undefined;
  residents: ResidentProps[];
};

export default function ResidentInfo({
  resident,
  user,
  residentUserCount,
  permissions,
  hideUserSection,
  residents,
}: Props) {
  const { colors } = useTheme();

  return (
    <Fragment>
      <div className="u-font14" style={{ lineHeight: "1.2" }}>
        {resident.email ? (
          <div className="u-mbottom4 u-flex u-flex-align-items-start">
            <span className="u-mright4">Synced Email:</span>
            <span style={{ color: colors.textPrimary }} className="u-mright4">
              {resident.email}{" "}
            </span>
            <HoverText
              color={Helpers.colors.blue}
              tooltipText={
                user && !!user.id
                  ? "User account has been created, all emails now sent to the user's email and not this email address."
                  : "This will only be used until a SmartRent user account is created."
              }
            />
          </div>
        ) : (
          <div
            className={classNames("u-mbottom4 u-font12", {
              "text-muted-foreground":
                resident.status === "Past" && !resident.moved_in,
              "text-warning": resident.status !== "Past" && !resident.moved_in,
              "u-text-symbolic-warning":
                resident.status !== "Past" && !resident.moved_in,
            })}
          >
            No email synced from leasing software
          </div>
        )}

        {resident.phone_number && (
          <div className="u-mbottom4">
            Synced Phone: {formatPhone({ phone: resident.phone_number })}
          </div>
        )}
        {resident.move_in_date && (
          <div className="u-mbottom4">
            <span>
              Synced Move-In:{" "}
              {Helpers.formatDateUtc(resident.move_in_date, "L")}
            </span>

            {resident.move_out_date
              ? ` to ${Helpers.formatDateUtc(resident.move_out_date, "L")}`
              : ""}
          </div>
        )}

        {resident.lease_start_date || resident.lease_end_date ? (
          <div className="u-mbottom4">
            Synced Lease:{" "}
            {resident.lease_start_date
              ? Helpers.formatDateUtc(resident.lease_start_date, "L")
              : "-"}{" "}
            to{" "}
            {resident.lease_end_date
              ? Helpers.formatDateUtc(resident.lease_end_date, "L")
              : "-"}
          </div>
        ) : null}
        <div>
          {resident.contact_status}
          {resident.contact_status && resident.relationship ? ", " : ""}
          {resident.relationship}
        </div>
        {user && user.last_sign_in_at && (
          <div>Last Sign In: {Helpers.formatDate(user.last_sign_in_at)}</div>
        )}
      </div>

      <ResidentMergeTool
        resident={resident}
        residents={residents}
        permissions={permissions}
      />

      <div className="u-font14">
        {resident.emergency_code_sent_at ? (
          <div>
            Backup code sent on{" "}
            {Helpers.formatDate(resident.emergency_code_sent_at)}
          </div>
        ) : null}

        {user && !hideUserSection ? (
          <div
            className="u-mtop4 u-mbottom8 u-ptop8 u-flex"
            style={{ borderTop: "1px solid #eee" }}
          >
            {Number(residentUserCount) > 1 ? (
              <div
                className="u-text-symbolic-error u-mbottom8"
                style={{ maxWidth: "350px", lineHeight: "1" }}
              >
                <span className="u-sans-bold">WARNING:</span> This user is tied
                to {residentUserCount} moved-in resident accounts. This may be a
                Corporate Housing user or the email address may be a generic /
                throwaway. Be very careful with giving out invite / forgot
                password links to anyone associated with this account.
              </div>
            ) : null}

            <div
              style={{ lineHeight: "1" }}
              className="u-flex u-flex-align-items-start"
            >
              <span className="u-mright4">SmartRent Login:</span>
              {permissions.resident_details ? (
                <Link to={`/users/${user.id}`} className="a u-mright4">
                  {user.email}
                </Link>
              ) : (
                <span className="u-sans-bold u-mright4">{user.email}</span>
              )}{" "}
              <HoverText
                color={Helpers.colors.blue}
                tooltipText={
                  <>
                    <div>Email address that the resident uses to login.</div>
                    <div className="u-mtop4">
                      Note: Now that a SmartRent account has been created,
                      changing the resident's email address via RealPage or
                      other leasing integrations will not change this user's
                      email address.
                    </div>
                    <div className="u-mtop4">
                      Users can change their own email address in Account
                      Settings if they are able to login.
                    </div>
                  </>
                }
              />
            </div>
          </div>
        ) : null}

        <Fragment>
          <div>
            {resident.google_actions_enabled ? "Google Actions Enabled" : ""}
          </div>
          <div>{resident.alexa_enabled ? "Alexa Enabled" : ""}</div>
        </Fragment>
      </div>
    </Fragment>
  );
}
