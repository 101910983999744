import { useApi } from "@/lib/hooks";

import { useUnit } from "../UnitContext";

export default function HubHeader() {
  const { group } = useUnit();

  const { response } = useApi({
    url: `/groups/${group.id}/maintenance/config`,
    trigger: [group.id],
  });

  const notifyResidentConfig =
    response?.data?.maintenanceConfig?.notify_resident_config;

  const displayBoolean = (b: any) => (b ? "Yes" : "No");

  return (
    <div className="mt-2">
      <span className="u-text-gray-600">Resident Notifications Enabled:</span>
      <div className="flex flex-col ml-3 text-warning u-font10 u-line18">
        <span>Manual: {displayBoolean(notifyResidentConfig?.Manual)}</span>
        <span>Humidity: {displayBoolean(notifyResidentConfig?.Humidity)}</span>
        <span>Leak: {displayBoolean(notifyResidentConfig?.Leak)}</span>
        <span>
          Low Battery: {displayBoolean(notifyResidentConfig?.["Low Battery"])}
        </span>
        <span>Temp: {displayBoolean(notifyResidentConfig?.Temp)}</span>
      </div>
    </div>
  );
}
