import * as React from "react";
import { View, StyleSheet } from "react-native";
import { Link } from "react-router-dom";

import { Typography } from "@smartrent/ui";

import Layout from "@/layout/Layout";
import { GroupsTable } from "@/common/GroupsTable";
import { useDocumentTitle } from "@/hooks/use-document-title";

export const GroupsContainer: React.FC = () => {
  useDocumentTitle("Groups");
  return (
    <Layout>
      <View style={styles.container}>
        <Typography type="title3" style={styles.linktree}>
          <Link to="/organizations" className="a">
            Organizations
          </Link>{" "}
          / Groups
        </Typography>

        <GroupsTable />
      </View>
    </Layout>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: 24,
  },
  linktree: {
    marginBottom: 24,
  },
});
