import { useCallback, useState } from "react";
import { StyleSheet } from "react-native";
import {
  DrawerActions,
  DrawerContent,
  DrawerHeader,
  FormikSubmit,
  Button,
  ConfirmDialog,
  FormSection,
} from "@smartrent/ui";
import { Form, Formik, FormikHelpers } from "formik";
import { useModalState } from "@smartrent/hooks";

import { useDrawerNav } from "@/common/AppDrawer";
import { OrganizationProps, SalesForceAccount } from "@/types";
import { useGlobalContext } from "@/layout/Context";
import {
  useLinkSalesforceToOrganizationMutation,
  useSalesforceAccount,
} from "@/api";
import { SalesforceAccountSelect } from "@/common/salesforce/SalesforceAccountSelect";

export function LinkIntegrationToOrg(props: LinkIntegrationToOrgProps) {
  const drawer = useDrawerNav();
  const { setToast } = useGlobalContext();
  const { visible: isModalVisible, onOpen, onClose } = useModalState();
  const [modalSubmitLoading, setModalSubmitLoading] = useState(false);

  const { data: initialAccount } = useSalesforceAccount(
    props.organization.salesforce_id || "",
    { enabled: !!props.organization.salesforce_id }
  );

  const [linkItegrationToOrganization] =
    useLinkSalesforceToOrganizationMutation();

  const [account, setAccount] = useState<SalesForceAccount>({
    Id: "",
    Name: "",
  });

  const handleSubmit = useCallback(
    async (_values: {}, actions: FormikHelpers<{}>) => {
      actions.setSubmitting(true);

      if (!props.organization.salesforce_id && !account.Id) {
        setToast({
          type: "error",
          title: "Error",
          message: "Please select a salesforce account before submitting.",
        });
        return;
      }

      onOpen();

      actions.setSubmitting(false);
    },
    [account.Id, onOpen, props.organization.salesforce_id, setToast]
  );

  const handleConfirm = useCallback(async () => {
    try {
      setModalSubmitLoading(true);
      await linkItegrationToOrganization({
        organization_id: props.organization.id,
        salesforce_account_id: account?.Id || null,
      });

      props.refetchOrganization();

      onClose();
      setModalSubmitLoading(false);
      drawer.pop();
    } catch (error) {
      onClose();
      setModalSubmitLoading(false);
      const solicitErrors = (error as any)?.response?.data?.errors;
      setToast({
        type: "error",
        title: "Error",
        message:
          solicitErrors || "Something went wrong. Please contact engineering.",
      });
    }
  }, [
    account.Id,
    onClose,
    linkItegrationToOrganization,
    props,
    drawer,
    setToast,
  ]);

  return (
    <>
      <DrawerHeader title="Integration Linking" />
      <Formik initialValues={{}} onSubmit={handleSubmit}>
        {() => (
          <>
            <DrawerContent style={styles.drawerContent}>
              <Form>
                <FormSection title="Salesforce Account" style={styles.section}>
                  <SalesforceAccountSelect
                    initialValue={
                      account.Id ? account : initialAccount?.records?.[0]
                    }
                    value={account}
                    setValue={setAccount}
                    disabled={false}
                  />
                </FormSection>
              </Form>
            </DrawerContent>
            <DrawerActions>
              <Button onPress={drawer.pop} variation="plain">
                Close
              </Button>
              <FormikSubmit label="Submit" submittingLabel="Submitting" />
            </DrawerActions>
          </>
        )}
      </Formik>
      <ConfirmDialog
        title={account.Name ? "Link Integration" : "Unlink Integration"}
        description={
          account.Name
            ? `Are you sure you want to assign Salesforce Account "${account.Name}" to ${props.organization.name}?`
            : `Are you sure you want to unassign Salesforce Account "${initialAccount?.records?.[0]?.Name}" from ${props.organization.name}?`
        }
        visible={isModalVisible}
        onConfirm={handleConfirm}
        onClose={onClose}
        confirmButtonLoading={modalSubmitLoading}
      />
    </>
  );
}

interface LinkIntegrationToOrgProps {
  organization: OrganizationProps;
  refetchOrganization: () => void;
}

const styles = StyleSheet.create({
  drawerContent: { marginTop: -25 },
  section: { paddingBottom: 16 },
});
