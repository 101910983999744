import { View } from "react-native";

import Layout from "@/layout/Layout";

// Unit Components
import { VendorTable } from "@/common/vendor-applications/VendorTable";
import { useDocumentTitle } from "@/hooks/use-document-title";

export function VendorApplications() {
  useDocumentTitle("Vendor Applications");

  return (
    <Layout>
      <View style={{ margin: 16 }}>
        <VendorTable />
      </View>
    </Layout>
  );
}
