import { View, StyleSheet } from "react-native";
import { AnimatedPlaceholder, useTheme, Stack, VStack } from "@smartrent/ui";

import Layout from "@/layout/Layout";
import { useGlobalContext } from "@/layout/Context";

import { useVendorApplicationQuery } from "@/api";
import { VendorApplicationDetails } from "@/common/vendor-applications/VendorApplicationDetails";
import { VendorApplicationGroupsTable } from "@/common/vendor-applications/VendorApplicationGroupsTable";
import { VendorApplicationPermissionsTable } from "@/common/vendor-applications/VendorApplicationPermissionsTable";
import { useDocumentTitle } from "@/hooks/use-document-title";

export function VendorApplication() {
  useDocumentTitle("Vendor Application");

  const { match } = useGlobalContext();
  const { colors } = useTheme();

  const { organizationId, vendorApplicationId } = match.params;

  const { data: vendorApplication, isLoading } = useVendorApplicationQuery(
    parseInt(organizationId),
    parseInt(vendorApplicationId)
  );

  return (
    <Layout>
      <View style={styles.container}>
        {isLoading ? (
          <Stack direction="column" spacing={8} style={styles.loadingContainer}>
            <AnimatedPlaceholder
              style={{
                width: 320,
                height: 32,
                backgroundColor: colors.gray200,
                marginBottom: 8,
              }}
            />
            <AnimatedPlaceholder
              style={{
                width: 100,
                height: 24,
                backgroundColor: colors.gray200,
              }}
            />
            <AnimatedPlaceholder
              style={{
                width: 100,
                height: 24,
                backgroundColor: colors.gray200,
              }}
            />
          </Stack>
        ) : (
          <VStack spacing={24} style={styles.details}>
            <VendorApplicationDetails vendorApplication={vendorApplication} />
            <VendorApplicationGroupsTable
              vendorApplication={vendorApplication}
            />
            <VendorApplicationPermissionsTable
              vendorApplication={vendorApplication}
            />
          </VStack>
        )}
      </View>
    </Layout>
  );
}

const styles = StyleSheet.create({
  container: {
    padding: 24,
  },
  details: {
    flex: 1,
  },
  loadingContainer: {
    padding: 16,
  },
});
