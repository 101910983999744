"use client";

import * as React from "react";
import { Link } from "react-router-dom";

import { useMediaQuery } from "@/_v2/utils/hooks/use-media-query";
import {
  Breadcrumb,
  BreadcrumbEllipsis,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "@/_v2/components/ui/breadcrumb";
import { Button } from "@/_v2/components/ui/button";
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
} from "@/_v2/components/ui/drawer";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/_v2/components/ui/dropdown-menu";
import { Skeleton } from "@/_v2/components/ui/skeleton";

interface BreadcrumbItemProps {
  href?: string;
  loading?: boolean;
  label: string;
}
interface BreadcrumbResponsiveProps {
  items: BreadcrumbItemProps[];
  itemsToDisplay?: number;
}

export function BreadcrumbResponsive({
  items,
  itemsToDisplay = 3,
}: BreadcrumbResponsiveProps) {
  const [open, setOpen] = React.useState(false);
  const isDesktop = useMediaQuery("(min-width: 768px)");

  const remainingItems = items.slice(-itemsToDisplay + 1);

  return (
    <Breadcrumb>
      <BreadcrumbList>
        <BreadcrumbChild item={items[0]} />

        <BreadcrumbSeparator />

        {items.length > itemsToDisplay ? (
          <>
            <BreadcrumbItem>
              {isDesktop ? (
                <DropdownMenu open={open} onOpenChange={setOpen}>
                  <DropdownMenuTrigger
                    className="flex items-center gap-1"
                    aria-label="Toggle menu"
                  >
                    <BreadcrumbEllipsis className="h-4 w-4" />
                  </DropdownMenuTrigger>
                  <DropdownMenuContent align="start">
                    {items.slice(1, -2).map((item, index) => (
                      <DropdownMenuItem key={index}>
                        <Link to={item.href ? item.href : "#"}>
                          {item.label}
                        </Link>
                      </DropdownMenuItem>
                    ))}
                  </DropdownMenuContent>
                </DropdownMenu>
              ) : (
                <Drawer open={open} onOpenChange={setOpen}>
                  <DrawerTrigger aria-label="Toggle Menu">
                    <BreadcrumbEllipsis className="h-4 w-4" />
                  </DrawerTrigger>
                  <DrawerContent>
                    <DrawerHeader className="text-left">
                      <DrawerTitle>Navigate to</DrawerTitle>
                      <DrawerDescription>
                        Select a page to navigate to.
                      </DrawerDescription>
                    </DrawerHeader>
                    <div className="grid gap-1 px-4">
                      {items.slice(1, -2).map((item, index) => (
                        <Link
                          key={index}
                          to={item.href ? item.href : "#"}
                          className="py-1 text-sm"
                        >
                          {item.label}
                        </Link>
                      ))}
                    </div>
                    <DrawerFooter className="pt-4">
                      <DrawerClose asChild>
                        <Button variant="outline">Close</Button>
                      </DrawerClose>
                    </DrawerFooter>
                  </DrawerContent>
                </Drawer>
              )}
            </BreadcrumbItem>
            <BreadcrumbSeparator />
          </>
        ) : null}

        {remainingItems.map((item, index) => (
          <BreadcrumbChild
            key={`breadcrumb-item-${index}`}
            item={item}
            hasChild={index < remainingItems.length - 1}
          />
        ))}
      </BreadcrumbList>
    </Breadcrumb>
  );
}

const BreadcrumbChild = ({
  item,
  hasChild = false,
}: {
  item: BreadcrumbItemProps;
  hasChild?: boolean;
}) => {
  if (item.loading) {
    return (
      <>
        <BreadcrumbItem>
          <Skeleton className="w-20 h-4" />
        </BreadcrumbItem>
        {hasChild && <BreadcrumbSeparator />}
      </>
    );
  }

  return (
    <>
      <BreadcrumbItem>
        {item.href ? (
          <BreadcrumbLink asChild className="max-w-20 truncate md:max-w-none">
            <Link to={item.href}>{item.label}</Link>
          </BreadcrumbLink>
        ) : (
          <BreadcrumbPage className="max-w-20 truncate md:max-w-none">
            {item.label}
          </BreadcrumbPage>
        )}
      </BreadcrumbItem>
      {hasChild && <BreadcrumbSeparator />}
    </>
  );
};
