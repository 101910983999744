import { StyleSheet, View } from "react-native";
import { QueryFunction } from "@tanstack/react-query";
import {
  Table,
  ListQueryResponse,
  useTableQuery,
  ReactQueryTableBaseRecord,
} from "@smartrent/ui";

import Layout from "@/layout/Layout";
import { instance } from "@/lib/hooks";
import { useDocumentTitle } from "@/hooks/use-document-title";

const fetchProjects: QueryFunction<
  ListQueryResponse<ReactQueryTableBaseRecord>
> = async (data) => {
  const [, , filters] = data.queryKey;
  const response = await instance.get(`/reports/integration-adoption`, {
    params: filters,
  });
  return response.data;
};

export function IntegrationAdoption() {
  useDocumentTitle("Integration Adoption");

  const tableProps = useTableQuery({
    columns: [
      {
        name: "provider_name",
        header: "Integration",
        sortable: true,
        render: ({ row }) => (row as any).provider_name,
      },
      {
        name: "group_count",
        header: "Communities Using Integration",
        sortable: true,
        render: ({ row }) => (row as any).group_count,
      },
      {
        name: "organization_count",
        header: "Organizations Using Integration",
        sortable: true,
        render: ({ row }) => (row as any).organization_count,
      },
    ],
    keyExtractor: (row) => (row as any).provider_name,
    fetch: fetchProjects,
    getQueryKey: ({ filters, page, pageSize, sortColumn, sortDirection }) => [
      "projects",
      {},
      {
        ...filters,
        page,
        limit: pageSize,
        sort: sortColumn,
        dir: sortDirection,
      },
    ],
    defaultPageSize: 8,
  });

  return (
    <Layout>
      <View style={styles.containerView}>
        <Table<any>
          title="Integration Adoption"
          {...tableProps}
          noRecordsText="Fetching Integrations..."
        />
      </View>
    </Layout>
  );
}

const styles = StyleSheet.create({
  containerView: {
    margin: 16,
  },
});
