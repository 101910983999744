import React from "react";
import { cva, type VariantProps } from "class-variance-authority";
import { LoaderCircle } from "lucide-react";

import { cn } from "@/_v2/utils";

const loadingVariants = cva("animate-spin opacity-90", {
  variants: {
    variant: {
      default: "text-foreground",
      inverted: "text-background",
      primary: "text-primary",
    },
    size: {
      default: "size-9",
      sm: "size-8",
      lg: "size-10",
    },
  },
  defaultVariants: {
    variant: "default",
    size: "default",
  },
});

export interface LoadingProps
  extends React.ButtonHTMLAttributes<SVGElement>,
    VariantProps<typeof loadingVariants> {}

export function Loading({ className, size, variant, ...props }: LoadingProps) {
  return (
    <LoaderCircle
      className={cn(loadingVariants({ variant, size, className }))}
      {...props}
    />
  );
}
