import { View } from "react-native";
import { Button, FormSections, FormSection } from "@smartrent/ui";
import { get } from "lodash-es";

import Layout from "@/layout/Layout";
import { useUser } from "@/layout/Context";
import { useDocumentTitle } from "@/hooks/use-document-title";
import { useRotateSigningKeys } from "@/api/rsa-keys";

export const SigningKeyPage = () => {
  useDocumentTitle("Signing Keys");
  const { permissions } = useUser();

  const rotateKeysPermission = get(permissions, "rotate_signing_keys");
  const [rotateKeys] = useRotateSigningKeys();

  return (
    <Layout>
      <View>
        <FormSections>
          <FormSection
            title="Rotate Signing Keys"
            subtitle="rotates the oldest key"
          >
            {rotateKeysPermission && (
              <Button onPress={rotateKeys}>rotate</Button>
            )}
          </FormSection>
        </FormSections>
      </View>
    </Layout>
  );
};
