import { useHistory } from "react-router-dom";
import { BellIcon } from "@radix-ui/react-icons";

import { useUser } from "@/layout/Context";
import { useManualVerificationsQuery } from "@/api";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/_v2/components/ui/tooltip";
import { Button } from "@/_v2/components/ui/button";
import { cn } from "@/_v2/utils";

export const HeaderManualVerifications = () => {
  const { permissions } = useUser();
  const history = useHistory();
  const { data } = useManualVerificationsQuery(
    {},
    {
      enabled: !!permissions?.manual_verifications,
      refetchInterval: 30000,
    }
  );

  if (!permissions.manual_verifications) {
    return null;
  }

  return (
    <div>
      <Tooltip>
        <TooltipTrigger asChild>
          <Button
            variant="ghost"
            size="icon"
            onClick={() => history.push("/tours/manual-verifications")}
          >
            <BellIcon
              className={cn("size-4", {
                "opacity-40": !data?.length,
                "text-warning": data?.length,
              })}
            />
          </Button>
        </TooltipTrigger>
        <TooltipContent className="bg-warning" collisionPadding={8}>
          <p>{`${data?.length || 0} manual verification${
            data?.length === 1 ? "" : "s"
          } pending review`}</p>
        </TooltipContent>
      </Tooltip>
    </div>
  );
};
