import { Input, InputProps } from "@/_v2/components/ui/input";
import { cn } from "@/_v2/utils";

import { Table } from "./data-table-filters-types";

export interface DataTableFiltersQuickSearchProps<TData>
  extends Omit<InputProps, "value" | "onChange"> {
  table: Table<TData>;
  columnId: string;
}

export const DataTableFiltersQuickSearch = <TData,>({
  table,
  columnId,
  className,
  ...props
}: DataTableFiltersQuickSearchProps<TData>) => {
  return (
    <Input
      {...props}
      className={cn("max-w-xs", className)}
      value={(table.getColumn(columnId)?.getFilterValue() as string) ?? ""}
      onChange={(event) =>
        table.getColumn(columnId)?.setFilterValue(event.target.value)
      }
    />
  );
};
