import Icon from "./Icon";
import ExclamationCircle from "./icons/ExclamationCircle";

type NoRecordProps = {
  title?: string;
  text?: string;
  className?: string;
  style?: Object;
};

const NoRecords = ({ title, text, className, style }: NoRecordProps) => (
  <div
    className={`placeholder bg-background ${className || ""}`}
    style={style || {}}
  >
    <Icon className="placeholder__icon" icon={<ExclamationCircle />} />
    <div className="placeholder__instructions">
      <h1 className="placeholder__h1">{title || "No Records Found"}</h1>
      {text && <div>{text}</div>}
    </div>
  </div>
);

export default NoRecords;
