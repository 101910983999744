import { useCallback, useMemo, useState } from "react";
import * as React from "react";
import { StyleSheet, View } from "react-native";
import { Switch, Route, useRouteMatch, useParams } from "react-router-dom";

import {
  Panel,
  PanelBody,
  AnimatedPlaceholder,
  VStack,
  PanelHeader,
  ConfirmDialog,
  Dialog,
  DialogContent,
  DialogTitle,
  SelectField,
  DialogAction,
  HStack,
  Typography,
} from "@smartrent/ui";

import { Clock, DollarSign, Save, Trash } from "@smartrent/icons";
import { useModalState } from "@smartrent/hooks";

import { isEmpty } from "lodash-es";

import Layout from "@/layout/Layout";

import { ScopeOfWorkDeviceSelection } from "@/pages/scope-of-work/ScopeOfWorkDeviceSelection";
import { ScopeOfWorkBuilder } from "@/pages/scope-of-work/ScopeOfWorkBuilder";

import {
  useDeleteSOWMutation,
  useScopeOfWorkAssignDeviceOrderMutation,
  useScopeOfWorkSetReminderIntervalMutation,
  useScopeOfWorkTogglePricingMutation,
} from "@/api";
import { ScopeOfWorkDeviceDictionary, ScopeOfWorkStatus } from "@/types";

import { useGlobalContext, useUser } from "@/layout/Context";
import { useDocumentTitle } from "@/hooks/use-document-title";

import { SowPropertySection } from "./sow-report/SowPropertySection";
import { ScopeOfWorkDevices } from "./sow-report/SowDevices";
import { SowBreadcrumbs } from "./sow-report/SowBreadcrumbs";
import { useScopeOfWorkContext } from "./provider/ScopeOfWorkContext";
import { SowDebugActionsPanel } from "./sow-report/SowDebugActionsPanel";
import { SowPropertySectionEditForm } from "./sow-report/SowPropertySectionEditForm";
import { SowSortArrows } from "./sow-report/SowSortArrows";
import { ScopeOfWorkTemplateModal } from "./ScopeOfWorkTemplateModal";
import { ScopeOfWorkHeader } from "./ScopeOfWorkHeader";

export const ScopeOfWorkPage: React.VFC = () => {
  useDocumentTitle("Scope of Work Details");
  const { path } = useRouteMatch();
  const { user, location, history } = useGlobalContext();
  const { permissions } = useUser();
  const { scopeOfWorkId } = useParams<{ scopeOfWorkId: string }>();
  const [deleteSOWMutation] = useDeleteSOWMutation();
  const [selectedInterval, setSelectedInterval] = useState<{
    value: string;
    label: string;
  }>();

  const { visible, onOpen, onClose } = useModalState();
  const {
    visible: deleteVisible,
    onOpen: deleteOnOpen,
    onClose: deleteOnClose,
  } = useModalState();
  const {
    visible: pricingVisible,
    onOpen: pricingOnOpen,
    onClose: pricingOnClose,
  } = useModalState();
  const {
    visible: reminderVisible,
    onOpen: reminderOnOpen,
    onClose: reminderOnClose,
  } = useModalState();

  // USE SOW CONTEXT
  const {
    scopeOfWorkQuery: { data },
    devicesGroupedByCategory,
  } = useScopeOfWorkContext();

  const [scopeOfWorkTogglePricing] = useScopeOfWorkTogglePricingMutation();
  const [scopeOfWorkSetReminder] = useScopeOfWorkSetReminderIntervalMutation();

  const [
    scopeOfWorkAssignDeviceOrderMutation,
    { isLoading: deviceOrderLoading },
  ] = useScopeOfWorkAssignDeviceOrderMutation();

  const handleTogglePricing = useCallback(async () => {
    await scopeOfWorkTogglePricing({
      scope_of_work_id: scopeOfWorkId,
      show_pricing: data.show_pricing,
    });
    pricingOnClose();
  }, [scopeOfWorkTogglePricing, scopeOfWorkId, data?.show_pricing]);

  const handleSetReminder = useCallback(async () => {
    await scopeOfWorkSetReminder({
      scope_of_work_id: scopeOfWorkId,
      reminder_interval: selectedInterval.value,
    });
    pricingOnClose();
  }, [scopeOfWorkId, selectedInterval]);

  const handleDelete = useCallback(async () => {
    await deleteSOWMutation({ id: scopeOfWorkId });
    history.push("/scope-of-work");
  }, [deleteSOWMutation, history, scopeOfWorkId]);

  const isReportBuilderDisabled = useMemo(
    () =>
      location?.pathname?.includes("/add/") ||
      data?.status === ScopeOfWorkStatus.ReadyForReview ||
      data?.status === ScopeOfWorkStatus.Syncing ||
      data?.status === ScopeOfWorkStatus.Completed,
    [data?.status, location?.pathname]
  );

  const actions = [
    {
      label: "Save as Template",
      onPress: onOpen,
      StartAdornment: Save,
    },
    {
      label: "Reminder Interval",
      onPress: reminderOnOpen,
      StartAdornment: Clock,
    },
  ];

  if (data?.show_pricing !== undefined) {
    actions.push({
      label: data?.show_pricing ? "Hide Pricing" : "Show Pricing",
      onPress: pricingOnOpen,
      StartAdornment: DollarSign,
    });
  }

  if (permissions.delete_sow) {
    actions.push({
      label: "Delete",
      onPress: deleteOnOpen,
      StartAdornment: Trash,
    });
  }

  return (
    <Layout>
      <View style={styles.container}>
        <View style={styles.breadcrumb}>
          <SowBreadcrumbs />
        </View>

        <View style={styles.flexRow}>
          {/* LEFT SIDE SECTION */}
          <View
            style={
              user?.permissions?.manage_sow
                ? styles.leftContent
                : styles.leftContentViewOnly
            }
          >
            <Switch>
              <Route exact path={path}>
                <SowDebugActionsPanel style={styles.debugSection} />
                {/* SCOPE OF WORK PANEL */}
                <Panel style={styles.panel}>
                  <PanelHeader actions={actions}>
                    <ScopeOfWorkHeader />
                  </PanelHeader>
                  {data ? (
                    <PanelBody>
                      <VStack spacing={16}>
                        {/* SOW DETAILS (PROPERTY) SECTION */}
                        <SowPropertySection />
                        {/* SOW DEVICES SECTION */}
                        {/* If we have `scope_of_work_devices_order`,
                      we map over the array and display the categories in the
                      order of the array */}
                        {data?.scope_of_work_devices_order
                          ? data?.scope_of_work_devices_order.map(
                              (category, index) => {
                                return devicesGroupedByCategory &&
                                  devicesGroupedByCategory[category] &&
                                  devicesGroupedByCategory[category].length ? (
                                  <View
                                    key={category}
                                    style={styles.categoryRow}
                                  >
                                    {user?.permissions?.manage_sow ? (
                                      <View style={styles.arrowContainer}>
                                        <SowSortArrows
                                          isLoading={deviceOrderLoading}
                                          index={index}
                                          scope_of_work_id={data?.id}
                                          length={
                                            (
                                              data?.scope_of_work_devices_order ||
                                              []
                                            ).length
                                          }
                                          current_order={
                                            data?.scope_of_work_devices_order ||
                                            []
                                          }
                                          onPress={
                                            scopeOfWorkAssignDeviceOrderMutation
                                          }
                                        />
                                      </View>
                                    ) : null}
                                    <ScopeOfWorkDevices
                                      category={category}
                                      devices={
                                        devicesGroupedByCategory[category]
                                      }
                                      opportunity={data?.sf_opportunity_id}
                                    />
                                  </View>
                                ) : null;
                              }
                            )
                          : // Covers the case of us not having a `scope_of_work_devices_order` saved
                            !isEmpty(devicesGroupedByCategory)
                            ? Object.entries(
                                devicesGroupedByCategory as ScopeOfWorkDeviceDictionary
                              ).map(([category, devices]) => {
                                return (
                                  <ScopeOfWorkDevices
                                    key={category}
                                    category={category}
                                    devices={devices}
                                    opportunity={data?.sf_opportunity_id}
                                  />
                                );
                              })
                            : null}
                      </VStack>
                    </PanelBody>
                  ) : (
                    <AnimatedPlaceholder />
                  )}
                </Panel>
              </Route>
              {/* ADD SOW DEVICES PANEL */}
              <Route path={`${path}/add/:category`}>
                <ScopeOfWorkDeviceSelection />
              </Route>
              {/* EDIT SOW PROPERTY INFO */}
              <Route path={`${path}/details`}>
                <SowPropertySectionEditForm />
              </Route>
            </Switch>
          </View>
          {/* RIGHT SIDE SECTION */}
          {user?.permissions?.manage_sow ? (
            <View style={styles.sideContainer}>
              <ScopeOfWorkBuilder disabled={isReportBuilderDisabled} />
            </View>
          ) : null}
        </View>
      </View>
      <ScopeOfWorkTemplateModal visible={visible} onClose={onClose} />
      <ConfirmDialog
        title="Are you sure?"
        description="Do you really want to delete this Scope of Work?"
        visible={deleteVisible}
        onConfirm={handleDelete}
        onClose={deleteOnClose}
      />
      <ConfirmDialog
        title="Are you sure?"
        description={`Pricing is currently ${
          data?.show_pricing ? "shown" : "hidden"
        } for this Scope of Work. ${
          data?.show_pricing
            ? "Hiding pricing will prevent the client from seeing any pricing information."
            : "Showing pricing will allow the client to see pricing data."
        } Do you want to continue?`}
        visible={pricingVisible}
        onConfirm={handleTogglePricing}
        onClose={pricingOnClose}
      />
      <Dialog
        visible={reminderVisible}
        onClose={reminderOnClose}
        titleID="dialog-title"
        descriptionID="dialog-desc"
      >
        <DialogContent style={{ padding: 40 }}>
          <VStack spacing={20}>
            <DialogTitle>
              Select an interval for a reminder notification to be sent to the
              client.
            </DialogTitle>

            <Typography color="textSecondary">
              This will set an interval for an email to be sent to a client
              after the SOW is marked Ready for Review.
            </Typography>

            <SelectField
              label="Select an interval"
              name="reminder_interval"
              value={selectedInterval}
              onChange={setSelectedInterval}
              options={[
                { value: "0", label: "None" },
                { value: "1", label: "1 Day" },
                { value: "3", label: "3 Days" },
                { value: "5", label: "5 Days" },
                { value: "7", label: "7 Days" },
              ]}
            />
          </VStack>
          <HStack spacing={8} justify="space-between">
            <DialogAction
              text="Cancel"
              type="cancel"
              onPress={reminderOnClose}
            />
            <DialogAction
              text="Continue"
              onPress={() => {
                handleSetReminder();
                reminderOnClose();
              }}
            />
          </HStack>
        </DialogContent>
      </Dialog>
    </Layout>
  );
};

const styles = StyleSheet.create({
  breadcrumb: {
    padding: 24,
  },
  container: { flex: 1, flexDirection: "column" },
  sideContainer: { flex: 2, minWidth: 350, height: "100%", marginLeft: 16 },
  flexRow: { flexDirection: "row", flex: 1 },
  leftContent: { flexDirection: "column", flex: 5, marginHorizontal: 16 },
  leftContentViewOnly: {
    flexDirection: "column",
    width: "100%",
    paddingRight: 32,
    marginHorizontal: 16,
  },
  debugSection: {
    marginTop: 0,
    display: "flex",
    flexGrow: 0,
    marginBottom: 8,
  },
  panel: {
    marginTop: 8,
    paddingBottom: 0,
  },
  arrowContainer: {
    minWidth: 60,
    justifyContent: "flex-start",
    alignItems: "center",
    paddingTop: 16,
  },
  categoryRow: {
    flexDirection: "row",
  },
});
