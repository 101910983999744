import { useQueryCompat, useReducedInfiniteQuery } from "@smartrent/hooks";

import { instance } from "@/lib/hooks";
import { PropertyHealthReport, UnitsResponse } from "@/types";
import { buildUrl } from "@/utils";
import { createAxiosMutation, createAxiosQuery } from "@/hooks/react-query";
import { CustomReportResponse } from "@/pages/CustomReports";
import { useGlobalContext } from "@/layout/Context";

/**
 * The way this works for data fetching is that the Router.tsx path must be an exact match for the API path.
 *
 * For example:
 *
 * If the user lands on `/reports/cmw-monthly` on the frontend, there must be a route for
 * `/api/reports/cmw-monthly` on the backend.
 *
 * Generic supported filters: `?group_ids=x&organization_ids=y`
 */
export const useFetchMonthlyReportsByPath = ({
  filters,
  path,
}: {
  filters: Record<string, any>;
  path: string;
}) => {
  return useQueryCompat(
    [path, filters],
    async (key: string, filters: Record<string, any>) => {
      // @todo make more specific
      const { pathname, search } = buildUrl({
        pathname: path,
        search: filters,
      });
      const { data } = await instance.get<Record<string, any>[]>(
        `${pathname}${search}`
      );

      return data;
    }
  );
};

export interface CustomReportsQueryParams {
  filters: Record<string, any>;
  path: string;
}
export interface CustomReportsQueryResponse
  extends Partial<CustomReportResponse> {}

export const useFetchCustomReportsByPath = (
  params: CustomReportsQueryParams,
  options: { enabled: boolean }
) => {
  const context = useGlobalContext();
  return createAxiosQuery(
    "get-custom-reports-query",
    async (p?: CustomReportsQueryParams) => {
      const { pathname, search } = buildUrl({
        pathname: p?.path,
        search: p?.filters,
      });
      return await instance.get<CustomReportsQueryResponse>(
        `${pathname}${search}`
      );
    },
    {
      ...options,
      onError: (error) => {
        context.setToast({
          type: "error",
          title: "Error Fetching Report",
          message: error.response?.data?.message,
        });
      },
    }
  )(params, options);
};

export const useUnitHealthReportQuery = (
  groupId: number,
  filters: Record<string, any>
) =>
  useReducedInfiniteQuery(
    ["unitHealth", groupId, filters],
    async ({ pageParam }): Promise<UnitsResponse> => {
      const response = await instance.get(
        `/reports/v2/groups/${groupId}/health`,
        {
          params: {
            page: pageParam,
            ...filters,
            limit: 50,
          },
        }
      );
      return response.data;
    },
    {
      enabled: !!groupId,
    }
  );

export const useUnitHealthReportTableQuery = async (
  key: "unit-health-report",
  filters: Record<string, any>
) => {
  const { groupId } = filters;
  const { data } = await instance.get(`/reports/v2/groups/${groupId}/health`, {
    params: {
      ...filters,
      limit: 25,
    },
  });

  return data;
};

export const useGroupPropertyHealthReportQuery = createAxiosQuery<
  {
    groupId?: string | number;
  },
  PropertyHealthReport
>("groups-property-health-report", async (filters) => {
  return instance.get<PropertyHealthReport>(
    `/groups/${filters?.groupId}/property-health-report`
  );
});

export interface PropertyStaffUsersReportQueryResponse {
  organization_id?: string;
  group_id?: string;
  active: string;
  inactive: string;
  not_accepted: string;
  total: string;
  never_signed_in: string;
}
export const useGroupPropertyStaffUsersReportQuery = createAxiosQuery(
  "groups-property-staff-users-report",
  async (params?: { groupId: string | number }) => {
    return instance.get<PropertyStaffUsersReportQueryResponse>(
      `/reports/groups/${params?.groupId}/property-staff-users`
    );
  }
);

export const useOrganizationPropertyStaffUsersReportQuery = createAxiosQuery(
  "organizations-property-staff-users-report",
  async (params?: { organizationId: string | number }) => {
    return instance.get<PropertyStaffUsersReportQueryResponse>(
      `/reports/organizations/${params?.organizationId}/property-staff-users`
    );
  }
);

export interface BatteryHealthReportRow {
  "Property Name": string;
  "Property ID": string;
  "Unit Name": string;
  "Unit ID": string;
  Model: string;
  "Device Name": string;
  Type: string;
  Online: boolean;
  "Battery Level": number;
  "Battery Powered": boolean;
  "Battery Last Read": string;
  DSK: string;
}

export const useDownloadBatteryHealthReportMutation = createAxiosMutation(
  async ({ groupId }: { groupId: string | number }) => {
    return instance.get<BatteryHealthReportRow[]>(
      `/reports/groups/${groupId}/battery-health`
    );
  },
  {
    successToast() {
      return {
        type: "success",
        title: "Success!",
        message: `Device Report was requested. The file will download shortly.`,
      };
    },
    errorToast() {
      return {
        type: "error",
        title: "Error!",
        message:
          "Something went wrong downloading the report. Please try again.",
      };
    },
  }
);

export interface HubStatusReportRow {
  "Property Name": string;
  "Property ID": string;
  "Organization Name": string;
  "Unit Name": string;
  "Unit ID": string;
  "Unit Status": string;
  "Unit Occupied": boolean;
  "Unit Floor": string;
  "Unit Building": string;
  "Unit Code": string;
  "Serial #": string;
  "Firmware Version": string;
  "ZWave SDK Version": string;
  Online: boolean;
  "Last Offline At": string | null;
  "Uptime Past 24 Hours": string;
  Type: string | null;
  Regions: string | null;
  "Install Date": string;
}

export const useDownloadHubStatusReportMutation = createAxiosMutation(
  async ({ groupId }: { groupId: string | number }) => {
    return instance.get<HubStatusReportRow[]>(
      `/reports/groups/${groupId}/hub-status`
    );
  },
  {
    successToast() {
      return {
        type: "success",
        title: "Success!",
        message: `Hub Status Report was requested. The file will download shortly.`,
      };
    },
    errorToast() {
      return {
        type: "error",
        title: "Error!",
        message:
          "Something went wrong downloading the report. Please try again.",
      };
    },
  }
);
