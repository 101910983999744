/* Dev Note: This file is deprecated, use sr-table/Table.tsx */

import { Component } from "react";

import * as React from "react";
import classNames from "classnames";
import { ChevronDown, ChevronUp } from "@smartrent/icons";

import { debounce } from "lodash-es";

import Helpers from "@/lib/helpers";

import NoRecords from "./NoRecords";
import Loading from "./Loading";

import Icon from "./Icon";

/**
 Sample usage:

 <Table
    columns={[
      {
        label: 'Unit Name',
        filter: 'marketing_name',
        className: "grid__sm--12 grid__md--2",
        render: (record) => (
          <div className="u-sans-bold">
            {record.marketing_name}
          </div>
        )
      },
      {
        label: 'Assigned To',
        sort: 'assigned_to',
        className: "grid__sm--12 grid__md--2",
        render: ({assigned_to}) => assigned_to
      },
    ]}
    loading={this.state.loading}
    location={this.props.location}
    history={this.props.history}
    records={this.state.records}
    classes={{
      container: 'list--striped',
      chevron: 'icon--gray-400',
      chevronActive: 'icon--gray-500',
      header: 'u-text-upper u-sans-bold u-text-gray-400',
    }}
    noRecordsTitle='No Work Orders'
  />

*/

interface TableProps {
  columns: {
    label: string;
    filter?:
      | string
      | { name: string; options: { label: string; value: string }[] };
    className?: string;
    sort?: string;
    render: (record: any) => React.ReactNode;
  }[];
  loading?: boolean;
  location?: any;
  history?: any;
  records: any[];
  className?: string;
  classes: {
    container: string;
    chevron: string;
    chevronActive: string;
    header: string;
  };
  noRecordsTitle?: string;
}

class Table extends Component<TableProps, Record<string, any>> {
  constructor(props: TableProps) {
    super(props);
    const qs = Helpers.qsFromLocation(props.location);
    const state: Record<string, any> = {};

    // Load state from querystring on initial load
    props.columns.forEach((c) => {
      if (c.filter && typeof c.filter === "string") {
        state[c.filter] = qs[c.filter] || "";
      } else if (c.filter && typeof c.filter !== "string" && c.filter.name) {
        state[c.filter.name] = qs[c.filter.name] || "";
      }
    });

    this.state = state;
  }

  onHeaderClick({ sort }: { sort?: string }, qs: any) {
    const { history, location } = this.props;
    if (!sort) {
      return;
    }
    let dir = "desc";
    if (qs.sort === sort && qs.dir === "desc") {
      dir = "asc";
    }
    return Helpers.updateQS({ history, location, update: { sort, dir } });
  }

  onChange(key: string, val: any, qs: any) {
    this.setState({ [key]: val });
    return this.onUpdateQS(key, val, qs);
  }

  // When applying a filter, set page back to 1
  onUpdateQS = debounce((key, val, qs) => {
    const { history, location } = this.props;
    return Helpers.updateQS({
      history,
      location,
      update: { [key]: val, page: 1 },
    });
  }, 600);

  /**
   * If a field does not have sort enabled, don't return any chevron
   * If a field has sort enabled and is actively used and the dir is "desc" then render ChevronDown, otherwise ChevronUp
   */
  renderChevron({ sort }: { sort?: string }, qs: any) {
    if (!sort) {
      return null;
    }
    const activeSort = qs.sort === sort;
    const Component = activeSort && qs.dir === "desc" ? ChevronDown : ChevronUp;
    const classes = this.props.classes;

    return (
      <Icon
        icon={<Component />}
        className={
          activeSort && classes.chevronActive
            ? classes.chevronActive
            : classes.chevron
        }
      />
    );
  }

  renderHeaderFilter(
    { label, filter }: { label?: string; filter?: any },
    qs: any
  ) {
    // Usage: filter="first_name"
    if (typeof filter === "string") {
      return (
        <input
          className="input__table-input"
          value={this.state[filter] || ""}
          type="text"
          placeholder={label}
          onChange={(e) => this.onChange(filter, e.currentTarget.value, qs)}
        />
      );
    }

    // Usage: filter={name: "first_name", options: [{label: "Jason", value: "jason"}, {label: "Tom", value: "tom"}]}
    if (filter.name && filter.options) {
      return (
        // eslint-disable-next-line jsx-a11y/no-onchange
        <select
          className="input__input select__table-select"
          defaultValue={this.state[filter.name]}
          name={filter.name}
          onChange={(e) =>
            this.onChange(filter.name, e.currentTarget.value, qs)
          }
        >
          {filter.options.map(
            ({ value, label }: { value: string; label: string }) => (
              <option value={value} key={value}>
                {label}
              </option>
            )
          )}
        </select>
      );
    }

    return null;
  }

  render() {
    const {
      classes,
      className,
      columns,
      noRecordsTitle,
      loading,
      location,
      records,
    } = this.props;
    const qs = Helpers.qsFromLocation(location);

    return (
      <Loading loading={loading}>
        <ul
          className={classNames(
            className,
            { list: true },
            classes.container,
            "bg-background border border-border rounded-b-md"
          )}
        >
          <li className="list__item list__item--header !bg-background">
            <div className="grid list__grid">
              {columns.map((c, idx) => (
                <div
                  key={c.label || idx}
                  className={classNames(
                    "u-mbottom4",
                    c.className,
                    classes.header
                  )}
                >
                  <div
                    className={classNames("u-flex u-flex-align-items-center", {
                      "cursor--pointer": c.sort,
                    })}
                    onClick={() => this.onHeaderClick(c, qs)}
                  >
                    <span>{c.label}</span>
                    {this.renderChevron(c, qs)}
                  </div>
                  {c.filter && this.renderHeaderFilter(c, qs)}
                </div>
              ))}
            </div>
          </li>

          {records && records.length ? (
            records.map((child, idx) => (
              <li className="list__item !bg-background" key={idx}>
                <div className="grid list__grid bg-background">
                  {columns.map((c, colIdx) => (
                    <div className={classNames(c.className)} key={colIdx}>
                      {c.label && (
                        <div className="u-hidden u-visible--sm strong u-pright8">
                          {c.label}:
                        </div>
                      )}

                      <div>{c.render(child)}</div>
                    </div>
                  ))}
                </div>
              </li>
            ))
          ) : (
            <div className="u-m16">
              <NoRecords title={noRecordsTitle} />
            </div>
          )}
        </ul>
      </Loading>
    );
  }
}

export default Table;
