import { Drawer } from "vaul";

import { ScrollArea } from "@/_v2/components/ui/scroll-area";

interface SideDrawerProps {
  children: React.ReactNode;
  trigger: React.ReactNode;
  title: string;
  description?: string;
}

export const SideDrawer = ({
  children,
  trigger,
  title,
  description,
}: SideDrawerProps) => {
  return (
    <Drawer.Root direction="right">
      <Drawer.Trigger asChild>{trigger}</Drawer.Trigger>
      <Drawer.Portal>
        <Drawer.Title>{title}</Drawer.Title>
        <Drawer.Description>
          {description || "A drawer for " + title}
        </Drawer.Description>
        <Drawer.Overlay className="fixed inset-0 bg-background/30 backdrop-blur-[1px]" />
        <Drawer.Content className="-right-1 -top-1 bottom-0 fixed z-10 flex outline-none">
          <ScrollArea className="bg-background rounded-l-lg w-[310px] grow my-2 flex flex-col border border-border shadow-lg relative">
            <div className="-mt-1 p-4 pb-2 bg-muted/50">
              <p className="font-bold text-base pb-0">{title}</p>
              {description && (
                <p className="text-sm pb-1 text-muted-foreground">
                  {description}
                </p>
              )}
            </div>
            <div className="p-4">{children}</div>
          </ScrollArea>
        </Drawer.Content>
      </Drawer.Portal>
    </Drawer.Root>
  );
};
