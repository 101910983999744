/**
 * Deprecation notes:
 *
 * The pattern of adding functions to helpers.ts to make API requests is deprecated. Use react-query instead
 */

import { parse, stringify } from "querystring";
import axios from "axios";
import moment from "moment";
import { omit, startCase } from "lodash-es";
import { History, Location } from "history";
import { differenceInMinutes } from "date-fns";

import {
  AccountProps,
  VendorPermissionResource,
  VendorPermissionAction,
  GroupProps,
  ToastProps,
  HubPrepAction,
  HubPrepLog,
  HubPrepLogStatus,
  HubRequest,
  HubDetails,
  PermissionsProp,
  PermissionProps,
  Role,
} from "@/types";

import { TableQuery } from "@/_v2/types/api";

function getLoginUrl() {
  return `/login?redirectTo=${encodeURIComponent(window.location.href)}`;
}

// @todo jw check if I broke other places?
type UpdateQSProps = {
  history: History;
  location: Location;
  update: Record<string, any>;
  qs?: Record<string, any>;
  method?: "push" | "replace";
};

async function fetch(payload: any) {
  return axios({
    withCredentials: true,
    method: payload.method || "get",
    url: `${import.meta.env.REACT_APP_BASE_API_URL}${payload.path}${
      payload.query ? `?${stringify(payload.query)}` : ""
    }`,
    data: payload.data,
  })
    .then((r) => r.data)
    .catch(async (err) => {
      if (
        err.response &&
        err.response.status === 401 &&
        payload.path &&
        !payload.path.includes("public")
      ) {
        window.location.href = getLoginUrl();
      }
      throw err;
    });
}

function qsFromLocation(location: { search: string }): Record<string, any> {
  return location?.search ? parse(location.search.replace("?", "")) : {};
}

interface UpdateAccountProps {
  account: AccountProps;
  permissions: PermissionProps;
  role: Role | string;
}

function getHubTypeFromSerial(serial: string) {
  const lowerCaseSerial = serial.toLowerCase();
  switch (true) {
    case lowerCaseSerial.startsWith("t2"):
      return "fusion";
    case lowerCaseSerial.startsWith("zm"):
      return "zipamicro";
    case lowerCaseSerial.startsWith("zb"):
      return "zipabox";
    case lowerCaseSerial.startsWith("ah"):
    case lowerCaseSerial.startsWith("srh"):
      return "smartrent";
    default:
      return "unknown";
  }
}

function getHubType(hub: HubRequest & HubDetails) {
  const { model, serial } = hub;
  switch ((model ?? "").toLowerCase()) {
    case "macaw":
      return "Fusion V3";
    case "parakeet":
      return "Hub+";
    case "condor":
      return "Hub V3";
    default:
      return getHubTypeFromSerial(serial ?? "");
  }
}

export function toQS(filters: Record<string, any>) {
  return stringify(filters);
}

export function filtersToQS(filters?: { id: string; value: any }[]) {
  return (
    filters?.map((filter) => `${filter.id}=${filter.value}`).join("&") ?? ""
  );
}

export function tableQueryToQS(tableQuery?: TableQuery) {
  if (!tableQuery) {
    return "";
  }
  const pagination = omit(tableQuery, ["filters"]);
  const sortQS = toQS(pagination);

  const filtersQS = filtersToQS(tableQuery?.filters);

  return `${sortQS}${sortQS && filtersQS ? "&" : ""}${filtersQS}`;
}

export default {
  // Given this.props.location, return an object representing the query string
  // location = "?page=2&sort=marketing_name&dir=desc" -> {page: 2, sort: "marketing_name", dir: "desc"}
  qsFromLocation,

  toQS,

  filtersToQS,

  tableQueryToQS,

  defaultUser(): AccountProps {
    return {
      id: null,
      first_name: null,
      last_name: null,
      email: null,
      permissions: {
        name: "",
      }, // every component should handle if this is populated or empty
      role_id: null,
    };
  },

  fetch,

  // update: {includeDeleted: true}
  updateQS({ history, update, location, method = "push" }: UpdateQSProps) {
    return history[method](
      `${location.pathname}?${stringify({
        ...qsFromLocation(location),
        ...update,
      })}`
    );
  },

  account: {
    updatePermissions({ account, permissions, role }: UpdateAccountProps) {
      return fetch({
        method: "PUT",
        path: `/api/accounts/${account.id}/permissions`,
        data: {
          permissions,
          role,
        },
      });
    },
  },

  hub: {
    deleteConstructionCodes({ hubId }: { hubId: number }) {
      return fetch({
        method: "DELETE",
        path: `/api/hubs/${hubId}/construction-codes`,
      });
    },
  },

  hubPrep: {
    batch(payload: {
      serials: string[];
      action: HubPrepAction;
      advancedOptions: any;
    }) {
      return fetch({
        path: "/api/hub-prep-logs",
        method: "POST",
        data: payload,
      });
    },
  },

  auth: {
    async logout() {
      return fetch({
        method: "POST",
        path: "/api/logout", // we don't want it to call /api because we have route security on all /api routes
        data: {},
      }).then(() => {
        window.location.href = getLoginUrl();
      });
    },

    login(email: string | null, password: string | null) {
      if (!(email && password)) {
        throw new Error("Please fill out all required fields");
      }

      return fetch({
        method: "POST",
        path: "/public-api/login", // we don't want it to call /api because we have route security on all /api routes
        data: {
          email,
          password,
        },
      });
    },

    async getUser() {
      return fetch({ path: "/api/users/me" }).then((response) => response.user);
    },

    // sample usage: can("units") or can("search")
    can(user: AccountProps, permission: PermissionsProp) {
      return user && user.permissions ? user.permissions[permission] : false;
    },
  },

  troubleshoot: {
    makeHubsOnline: {
      get() {
        return fetch({
          path: "/api/troubleshoot/hubs/make-online",
        });
      },
    },
  },

  zipato: {
    firmware: {
      upgrade({ hub_id_or_serial }: { hub_id_or_serial: number | string }) {
        return fetch({
          path: `/api/hubs/${hub_id_or_serial}/upgrade`,
          method: "POST",
        });
      },
    },

    box: {
      redisStats({ serial }: { serial: string }) {
        return fetch({
          path: "/api/zipato/redis-stats",
          query: {
            serial,
          },
        });
      },

      logs({ remote_id, serial }: { remote_id?: string; serial?: string }) {
        return fetch({
          path: "/api/zipato/message-logs",
          query: {
            remote_id,
            serial,
          },
        });
      },

      boxSync({ unit_id }: { unit_id: number }) {
        return fetch({
          path: `/api/units/${unit_id}/hub/box-sync`,
          method: "POST",
        });
      },

      saveAll({ unit_id }: { unit_id: number }) {
        return fetch({
          path: `/api/units/${unit_id}/hub/save-all`,
          method: "POST",
        });
      },

      reboot({ unit_id }: { unit_id: number }) {
        return fetch({
          path: `/api/units/${unit_id}/hub/reboot`,
          method: "POST",
        });
      },

      offlineCheck({ unit_id }: { unit_id: number }) {
        return fetch({
          path: `/api/units/${unit_id}/hub/offline`,
          method: "POST",
        });
      },

      get({ unit_id }: { unit_id: number }) {
        return fetch({
          path: `/api/units/${unit_id}/hub/box-get`,
        });
      },
    },

    account(serial: string) {
      return fetch({
        path: `/api/hubs/${serial}/zipato`,
      });
    },
  },

  unknownErrorToast: {
    type: "error",
    title: "Error",
    message: "An unknown error occurred",
  } as ToastProps,

  colors: {
    primary: "#00aca0",
    gray: "#9a9eaa",
    amber: "#ffca28",
    blue: "#42a5f5",
    green: "#66bb6a",
    orange: "#ffa726",
    red: "#c2185b",
  },

  getGraphColors() {
    return [
      "#73b9af",
      "#00aca0",
      "#28697b",
      "#ff9200",
      "#C2185B",
      "#4caf50",
      "#039be5",
      "#ec407a",
      "#ab47bc",
      "#7e57c2",
      "#5c6bc0",
      "#42a5f5",
      "#29b6f6",
      "#26c6da",
      "#26a69a",
      "#66bb6a",
      "#9ccc65",
      "#d4e157",
      "#ffee58",

      // repeat
      "#73b9af",
      "#00aca0",
      "#28697b",
      "#ff9200",
      "#C2185B",
      "#4caf50",
      "#039be5",
      "#ec407a",
      "#ab47bc",
      "#7e57c2",
      "#5c6bc0",
      "#42a5f5",
      "#29b6f6",
      "#26c6da",
      "#26a69a",
      "#66bb6a",
      "#9ccc65",
      "#d4e157",
      "#ffee58",

      // repeat
      "#73b9af",
      "#00aca0",
      "#28697b",
      "#ff9200",
      "#C2185B",
      "#4caf50",
      "#039be5",
      "#ec407a",
      "#ab47bc",
      "#7e57c2",
      "#5c6bc0",
      "#42a5f5",
      "#29b6f6",
      "#26c6da",
      "#26a69a",
      "#66bb6a",
      "#9ccc65",
      "#d4e157",
      "#ffee58",

      // repeat
      "#73b9af",
      "#00aca0",
      "#28697b",
      "#ff9200",
      "#C2185B",
      "#4caf50",
      "#039be5",
      "#ec407a",
      "#ab47bc",
      "#7e57c2",
      "#5c6bc0",
      "#42a5f5",
      "#29b6f6",
      "#26c6da",
      "#26a69a",
      "#66bb6a",
      "#9ccc65",
      "#d4e157",
      "#ffee58",
    ];
  },

  lineGraphDefaults: {
    fill: false,
    lineTension: 0.1,
    borderCapStyle: "butt",
    borderDash: [],
    borderDashOffset: 0.0,
    borderJoinStyle: "miter",
    pointBackgroundColor: "#fff",
    pointBorderWidth: 1,
    pointHoverRadius: 5,
    pointHoverBorderWidth: 2,
    pointRadius: 1,
    pointHitRadius: 10,
  },

  lineGraphColors: {
    red: "rgba(241,11,11,0.6)",
    green: "rgba(75,175,80,0.6)",
    lightGreen: "rgb(91, 198, 204)",
    darkGreen: "rgb(0, 146, 124)",
    blue: "rgba(82,213,211,0.6)",
    orange: "rgba(253,113,55,0.6)",
    gray100: "rgb(235, 238, 244)",
    gray200: "rgb(235, 238, 244)",
    gray400: "rgb(154, 158, 170)",
    gray600: "rgb(106, 110, 121)",
    errorLight: "rgb(197, 20, 92)",
    purple: "rgb(159, 78, 182)",
    indigo: "rgb(95, 107, 186)",
    lightBlue: "rgb(89, 179, 240)",
    cyan: "rgb(95, 195, 215)",
    yellow: "rgb(253, 239, 114)",
    amber: "rgb(247, 204, 80)",
    deepOrange: "rgb(238, 120, 80)",
    brown: "rgb(136, 111, 101)",
  },

  tableClasses() {
    return {
      container: "list--striped u-font16",
      chevron: "icon--gray-400",
      chevronActive: "icon--gray-500",
      header: "u-text-upper u-sans-bold u-text-gray-400 u-font16",
    };
  },

  formatAddress(property: GroupProps) {
    return [
      property.street_address_1,
      property.street_address_2,
      property.city,
      `${property.state} ${property.zip}`,
      property.country,
    ]
      .filter((v) => !!v)
      .join(", ");
  },

  formatDate(
    datetime: string | Date,
    format: string = "L LT",
    defaultCharacter: string | null = null
  ) {
    if (!datetime) {
      return defaultCharacter;
    }
    return moment(datetime).format(format);
  },

  // To be used when we have a Date string but not a date time
  // example: 2020-04-21
  formatDateUtc(datetime: string | Date, format = "L") {
    return moment.utc(datetime).format(format);
  },

  replaceAll(target: string, search: RegExp, replacement: string) {
    return target.split(new RegExp(search, "g")).join(replacement);
  },

  downloadFile(fileName: string, data: any) {
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(new Blob([data]));

    link.setAttribute("download", fileName || "download");
    document.body.appendChild(link);
    link.click();
  },

  getHubTypeFromSerial,

  getHubType,

  hubPrepStatus(hubPrepLog: HubPrepLog): HubPrepLogStatus {
    const {
      action_required,
      hub_account_registration_required,
      hub_account_id,
      config_update_attempted_at,
      config_updated_at,
      config_update_required,
      firmware_update_required,
      firmware_upgrade_attempted_at,
      firmware_upgraded_at,
      rekey_required,
      rekey_attempted_at,
      rekeyed_at,
      reboot_required,
      reboot_attempted_at,
      rebooted_at,
      box_sync_required,
      box_sync_attempted_at,
      box_synced_at,
      community_wifi_required,
      community_wifi_attempted_at,
      community_wifi_applied_at,
      reset_zwave_required,
      reset_zwave_attempted_at,
      reset_zwave_completed_at,
      started_at,
      verify_hub_sim_required,
      verify_hub_sim_attempted_at,
      failed_at,
    } = hubPrepLog;

    const inProgress =
      (hub_account_registration_required && !hub_account_id) ||
      (firmware_update_required && !firmware_upgrade_attempted_at) ||
      (firmware_upgrade_attempted_at && !firmware_upgraded_at) ||
      (!config_update_attempted_at &&
        config_update_required &&
        !config_updated_at) ||
      rekey_required ||
      (!rekeyed_at && rekey_attempted_at) ||
      (reboot_required && !reboot_attempted_at) ||
      (reboot_attempted_at && !rebooted_at) ||
      (box_sync_required && !box_sync_attempted_at) ||
      (box_sync_attempted_at && !box_synced_at) ||
      (community_wifi_required && !community_wifi_attempted_at) ||
      (community_wifi_attempted_at && !community_wifi_applied_at) ||
      (reset_zwave_required && !reset_zwave_completed_at) ||
      (verify_hub_sim_required && !verify_hub_sim_attempted_at);

    const failed =
      (!reset_zwave_required &&
        reset_zwave_attempted_at &&
        !reset_zwave_completed_at) ||
      (action_required &&
        started_at !== null &&
        differenceInMinutes(new Date(), new Date(started_at)) >= 30) ||
      failed_at !== null; // The genserver timed out after 30 minutes

    switch (true) {
      case failed:
        return "Failed";
      case inProgress && action_required:
        return "In Progress";
      default:
        return "Complete";
    }
  },
};

export const formatCents = (cents: number) => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(cents / 100);
};

export const formatPercent = (decimal: number) => {
  return `${decimal * 100}%`;
};

export const formatVendorPermissionResource = (
  resource: VendorPermissionResource
) => {
  switch (resource) {
    case "access-codes":
      return "Access Codes";
    case "units":
      return "Units";
    case "thermostat-control":
      return "Thermostat Control";
    case "enrollment":
      return "Enrollment";
    case "tours":
      return "Tours";
    case "vacant-units":
      return "Vacant Units";
    case "access-codes-vacant-units":
      return "Unit Vacant Access Codes";
    case "webhooks":
      return "Webhooks";
    default:
      return "";
  }
};

export const formatVendorPermissionAction = (
  action: VendorPermissionAction
) => {
  return startCase(action);
};

// This is definitely not copied from stack overflow.
export const getNumberOrdinal = (n: number) => {
  const s = ["th", "st", "nd", "rd"],
    v = n % 100;
  return n + (s[(v - 20) % 10] || s[v] || s[0]);
};

export const delay = async (time: number) => {
  return new Promise((resolve) => setTimeout(resolve, time));
};

export function createCsv<T = Record<string, any>>(
  data: T[],
  headers: (keyof T)[],
  options: { quoteValues?: boolean } = {
    quoteValues: true,
  }
) {
  const rows = data
    .map((d) => objectToCsvRow<T>(d, headers, options.quoteValues))
    .join("\n");
  return headers.join(",") + "\n" + rows;
}

export function objectToCsvRow<T = Record<string, any>>(
  datum: T,
  headers: (keyof T)[],
  quoteValues = true
) {
  return headers
    .map((header) =>
      datum[header] ? (quoteValues ? `"${datum[header]}"` : datum[header]) : ""
    )
    .join(",");
}

export function isUUID(uuid: string) {
  const uuidRegex = new RegExp(
    /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i
  );
  uuidRegex.test(uuid);
}
