import { instance } from "@/lib/hooks";
import { createAxiosQuery } from "@/hooks/react-query";

import { AccountRole } from "@/types";

export type RolesResponse = AccountRole[];

export const useRolesQuery = createAxiosQuery("roles", async () => {
  return instance.get<RolesResponse>(`/roles`);
});
