import { TouchableOpacity } from "react-native";
import { Box, TextInputField, useTheme } from "@smartrent/ui";
import { CloseCircleSolid, Search } from "@smartrent/icons";

import { JobList } from "@/common/jobs/JobList";

import { useUrlTextInputFilter } from "@/hooks/url-filters";
import { useDocumentTitle } from "@/hooks/use-document-title";

import Layout from "../layout/Layout";
import ProjectHeader from "../common/projects/ProjectHeader";

interface Props {
  id: string;
}

export default function Project({ id }: Props) {
  useDocumentTitle("Alloy Install Project");
  const { inputValue, setInputValue, filterValue } = useUrlTextInputFilter(
    "name",
    {
      debounceInterval: 300,
    }
  );
  const { colors } = useTheme();

  return (
    <Layout>
      <Box>
        <ProjectHeader id={id} />
        <TextInputField
          StartAdornment={Search}
          label="Search for jobs..."
          textInputProps={{
            value: inputValue,
            onChangeText: setInputValue,
          }}
          EndAdornment={() =>
            inputValue ? (
              <TouchableOpacity onPress={() => setInputValue("")}>
                <CloseCircleSolid color={colors.iconsWithinComponents} />
              </TouchableOpacity>
            ) : null
          }
        />
      </Box>
      <JobList projectId={id} searchValue={filterValue} />
    </Layout>
  );
}
