import { useCallback, useState, useEffect, useContext } from "react";
import { View, Text, FlatList, StyleSheet, TextInput } from "react-native";
import moment from "moment";
import { Locked, Unlocked, CheckCircle, Close } from "@smartrent/icons";
import {
  ConfirmDialog,
  Typography,
  Link,
  MenuOption,
  PromptDialog,
  HStack,
  useTheme,
  Button,
} from "@smartrent/ui";
import { useClipboard, useModalState } from "@smartrent/hooks";

import Skeleton from "react-loading-skeleton";
import { get, startCase, compact, first, filter } from "lodash-es";

import { useApi } from "@/lib/hooks";
import { userForgotPasswordLink, userInvitationLink } from "@/utils/links";
import { useDocumentTitle } from "@/hooks/use-document-title";

import Layout from "@/layout/Layout";
import Context from "@/layout/Context";
import Helpers from "@/lib/helpers";

import Resident from "@/common/Resident";
import { PanelCard } from "@/common/PanelCard";
import { EmailWebhooks } from "@/common/EmailWebhooks";
import { ManagerQuickActions } from "@/common/links/SmartLinks";
import { AssignedUserGroupsTable } from "@/pages/user/AssignedUserGroupsTable";
import { SMSWebhookCard } from "@/pages/user/SMSWebhookCard";
import { LoginActivity } from "@/pages/user/LoginActivity";

import { formatDate, formatDateToBeHumanReadable, formatPhone } from "@/utils";

import {
  useDeleteSmartRentUser,
  useDeleteUserTFACredentials,
  useChangeSmartRentLogin,
  useUpdateUserEmailMutation,
  useRemoveServiceAccount,
} from "@/api/users";

import { ScrollArea } from "@/_v2/components/ui/scroll-area";

import {
  ResidentProps,
  UserProps,
  UnitProps,
  GroupProps,
  OrganizationProps,
  AccountProps,
  EmployeeRole,
  ConfirmationModalProps,
  PromptModalProps,
} from "../types";

function fetchWebhooks({ userId }: { userId: number }) {
  return Helpers.fetch({
    path: `/api/users/${userId}/webhooks`,
  });
}
export function User() {
  useDocumentTitle("User Details");
  const { colors } = useTheme();
  const context = useContext(Context);
  const currentUser = context.user as AccountProps;
  const match = context.match;
  const [user, setUser] = useState<UserProps | null>(null);
  const { ClipboardIcon, onCopy } = useClipboard();

  const [webhooks, setWebhooks] = useState<any[]>([]);
  const { visible, onOpen, onClose } = useModalState();
  const {
    visible: promptVisible,
    onOpen: promptOnOpen,
    onClose: promptOnClose,
  } = useModalState();

  const [residents, setResidents] = useState<ResidentProps[]>([]);
  const [residentCount, setResidentCount] = useState<{
    user_id: number;
    count: number;
  } | null>(null);
  const [units, setUnits] = useState<UnitProps[]>([]);

  const [role, setRole] = useState<EmployeeRole | null>(null);
  const [groups, setGroups] = useState<GroupProps[]>([]);
  const [organizations, setOrganizations] = useState<OrganizationProps[]>([]);

  const [confirmationModal, setConfirmationModalProps] =
    useState<ConfirmationModalProps>();
  const [promptModal, setPromptModalProps] = useState<PromptModalProps>();

  const [deleteUser] = useDeleteSmartRentUser();
  const [disableTFA] = useDeleteUserTFACredentials();
  const [updateSmartrentLogin] = useChangeSmartRentLogin();
  const [updateUserEmail] = useUpdateUserEmailMutation();
  const [removeServiceAccount] = useRemoveServiceAccount();

  const permissions = currentUser.permissions;

  const { response } = useApi({
    url: `/users/${match?.params?.userId}`,
    trigger: [match?.params?.userId],
  });

  useEffect(() => {
    if (user?.id) {
      fetchWebhooks({ userId: user.id }).then(({ webhooks }) =>
        setWebhooks(webhooks)
      );
    }
  }, [user]);

  useEffect(() => {
    if (response && response.data) {
      setUser(response.data.user);
      setResidents(response.data.residents);
      setResidentCount(response.data.residentCount);
      setUnits(response.data.units);
      setGroups(response.data.groups);
      setOrganizations(response.data.organizations);
      setRole(response.data.role);
    }
  }, [response]);

  const openConfirmationModal = useCallback(
    (confirmationProps: ConfirmationModalProps) => {
      setConfirmationModalProps({
        ...confirmationProps,
        onConfirm: () => {
          confirmationProps.onConfirm();
          onClose();
        },
      });
      onOpen();
    },
    [onClose, onOpen]
  );

  const openPromptModal = useCallback(
    (promptProps: PromptModalProps) => {
      setPromptModalProps({
        ...promptProps,
        onConfirm: (inputValue) => {
          promptProps.onConfirm(inputValue);
          promptOnClose();
        },
      });
      promptOnOpen();
    },
    [promptOnClose, promptOnOpen]
  );

  const forgotPasswordExpiresAt =
    user &&
    moment(user.forgot_password_sent_at).add(15, "minutes").format("L LT");
  const forgotPasswordExpired = moment(forgotPasswordExpiresAt).isBefore(
    moment()
  );

  const userManagementDisplayItems = get(permissions, "account_links")
    ? [
        {
          label: "Invite Link",
          text: user ? (
            user.invitation_token ? (
              <View style={styles.inviteLine}>
                <View
                  style={[
                    styles.inputContainer,
                    { backgroundColor: colors.mutedBackgroundGrayscale },
                  ]}
                >
                  <TextInput
                    style={styles.input}
                    value={userInvitationLink(user.invitation_token)}
                    editable={false}
                  />
                </View>
                <Button
                  variation="plain"
                  size="x-small"
                  color="primary"
                  style={styles.copyButton}
                  onPress={() => {
                    onCopy(userInvitationLink(user.invitation_token));
                  }}
                >
                  <ClipboardIcon color={colors.primary} />
                </Button>
              </View>
            ) : (
              "N/A"
            )
          ) : (
            <Skeleton width={145} />
          ),
        },
        {
          label: "Forgot Password Link",
          text: user ? (
            user.forgot_password_token ? (
              <Text>
                <input
                  className="input__input u-size-full"
                  value={userForgotPasswordLink(user.forgot_password_token)}
                  readOnly
                />
                <div className="u-font14">
                  (
                  {forgotPasswordExpired ? (
                    <span className="u-text-symbolic-warning">expired</span>
                  ) : (
                    <span className="u-text-symbolic-knowledge">
                      expires after {forgotPasswordExpiresAt}
                    </span>
                  )}
                  )
                </div>
              </Text>
            ) : (
              "N/A"
            )
          ) : (
            <Skeleton width={145} />
          ),
        },
      ]
    : [];

  const residentItems =
    user && get(user, "role") === "consumer"
      ? [
          {
            // This item could be moved to all user views in the future if we
            // require tos agreements for property staff
            label: "Terms of Service Accepted At",
            text: user ? (
              <Text>
                {user.tos_accepted_at ? (
                  <View>
                    <div className="u-flex u-flex-align-items-center">
                      <span className="u-mright4">
                        <CheckCircle size={16} color={Helpers.colors.green} />
                      </span>
                      Yes {user.saml_id ? ` (saml)` : ""}
                    </div>
                    <div className="u-font11 u-mtop4">
                      accepted at {Helpers.formatDate(user.tos_accepted_at)}
                    </div>
                  </View>
                ) : (
                  <div className="u-flex u-flex-align-items-center">
                    <Close size={18} color={Helpers.colors.gray} /> No
                  </div>
                )}
              </Text>
            ) : (
              <Skeleton width={145} />
            ),
          },
          {
            label: "Connected Resident Records",
            text:
              residentCount !== null ? (
                <Text>{residentCount.count}</Text>
              ) : (
                <Skeleton width={145} />
              ),
          },
        ]
      : [];

  const homeOwnerItems =
    user && get(user, "role") === "home_owner"
      ? [
          {
            label: "Connected Home Owner Records",
            text:
              residentCount !== null ? (
                <Text>{residentCount.count}</Text>
              ) : (
                <Skeleton width={145} />
              ),
          },
        ]
      : [];

  const propertyStaffItems =
    user &&
    role &&
    get(user, "role", "") === "employee" &&
    permissions.resident_details
      ? compact([
          {
            label: "Organization",
            text: (
              <Link href={`/organizations/${first(organizations)?.id}`}>
                {first(organizations)?.name}
              </Link>
            ),
          },

          {
            label: "Role",
            text: (
              <div>
                <div>{role.name}</div>

                <ScrollArea
                  className="h-48 p-1 pr-3 border border-border rounded-sm"
                  type="always"
                >
                  {/* <View
                  style={[
                    styles.scrollContainer,
                    {
                      backgroundColor: colors.listBackground,
                    },
                  ]}
                > */}
                  {role &&
                    compact(
                      Object.keys(role).map(
                        (permission: keyof EmployeeRole, index) => {
                          if (
                            [
                              "id",
                              "name",
                              "inserted_at",
                              "updated_at",
                              "organization_id",
                            ].includes(permission)
                          ) {
                            return null;
                          }

                          return (
                            <HStack
                              key={permission}
                              justify="space-between"
                              align="center"
                              width="100%"
                              style={{
                                backgroundColor:
                                  index % 2 === 0
                                    ? colors.listBackground
                                    : colors.listBackgroundHover,
                              }}
                            >
                              <Typography type="bodySmall">
                                {startCase(permission)}:{" "}
                              </Typography>

                              <Typography
                                type="bodySmallSemibold"
                                color={
                                  role[permission] ? "success400" : "error500"
                                }
                              >
                                {role[permission] ? "Yes" : "No"}
                              </Typography>
                            </HStack>
                          );
                        }
                      )
                    )}
                </ScrollArea>
              </div>
            ),
          },

          {
            label: "Last Sign In",
            text: user ? (
              user.last_sign_in_at ? (
                <View>
                  <Typography type="body">
                    {formatDateToBeHumanReadable({
                      date: user.last_sign_in_at,
                    })}
                  </Typography>
                  <Typography type="bodySmall">
                    at{" "}
                    {formatDate({
                      date: user.last_sign_in_at,
                    })}{" "}
                  </Typography>
                </View>
              ) : (
                "N/A"
              )
            ) : (
              <Skeleton width={145} />
            ),
          },

          {
            label: "Sign In Count",
            text: user ? (
              user.sign_in_count ? (
                <Typography type="body">
                  {Number(user.sign_in_count).toLocaleString()}
                </Typography>
              ) : (
                "N/A"
              )
            ) : (
              <Skeleton width={145} />
            ),
          },
        ])
      : [];

  const cardActions: MenuOption[] = compact([
    user &&
    permissions.account_management &&
    !permissions.change_user_email &&
    residents.length === 1 &&
    !user.invitation_token &&
    !user.deleted_at
      ? {
          label: "Change SmartRent Login",
          onPress: () => {
            openConfirmationModal({
              title: "Change SmartRent Login?",
              description: `By submitting this request, you are confirming that you verified the user's identity, that this new email address belongs to that user, and that the user requested this change.

A confirmation email will be sent to: "${residents[0]?.email}".
The user needs to confirm their new email for the change to be effective.
              `,
              confirmText: "Change",
              onConfirm: () => {
                updateSmartrentLogin({
                  userId: user.id,
                  payload: { email: residents[0]?.email },
                });
              },
            });
          },
        }
      : null,
    user &&
    permissions.change_user_email &&
    !user.invitation_token &&
    !user.deleted_at
      ? {
          label: "Change SmartRent Login",
          onPress: () => {
            openPromptModal({
              title: "Change SmartRent Login?",
              description:
                "By submitting this request, you are confirming that the user requested to change their SmartRent login.",
              confirmText: "Continue",
              label: "Email",
              onConfirm: (inputValue) => {
                updateSmartrentLogin({
                  userId: user.id,
                  payload: { email: inputValue },
                });
                onClose();
              },
              defaultValue: user.email,
            });
          },
        }
      : null,

    permissions.delete_user_accounts &&
    user &&
    ["consumer", "home_owner"].includes(get(user, "role")) &&
    !user.deleted_at &&
    filter(residents || [], { moved_in: true }).length === 0
      ? {
          label: "Delete SmartRent User",
          onPress: () => {
            openConfirmationModal({
              title: "Delete SmartRent User?",
              description:
                "Are you sure you want to delete this SmartRent user?",
              confirmText: "Delete",
              onConfirm: () => {
                deleteUser({
                  userId: user.id,
                });
              },
            });
          },
        }
      : null,

    permissions.remove_service_account &&
    user &&
    get(user, "service_account") === true &&
    !user.deleted_at &&
    filter(residents || [], { moved_in: true }).length === 0
      ? {
          label: "Remove Service Account",
          onPress: () => {
            openConfirmationModal({
              title: "Remove Service Account?",
              description:
                "Are you sure you want to remove the service account from this user?",
              confirmText: "Confirm",
              onConfirm: () => {
                removeServiceAccount({
                  userId: user.id,
                });
              },
            });
          },
        }
      : null,
  ]);

  if (
    permissions.tfa_assistance &&
    user &&
    get(user, "tfa_enabled") &&
    !user.deleted_at
  ) {
    cardActions.push({
      label: "Disable TFA",
      onPress: () => {
        openConfirmationModal({
          title: "Disable TFA?",
          description:
            "By submitting this request, you are confirming that the user requested to disable TFA, that they don't have any backup TFA codes, and you have also verified their identity by asking the user which email address and phone number they have on file for their SmartRent account.",
          confirmText: "Disable",
          onConfirm: () => {
            disableTFA({
              userId: user.id,
            });
          },
        });
      },
    });
  }

  if (permissions.change_user_email && user && get(user, "invitation_token")) {
    // Change email will be available before the user accepts their account.
    // Change smartrent login will be once they've accepted it.
    // As before they accept, we can just change the email, then resend an invite
    // that will go to the updated email, but post acceptance, it will trigger an
    // email change request that needs to be confirmed by the user.
    cardActions.push({
      label: "Change Email",
      onPress: () => {
        openPromptModal({
          title: "Change Email?",
          description:
            "By submitting this request, you are confirming that you verified the user's identity, that this new email address belongs to that user, and that the user requested this change.",
          confirmText: "Continue",
          label: "Email",
          onConfirm: (inputValue) => {
            updateUserEmail({
              userId: user.id,
              payload: { email: inputValue },
            });
          },
          defaultValue: user.email,
        });
      },
    });
  }

  return (
    <Layout>
      <View style={styles.container}>
        {context?.user?.permissions?.core_platform_access &&
        organizations.length ? (
          user &&
          ["consumer", "home_owner"].includes(user.role) &&
          groups.length &&
          units.length &&
          residents.length ? (
            <ManagerQuickActions
              smartLinks={[
                {
                  title: "Resident Overview",
                  href: `/manager/groups/${first(groups)?.id}/units/${
                    first(units)?.id
                  }/residents/${first(residents)?.id}`,
                  organizationUrl: first(organizations)?.url || "",
                },
              ]}
            />
          ) : (
            <ManagerQuickActions
              smartLinks={[
                {
                  title: "User Overview",
                  href: `/manager/users/${user?.id}/edit`,
                  organizationUrl: first(organizations)?.url || "",
                },
              ]}
            />
          )
        ) : null}
        <View style={styles.cardContainer}>
          <PanelCard
            actions={cardActions.length ? cardActions : undefined}
            title={user ? `${user.first_name} ${user.last_name}` : undefined}
            statusBadge={
              user?.deleted_at
                ? {
                    status: "error",
                    statusLabel: "DELETED",
                  }
                : user?.service_account
                  ? {
                      status: "knowledge",
                      statusLabel: "SERVICE ACCOUNT",
                    }
                  : null
            }
          >
            <FlatList
              data={compact([
                ...propertyStaffItems,
                get(permissions, "resident_details")
                  ? {
                      label: "SmartRent Login",
                      text: user ? (
                        user.email ? (
                          <View>
                            <Text>
                              <span>{user.email} </span>
                              {webhooks &&
                              webhooks.length &&
                              webhooks.find((w) => w.event === "dropped") ? (
                                <span className="u-text-symbolic-error">
                                  UNDELIVERABLE
                                </span>
                              ) : null}
                            </Text>
                          </View>
                        ) : (
                          "N/A"
                        )
                      ) : (
                        <Skeleton width={145} />
                      ),
                    }
                  : null,
                get(permissions, "resident_details")
                  ? {
                      label: "Mobile Phone",
                      text: user ? (
                        user.mobile_phone ? (
                          <Text>
                            {formatPhone({ phone: user.mobile_phone })}
                          </Text>
                        ) : (
                          "N/A"
                        )
                      ) : (
                        <Skeleton width={145} />
                      ),
                    }
                  : null,
                {
                  label: "Account Accepted",
                  text: user ? (
                    <View>
                      <Text>
                        {user.password_set || user.saml_id ? (
                          <HStack spacing={4} align="center">
                            <CheckCircle
                              size={16}
                              color={Helpers.colors.green}
                            />
                            <Typography>
                              Yes {user.saml_id ? ` (saml)` : ""}
                            </Typography>
                          </HStack>
                        ) : (
                          <HStack spacing={4} align="center">
                            <Close size={18} color={Helpers.colors.gray} />
                            <Typography>No</Typography>
                          </HStack>
                        )}
                      </Text>
                      {user.inserted_at ? (
                        <div className="u-font11 u-mtop4">
                          created at {Helpers.formatDate(user.inserted_at)}
                        </div>
                      ) : null}
                      {user.password_set && user.password_updated_at ? (
                        <div className="u-font11 u-text-symbolic-knowledge">
                          password updated on{" "}
                          {Helpers.formatDate(user.password_updated_at)}
                        </div>
                      ) : null}
                    </View>
                  ) : (
                    <Skeleton width={145} />
                  ),
                },
                {
                  label: "TFA enabled",
                  text: user ? (
                    <Text>
                      {user.tfa_enabled ? (
                        <div className="u-flex u-flex-align-items-center">
                          <Locked size={18} color={Helpers.colors.green} /> Yes
                        </div>
                      ) : (
                        <div className="u-flex u-flex-align-items-center">
                          <Unlocked size={18} color={Helpers.colors.gray} /> No
                        </div>
                      )}
                    </Text>
                  ) : (
                    <Skeleton width={145} />
                  ),
                },
                get(permissions, "resident_details")
                  ? {
                      label: "Email Webhooks",
                      text: user ? (
                        <Text>
                          <EmailWebhooks user={user} />
                        </Text>
                      ) : (
                        <Skeleton width={145} />
                      ),
                    }
                  : null,
                ...residentItems,
                ...homeOwnerItems,
                ...userManagementDisplayItems,
              ])}
              renderItem={({ item }) => (
                <View
                  style={[
                    styles.listItem,
                    {
                      backgroundColor: colors.listBackground,
                      borderBottomColor: colors.border,
                    },
                  ]}
                >
                  <Typography color="primary" variation="semibold.body.medium">
                    {item.label}
                  </Typography>
                  <Typography variation="regular.body.medium">
                    {item.text}
                  </Typography>
                </View>
              )}
              keyExtractor={(item) => item.label}
              style={{ position: "relative", zIndex: -1 }}
            />
          </PanelCard>
          {permissions.account_management && user ? (
            <View style={styles.cardMarginLeft}>
              <LoginActivity userId={user.id} />
            </View>
          ) : null}
          <View style={styles.cardMarginLeft}>
            <SMSWebhookCard
              phone={user?.mobile_phone?.replace("+", "") || ""}
            />
          </View>
        </View>
        {!!residents.length && (
          <View style={styles.residentCard}>
            {residents.map((resident) => {
              const unit =
                resident && units.find(({ id }) => id === resident.unit_id);
              const group =
                unit && groups.find(({ id }) => id === unit.group_id);
              const organization =
                group &&
                organizations.find(
                  ({ id }) => Number(id) === Number(group.organization_id)
                );

              if (user && unit && group && organization) {
                return (
                  <Resident
                    permissions={permissions}
                    resident={resident}
                    user={user}
                    resident_users={residentCount || {}}
                    key={resident.id}
                    unit={unit}
                    group={group}
                    organization={organization}
                    hideUserSection={true}
                    residents={[]}
                    accessCode={null}
                    CMW_BASE_URL={get(response, "data.CMW_BASE_URL", null)}
                  />
                );
              } else {
                return null;
              }
            })}
          </View>
        )}
      </View>

      {user &&
      role &&
      get(user, "role", "") === "employee" &&
      permissions.resident_details ? (
        <View style={styles.container}>
          <AssignedUserGroupsTable user={user} role={role} />
        </View>
      ) : null}

      <ConfirmDialog
        title={confirmationModal?.title}
        description={confirmationModal?.description}
        visible={visible}
        onConfirm={confirmationModal?.onConfirm}
        onClose={onClose}
      />

      <PromptDialog
        title={promptModal?.title}
        description={promptModal?.description}
        visible={promptVisible}
        onConfirm={promptModal?.onConfirm}
        inputLabel={promptModal?.label}
        onClose={promptOnClose}
        defaultInputValue={promptModal?.defaultValue}
      />
    </Layout>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "column",
    padding: 16,
  },
  cardContainer: {
    flexDirection: "row",
  },
  residentCard: {
    flexDirection: "row",
    marginTop: 16,
    flexWrap: "wrap",
  },
  cardMarginLeft: {
    marginLeft: 16,
  },
  listItem: {
    borderBottomWidth: 1,
    paddingVertical: 6,
    zIndex: 2,
  },
  inviteLine: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    position: "relative",
    flexGrow: 1,
  },
  inputContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    height: 32,
    paddingHorizontal: 8,
    flexGrow: 1,
    marginRight: 4,
    borderRadius: 4,
  },
  input: {
    height: 48,
    flex: 1,
  },
  copyButton: {
    height: 32,
    paddingHorizontal: 4,
  },
});
