import { useRouteMatch } from "react-router-dom";
import { Line } from "react-chartjs-2";

import Layout from "@/layout/Layout";

import Graph from "@/common/Graph";
import { useDocumentTitle } from "@/hooks/use-document-title";

export function HubInstallHealthDashboard() {
  useDocumentTitle("Hub Install Health Dashboard");
  const match: { params: { hub_type: string } } = useRouteMatch();

  return (
    <Layout>
      <Graph
        path={`/reports/installations/pairing-daily-health?hub_type=${match.params.hub_type}`}
        renderProps={({ labels, datasets }) => (
          <Line
            data={{
              labels,
              datasets,
            }}
            options={{
              title: {
                display: true,
                text: "Daily Pairing Health",
              },
            }}
            height={45}
          />
        )}
      />
      <Graph
        path={`/reports/installations/time-until-device-goes-offline?hub_type=${match.params.hub_type}`}
        renderProps={({ labels, datasets }) => (
          <Line
            data={{
              labels,
              datasets,
            }}
            options={{
              title: {
                display: true,
                text: "Time Until Device Goes Offline",
              },
            }}
            height={45}
          />
        )}
      />
      <Graph
        path={`/reports/installations/lock-pairing-times-by-step?hub_type=${match.params.hub_type}`}
        renderProps={({ labels, datasets }) => (
          <Line
            data={{
              labels,
              datasets,
            }}
            options={{
              title: {
                display: true,
                text: "Lock Installation Step Times",
              },
            }}
            height={45}
          />
        )}
      />
    </Layout>
  );
}
