import { useState, useEffect, useContext } from "react";
import {
  map,
  first,
  last,
  get,
  orderBy,
  compact,
  groupBy,
  reduce,
  each,
  kebabCase,
  without,
} from "lodash-es";
import moment from "moment";
import classNames from "classnames";
import { CSVLink } from "react-csv";
import { Link, useTheme } from "@smartrent/ui";
import { sub, endOfDay, startOfDay } from "date-fns";
import { DateRange } from "react-day-picker";

import Context from "@/layout/Context";
import Helpers from "@/lib/helpers";
import { SectionLoader } from "@/common/Loader";
import { DatePickerWithRange } from "@/_v2/components/composites/date-picker-with-range";

function UnitBatteryReport({
  batteryDiff,
  earliestReport,
  latestReport,
  battery_levels,
  device_id,
  internal_name,
}) {
  const { colors } = useTheme();
  const [collapsed, setCollapsed] = useState(true);
  return (
    <div key={device_id} className="u-mbottom4">
      <div className="u-sans-bold">{internal_name}</div>
      <div>
        <div className="u-text-symbolic-error">{batteryDiff}% drop</div>
        <div className="u-font12" style={{ color: colors.textPrimary }}>
          {Helpers.formatDate(get(earliestReport, "inserted_at", ""), "MM/DD")}
          {" - "}
          {Helpers.formatDate(get(latestReport, "inserted_at", ""), "MM/DD")}
        </div>
      </div>

      {collapsed && (
        <div
          className="u-sans-bold u-mtop8 u-font12 cursor--pointer"
          onClick={(e) => setCollapsed(!collapsed)}
        >
          View Data
        </div>
      )}

      {!collapsed && (
        <div
          onClick={() => setCollapsed(!collapsed)}
          className="cursor--pointer"
        >
          <div className="u-sans-bold u-mtop8 u-font12">Data:</div>
          {map(
            battery_levels,
            ({ battery_level, device_id, inserted_at }, idx) => {
              const prevLevel = battery_levels[Number(idx) - 1];

              return (
                <div
                  key={`${device_id}-${idx}`}
                  data-device-id={device_id}
                  className={classNames({
                    "u-text-symbolic-knowledge u-sans-bold":
                      prevLevel && prevLevel.battery_level < battery_level,
                  })}
                >
                  {Helpers.formatDate(inserted_at, "MM/DD")} - {battery_level}
                </div>
              );
            }
          )}
        </div>
      )}
    </div>
  );
}

function UnitBatteryReports({ devices }) {
  const { marketing_name, unit_id } = first<any>(devices);
  const { colors } = useTheme();
  return (
    <div
      className="panel u-mright12"
      style={{
        minWidth: "175px",
        backgroundColor: colors.raisedSurfaces,
        borderColor: colors.border,
      }}
    >
      <div
        className="panel__header panel__header--border"
        style={{
          borderColor: colors.border,
        }}
      >
        <Link target="_blank" href={`/units/${unit_id}`} key={marketing_name}>
          <div className="h3">{marketing_name}</div>
        </Link>
      </div>

      <div className="panel__content">
        {map(devices, (device, idx) => {
          return <UnitBatteryReport key={idx} {...device} />;
        })}
      </div>
    </div>
  );
}

function DateRangeFilter({ location, history }) {
  const qs = Helpers.qsFromLocation(location);
  const defaultStartDate = sub(new Date(), { days: 30 });
  const defaultEndDate = new Date();

  const onDatesChange = ({ from, to }: DateRange) => {
    const newStartDate = !!from ? startOfDay(from) : defaultStartDate;
    const newEndDate = !!to ? endOfDay(to) : defaultEndDate;

    Helpers.updateQS({
      history,
      update: {
        startDate: newStartDate.toISOString(),
        endDate: newEndDate.toISOString(),
      },
      location,
    });
  };

  return (
    <DatePickerWithRange
      startDate={qs.startDate ? new Date(qs.startDate) : defaultStartDate}
      endDate={qs.endDate ? new Date(qs.endDate) : defaultEndDate}
      onDatesChange={onDatesChange}
      defaultStartDate={defaultStartDate}
      defaultEndDate={defaultEndDate}
    />
  );
}

function DeviceTypeOption({ location, history, value, label, types }) {
  const { colors } = useTheme();
  const isActive = types.includes(value);

  return (
    <div
      className={classNames({
        "u-sans-bold": isActive,
        "cursor--pointer": true,
        "u-mright4": true,
      })}
      style={{
        borderRadius: "4px",
        border: "1px solid #333",
        padding: "0 8px",
        backgroundColor: isActive ? Helpers.colors.primary : colors.background,
        color: colors.textPrimary,
      }}
      onClick={() =>
        Helpers.updateQS({
          history,
          location,
          update: {
            types: types.includes(value)
              ? without(types, value)
              : [...types, value],
          },
        })
      }
    >
      {label}
    </div>
  );
}

function DeviceTypeSelect({ location, history }) {
  const { colors } = useTheme();
  const qs = Helpers.qsFromLocation(location);
  const types: string[] = qs.types
    ? Array.isArray(qs.types)
      ? qs.types
      : [qs.types]
    : [];

  return (
    <div className="u-mbottom12">
      <div style={{ color: colors.textPrimary }}>Filter by device type:</div>
      <div className="u-flex u-flex-wrap">
        <DeviceTypeOption
          label="Locks"
          value="entry_control"
          location={location}
          history={history}
          types={types}
        />
        <DeviceTypeOption
          label="Thermostats"
          value="thermostats"
          location={location}
          history={history}
          types={types}
        />
        <DeviceTypeOption
          label="Sensors"
          value="sensor_notification"
          location={location}
          history={history}
          types={types}
        />
        <DeviceTypeOption
          label="Shades"
          value="shade_control"
          location={location}
          history={history}
          types={types}
        />
        <DeviceTypeOption
          label="Switches"
          value="switch_binary"
          location={location}
          history={history}
          types={types}
        />
        <DeviceTypeOption
          label="Dimmers"
          value="switch_multilevel"
          location={location}
          history={history}
          types={types}
        />
      </div>
    </div>
  );
}

export default function BatteryReports({ groupId }) {
  const { colors } = useTheme();
  const { location, history } = useContext(Context);
  const [units, setUnits] = useState([]);
  const [property, setProperty] = useState(null);
  const [threshold, setThreshold] = useState(15);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    Helpers.fetch({
      path: `/api/groups/${groupId}`,
    }).then(({ property }) => setProperty(property));
  }, [groupId]);

  useEffect(() => {
    setLoading(true);
    Helpers.fetch({
      path: `/api/reports/groups/${groupId}/battery-levels`,
      query: Helpers.qsFromLocation(location),
    }).then((records) => {
      setUnits(records);
      setLoading(false);
    });
  }, [location, groupId]);

  const unitCount = Object.keys(units).length;

  // Creates an array of arrays
  // The top-level array is a unit and the array inside it is the devices for that unit
  const filteredUnits = compact(
    map(units, (battery_levels, marketing_name: string) => {
      const groupedBatteryLevels = groupBy(battery_levels, "device_id");

      const reports = compact(
        map(groupedBatteryLevels, (grouped_levels, device_id) => {
          // Most recent change where battery went up (or first record)
          const highestReport = reduce(
            grouped_levels,
            (memo, report, idx) => {
              const prevReport = grouped_levels[Number(idx) - 1];
              if (
                prevReport &&
                prevReport.battery_level < report.battery_level
              ) {
                return report;
              }

              return memo;
            },
            grouped_levels[0]
          );

          const earliestReport = first(grouped_levels);
          const latestReport = last(grouped_levels);
          const batteryDiff =
            get(highestReport, "battery_level", 0) -
            get(latestReport, "battery_level", 0);

          if (!batteryDiff || batteryDiff <= threshold) {
            return null;
          }

          return {
            device_id,
            highestReport,
            earliestReport,
            latestReport,
            batteryDiff,
            marketing_name,
            battery_levels: grouped_levels,
            unit_id: get(highestReport, "unit_id", ""),
            internal_name: latestReport.internal_name,
          };
        })
      );

      return reports && reports.length ? reports : null;
    })
  );

  // Get units with most significantly dropped batteries even if there are two or more installed
  const orderedUnits = orderBy(
    filteredUnits,
    (devices) => {
      return reduce(
        devices,
        (memo, device) => {
          return device.batteryDiff > memo ? device.batteryDiff : memo;
        },
        0
      );
    },
    ["desc"]
  );

  const csvData = [];
  if (property && orderedUnits && orderedUnits.length) {
    each(orderedUnits, (devices) => {
      each(
        devices,
        ({
          marketing_name,
          latestReport,
          highestReport,
          batteryDiff,
          internal_name,
        }) => {
          csvData.push({
            organizationName: property.organization_name,
            propertyName: property.group_name,
            unitName: marketing_name,
            currentBatteryLevel: latestReport.battery_level,
            highestBatteryLevel: highestReport.battery_level,
            amountDropped: batteryDiff,
            internalName: internal_name,
          });
        }
      );
    });
  }

  const csvHeaders = [
    { label: "Organization", key: "organizationName" },
    { label: "Property", key: "propertyName" },
    { label: "Unit", key: "unitName" },
    { label: "Device", key: "internalName" },
    { label: "Highest Battery Level", key: "highestBatteryLevel" },
    { label: "Current Battery Level", key: "currentBatteryLevel" },
    { label: "Amount Dropped", key: "amountDropped" },
  ];

  return (
    <div className="u-p8">
      <div className="u-mbottom8 u-flex u-flex-wrap u-flex-justify-between u-flex-align-items-center">
        <div>
          <span className="u-text-symbolic-error u-sans-bold">
            {orderedUnits.length} units
          </span>{" "}
          had a battery drop greater than{" "}
          <input
            type="number"
            onChange={(e) => setThreshold(Number(e.currentTarget.value))}
            value={threshold}
            style={{ width: "45px", backgroundColor: colors.inputBackground }}
          />
          %
        </div>

        <DeviceTypeSelect location={location} history={history} />
        <DateRangeFilter location={location} history={history} />
      </div>
      <div className="u-mtop8 u-mbottom8 u-font12">
        Note: Many devices round to the nearest 5% battery level, rounded down.
        This means that it can be normal to see a 5% drop within a few day
        period.
        <div>
          This report also won't show devices with battery changes where the
          device or the hub is offline.
        </div>
      </div>
      {!(orderedUnits && orderedUnits.length) && (
        <div>
          No abnormal battery readings{" "}
          {unitCount !== 0 ? `- ${unitCount} units scanned` : ""}
        </div>
      )}

      <div className="u-flex u-flex-wrap">
        {loading && <SectionLoader />}
        {!loading &&
          map(orderedUnits, (devices, idx) => {
            return <UnitBatteryReports key={idx} devices={devices} />;
          })}
      </div>

      {property && orderedUnits && !!orderedUnits.length && (
        <div>
          <CSVLink
            className="button"
            data={csvData}
            headers={csvHeaders}
            filename={`battery-report-${kebabCase(
              property.organization_name
            )}-${kebabCase(property.group_name)}-${moment().format(
              "YYYY-MM-DD"
            )}`}
          >
            <div className="button__layout">Download CSV</div>
          </CSVLink>
        </div>
      )}
    </div>
  );
}
