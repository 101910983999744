import { useMedia } from "react-use";

// react-use's createBreakpoint uses window.innerWidth, which was causing
// some UI thrashing. This implementation is simpler, but it uses window.matchMedia
// instead.
function createBreakpoint<T extends Record<string, number>>(breakpoints: T) {
  const sortedBreakpoints = Object.entries(breakpoints).sort((a, b) =>
    a[1] >= b[1] ? -1 : 1
  );

  return (): keyof T => {
    const matches = sortedBreakpoints.map(([name, minWidth]) => {
      return [name, useMedia(`(min-width: ${minWidth}px)`)];
    });

    const firstMatch =
      matches.find(([name, matches]) => matches) ?? sortedBreakpoints[0];

    return firstMatch[0] as any;
  };
}

// mirrors assets/scss/settings/_breakpoints.scss
export const useBreakpoint = createBreakpoint({
  xs: 0, //0em
  sm: 320, // 20em
  md: 768, // 48em
  lg: 992, // 62em
  xl: 1280,
  "2xl": 1536,
});

export const useIs2XLDesktop = () => useBreakpoint() === "2xl";
export const useIsXLDesktop = () => useBreakpoint() === "xl";
export const useIsLgDesktop = () => useBreakpoint() === "lg";
export const useIsTablet = () => useBreakpoint() === "md";
export const useIsPhone = () => useBreakpoint() === "sm";
export const useIsDesktop = () => ["lg", "xl", "2xl"].includes(useBreakpoint());
export const useIsMobile = () => ["md", "sm", "xs"].includes(useBreakpoint());

export const useCardBreakpoint = createBreakpoint({
  minWidth: 480,
  zero: 0,
});

export const useIsMobileCard = () => useCardBreakpoint() === "zero";
