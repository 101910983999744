import { useParams } from "react-router-dom";

import { instance } from "@/lib/hooks";
import { createAxiosQuery } from "@/hooks/react-query";
import { tableQueryToQS } from "@/lib/helpers";

import { UnitPageResponseProps, UnitProps } from "@/_v2/types/unit";

import { PaginatedResponse, TableQuery } from "../types/api";

export const useCurrentUnit = () => {
  const { unitId } = useParams<{ unitId: string }>();

  return useUnitPageQuery({ unitId });
};

export const useUnitPageQuery = createAxiosQuery(
  "unit",
  async (filters?: { unitId: string }) => {
    return instance.get<UnitPageResponseProps>(`/v2/units/${filters?.unitId}`);
  }
);

export type UnitResponse = Pick<
  UnitProps,
  "id" | "marketing_name" | "status" | "building" | "floor"
>;
export interface UnitsResponseProps extends PaginatedResponse<UnitResponse> {}

export const useUnitsQuery = createAxiosQuery(
  "units",
  async (filters?: TableQuery) => {
    const query = tableQueryToQS(filters);

    return instance.get<UnitsResponseProps>(`/v2/units?${query}`);
  }
);
