import { useContext, useEffect, useMemo, useState, useCallback } from "react";
import { StyleSheet, View } from "react-native";
import { CSVLink } from "react-csv";

import { kebabCase, omit, startCase, camelCase } from "lodash-es";

import {
  Button,
  ColumnOptions,
  FormikTextInputField,
  MessageBanner,
  Spacer,
  Table,
  Typography,
  VStack,
} from "@smartrent/ui";
import { Download, InformationSolid } from "@smartrent/icons";

import Layout from "@/layout/Layout";
import Context from "@/layout/Context";
import helpers from "@/lib/helpers";
import { useUrlFilters } from "@/hooks/url-filters";
import { useFetchCustomReportsByPath } from "@/api/reports";
import { formatDate } from "@/utils";
import { useDocumentTitle } from "@/hooks/use-document-title";

interface CustomReportProps extends Record<string, any> {
  id: string;
  organization?: string;
  property?: string;
  region?: string;
  num_hubs?: string;
}

export interface CustomReportResponse extends Record<string, any> {
  organizations: any;
  groups: any;
  report: {
    rows: any;
    columns: any;
    lastRanAt: string;
  };
  supportedFilters?: Array<string>;
}

// @todo consolidate with MonthlyReportTable?
export default function CustomReports() {
  const [fetchedReport, setFetchedReport] = useState(false);

  const { location, match } = useContext(Context);

  const [filters, setFilters] = useState(helpers.qsFromLocation(location));

  const [defaultFilters] = useState(["organization_ids", "group_ids"]); // see api/lib/custom-report-helper.ts for usage.

  const reportName = match.params.reportName; // should be kebab cased like `move-in-exceptions`
  useDocumentTitle("Custom Report - " + reportName);

  // react query call
  const { data, isFetching, refetch } = useFetchCustomReportsByPath(
    {
      filters: omit(filters, ["page", "limit"]),
      path: `/custom-reports/${reportName}`,
    },
    {
      enabled: fetchedReport,
    }
  );

  const fetchReport = useCallback(() => {
    if (fetchedReport) {
      refetch();
    } else {
      setFetchedReport(true);
    }
  }, [refetch, fetchedReport]);

  // only table props use this hook
  const { filters: tableFilters, setAllFilters: setAllTableFilters } =
    useUrlFilters({ keys: defaultFilters });

  // We set the CustomReports filters based on API response data
  //
  // so.. in order to propagate the table's filters to our react query API call
  //
  // 1. we take advantage of useUrlFilters hook updating location..
  // 2. call this useEffect to update the state of our filters
  // 3. re-render & thereby 'refetching' our data with the updated filters
  //
  useEffect(() => {
    setFilters(helpers.qsFromLocation(location));
  }, [location]);

  const DISPLAY_ON_SCREEN = 25;

  const [totalRecords, setTotalRecords] = useState(data?.report?.rows.length);
  const [currentPage, setCurrentPage] = useState(filters?.page || 1);
  const [totalPages, setTotalPages] = useState(
    Math.ceil(data?.report?.rows.length / DISPLAY_ON_SCREEN)
  );
  const [pageSize] = useState(DISPLAY_ON_SCREEN);

  const getSupportedFilters = useMemo(() => {
    return data?.supportedFilters?.length
      ? data?.supportedFilters
      : defaultFilters;
  }, [data?.supportedFilters, defaultFilters]);

  const tableColumns: ColumnOptions<any>[] = useMemo(() => {
    setTotalRecords(data?.report?.rows.length);
    setTotalPages(Math.ceil(data?.report?.rows.length / DISPLAY_ON_SCREEN));

    // add columns
    const newData =
      data?.report?.columns.map((column, index) => {
        return {
          name: column,
          header: column,
          render: ({ row }) => {
            return <Typography numberOfLines={1}>{row[index]}</Typography>;
          },
        };
      }) ?? [];

    // add filters
    const newFilters =
      getSupportedFilters.map((filter, index) => {
        return {
          name: filter,
          header: filter,
          render: () => null,
          sortable: false,
          filter: () => <FormikTextInputField name={filter} label={filter} />,
          hidden: true,
        };
      }) ?? [];

    return [...newData, ...newFilters];
  }, [data?.report?.columns, data?.report?.rows.length, getSupportedFilters]);

  // we get our data all at once from /custom-reports endpoint
  // ..the table component only supports fetching data page by page
  // ..so we slice up the report's data to match the table's 'pageSize'
  const tableData: CustomReportProps[] = useMemo(() => {
    const prevPage = currentPage > 1 ? currentPage - 1 : 0;
    const dataSet = data?.report?.rows || [];
    return dataSet.length > 0
      ? dataSet.slice(prevPage * pageSize, currentPage * pageSize)
      : [];
  }, [data, currentPage, pageSize]);

  // Example:
  //  report      : Custom Reports / Hubs By Org
  //  csvFileName : hubs-by-org-2021-21-04-112137.csv
  const buildCsvFileName = useMemo(() => {
    if (data) {
      const { organizations, groups, report } = data;
      const { lastRanAt } = report;

      const orgName =
        organizations?.length === 1 ? organizations?.[0]?.name : "";
      const groupName = groups?.length === 1 ? groups?.[0]?.marketing_name : "";
      const timestamp = formatDate({
        date: lastRanAt,
        pattern: "yyyy MM dd HHmmss",
      });
      const kebabName = kebabCase(
        [orgName, groupName, reportName, timestamp].join(" ")
      );
      return `${kebabName}.csv`;
    }
    return "";
  }, [data, reportName]);

  return (
    <Layout>
      <View style={styles.card}>
        <View>
          <View style={styles.titleRow}>
            <VStack spacing={8} style={styles.tableDetailsContainer}>
              <Typography type="title3">
                {startCase(camelCase(reportName))}
              </Typography>

              {data ? (
                <Typography color="helperText" type="caption">
                  Supported filters: {data?.supportedFilters.join(", ")}
                </Typography>
              ) : null}
              <VStack spacing={4}>
                <View style={styles.tableDetailsRow}>
                  <Typography type="title5">Rows: </Typography>
                  <Typography font="regular" type="title6">
                    {data?.report?.rows?.length
                      ? data?.report?.rows.length.toLocaleString()
                      : 0}
                  </Typography>
                </View>

                <View style={styles.tableDetailsRow}>
                  <Typography type="title5">Organizations: </Typography>
                  <Typography font="regular" type="title6">
                    {data?.organizations?.length
                      ? data?.organizations.map(({ name }) => name).join(", ")
                      : "All organizations"}
                  </Typography>
                </View>

                <View style={styles.tableDetailsRow}>
                  <Typography type="title5">Groups: </Typography>
                  <Typography font="regular" type="title6">
                    {data?.groups?.length
                      ? data?.groups
                          .map(({ marketing_name }) => marketing_name)
                          .join(", ")
                      : "All groups"}
                  </Typography>
                </View>
              </VStack>
            </VStack>

            <View style={styles.tableCsvContainer}>
              {data ? (
                <CSVLink
                  style={{ justifyItems: "flex-end" }} // react-css
                  data={data?.report?.rows}
                  headers={data?.report?.columns}
                  filename={buildCsvFileName}
                >
                  <Button
                    size="small"
                    textStyle={styles.buttonText}
                    iconRight={Download}
                  >
                    Download CSV
                  </Button>
                </CSVLink>
              ) : null}
            </View>
          </View>
        </View>
      </View>

      <View style={styles.tableContainer}>
        {reportName === "jammed-lock-report" ? (
          <>
            <MessageBanner variation="info" icon={InformationSolid}>
              group_ids is a required filter for this report, and only 1 group
              can be selected at a time.
            </MessageBanner>
            <Spacer height={16} />
          </>
        ) : null}
        <Table<CustomReportProps>
          loading={isFetching}
          columns={tableColumns}
          action={
            <Button onPress={fetchReport} disabled={isFetching}>
              {isFetching ? "Loading..." : "Fetch Report"}
            </Button>
          }
          data={tableData}
          noRecordsText="No Records Found"
          totalRecords={totalRecords}
          currentPage={currentPage}
          totalPages={totalPages}
          pageSize={pageSize}
          onPageChange={(page) => {
            setCurrentPage(page);
          }}
          showClearFiltersButton={Object.keys(filters).length > 0}
          filters={tableFilters}
          onFiltersChange={setAllTableFilters}
        />
      </View>
    </Layout>
  );
}

const styles = StyleSheet.create({
  card: {
    padding: 16,
  },

  titleRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  tableDetailsContainer: { flexDirection: "column", marginBottom: 8 },
  tableDetailsRow: { flexDirection: "row" },
  tableCsvContainer: { flexDirection: "column", flexWrap: "wrap" },
  tableContainer: { margin: 16 },

  buttonText: { lineHeight: null },
});
