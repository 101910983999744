import { hostname } from "@/utils";

export const salesforceUrl = () => {
  switch (window.location.hostname) {
    // Prod
    case hostname.prod:
      return "https://smartrent.lightning.force.com";

    // QA
    case hostname.qa:
    case hostname.local:
      return "https://smartrent--full.sandbox.my.salesforce.com";

    // Default to test
    default:
      return "https://smartrent--full.sandbox.my.salesforce.com";
  }
};
