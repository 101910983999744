import { useContext, useEffect } from "react";
import { View, StyleSheet } from "react-native";

import { Button, HStack, VStack } from "@smartrent/ui";

import { useAccountsQuery } from "@/api/accounts";

import Layout from "@/layout/Layout";

import { useDrawerNav } from "@/common/AppDrawer";
import Context from "@/layout/Context";
import Helpers from "@/lib/helpers";
import { hostname } from "@/utils";
import { PermissionProps } from "@/types";
import { useDocumentTitle } from "@/hooks/use-document-title";

import Account from "./accounts/Account";

export default function Accounts() {
  useDocumentTitle("Accounts");
  const { setPageLoading, location } = useContext(Context);
  const drawer = useDrawerNav();

  const { data, isLoading } = useAccountsQuery();

  const qs = Helpers.qsFromLocation(location);

  let filteredRecords = data?.records || [];
  if (qs.firstName) {
    filteredRecords =
      data?.records.filter((account) => account.first_name === qs.firstName) ||
      [];
  }

  // For permission auditing
  if (qs.permission) {
    const filterOnPermission: keyof PermissionProps = qs.permission;

    if (!Array.isArray(filterOnPermission)) {
      filteredRecords = filteredRecords.filter((r) => {
        return r.permissions && r.permissions[filterOnPermission];
      });
    }
  }
  useEffect(() => {
    setPageLoading(isLoading);
  }, [data, setPageLoading, isLoading]);

  return (
    <Layout>
      <VStack spacing={8} style={styles.topMargin}>
        {/* we don't show the role creator tool in production */}
        {window.location.hostname === hostname.prod ? null : (
          <HStack justify="end">
            <Button onPress={() => drawer.push("createRole", {})}>
              New Role
            </Button>
          </HStack>
        )}
        <View style={styles.wrapper}>
          {filteredRecords?.map((r) => <Account account={r} key={r.id} />)}
        </View>
      </VStack>
    </Layout>
  );
}

const styles = StyleSheet.create({
  topMargin: { padding: 8 },
  wrapper: { padding: 4, paddingBottom: 128 },
});
