import { GearIcon } from "@radix-ui/react-icons";
import { startCase } from "lodash-es";

import { useTheme as useSKTheme } from "@smartrent/ui";
import { useModalState } from "@smartrent/hooks";

import { Button } from "@/_v2/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuPortal,
  DropdownMenuSeparator,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
} from "@/_v2/components/ui/dropdown-menu";

import { hostname } from "@/utils";
import Helpers from "@/lib/helpers";
import { useGlobalContext } from "@/layout/Context";
import { ScrollArea } from "@/_v2/components/ui/scroll-area";
import { useTheme } from "@/_v2/components/theme-provider";
import { FeedbackModal } from "@/common/modals/FeedbackModal";

import RoleSwitcher from "./role-switcher";

export const HeaderSettingsDropdown = () => {
  const { setTheme, theme } = useTheme();
  const { setMode } = useSKTheme();

  const {
    visible: feedbackModalVisible,
    onOpen: feedbackModalOnOpen,
    onClose: feedbackModalOnClose,
  } = useModalState();

  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="ghost" size="icon">
            <GearIcon className="size-4" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="w-56" align="end">
          <DropdownMenuGroup>
            <DropdownMenuSub>
              <DropdownMenuSubTrigger>
                Theme - {startCase(theme)}
              </DropdownMenuSubTrigger>
              <DropdownMenuPortal>
                <DropdownMenuSubContent>
                  <DropdownMenuItem
                    onClick={() => {
                      setTheme("light");
                      setMode("light");
                    }}
                  >
                    Light
                  </DropdownMenuItem>
                  <DropdownMenuItem
                    onClick={() => {
                      setTheme("dark");
                      setMode("dark");
                    }}
                  >
                    Dark (beta)
                  </DropdownMenuItem>
                  {/* <DropdownMenuItem
                    onClick={() => {
                      setTheme("system");
                      setMode("light");
                    }}
                  >
                    System
                  </DropdownMenuItem> */}
                </DropdownMenuSubContent>
              </DropdownMenuPortal>
            </DropdownMenuSub>
          </DropdownMenuGroup>

          <SelectRoleMenu />

          <DropdownMenuSeparator />
          <DropdownMenuItem onClick={feedbackModalOnOpen}>
            Leave Feedback
          </DropdownMenuItem>
          <DropdownMenuSeparator />
          <DropdownMenuItem onClick={() => Helpers.auth.logout()}>
            Log out
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>

      <FeedbackModal
        visible={feedbackModalVisible}
        onClose={feedbackModalOnClose}
      />
    </>
  );
};

const SelectRoleMenu = () => {
  const { user } = useGlobalContext();

  // This widget can only be used by Super Admins
  if (window.location.hostname === hostname.prod) {
    return null;
  }

  return (
    <>
      <DropdownMenuSeparator />
      <DropdownMenuGroup>
        <DropdownMenuLabel>Role</DropdownMenuLabel>
        <DropdownMenuItem disabled>{user.permissions.name}</DropdownMenuItem>
        <DropdownMenuSub>
          <DropdownMenuSubTrigger>Your Permissions</DropdownMenuSubTrigger>
          <DropdownMenuPortal>
            <DropdownMenuSubContent className="">
              <ScrollArea className="p-2 pr-3">
                <p className="text-wrap max-w-72 max-h-72">
                  {Object.keys(user.permissions)
                    .filter((p) => !["id", "name"].includes(p))
                    .map((permission) => (
                      <DropdownMenuItem key={permission} disabled>
                        {startCase(permission)}
                      </DropdownMenuItem>
                    ))}
                </p>
              </ScrollArea>
            </DropdownMenuSubContent>
          </DropdownMenuPortal>
        </DropdownMenuSub>
        <DropdownMenuSub>
          <DropdownMenuSubTrigger>Debug Role Switcher</DropdownMenuSubTrigger>
          <DropdownMenuPortal>
            <DropdownMenuSubContent className="">
              <RoleSwitcher />
            </DropdownMenuSubContent>
          </DropdownMenuPortal>
        </DropdownMenuSub>
      </DropdownMenuGroup>
    </>
  );
};
