import { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";

/**
 * This hook is used to track page views in Google Analytics.
 * It should be used in the within a Route component to track the raw path name stripped of any params
 */
export const usePageTracking = () => {
  const location = useLocation();
  const params = useParams<Record<string, string>>();

  const raw_path = Object.entries(params).reduce(
    (path, [key, value]) => path.replace("/" + value, "/:" + key),
    `${location.pathname}`
  );

  useEffect(() => {
    window.gtag("event", "page_view", {
      page_path: raw_path,
      page_search: location.search,
      page_hash: location.hash,
      page_uri: location.pathname + location.search + location.hash,
    });
  }, [location, raw_path]);
};
