import { useCurrentConversation } from "@/_v2/api/intercom/conversation";

import { Skeleton } from "@/_v2/components/ui/skeleton";

import { useDocumentTitle } from "@/hooks/use-document-title";

import { ConversationUnitsTable } from "./components/conversation-units-table";

export const ConversationSelectUnitPage = () => {
  useDocumentTitle("Conversation - Select Unit", " | SupportRent V2");
  const { data } = useCurrentConversation();

  return (
    <div>
      <div className="flex justify-between">
        <div className="pb-4">
          <h1 className="text-xl font-extrabold tracking-tight lg:text-2xl">
            Missing Conversation Details - Unit
          </h1>
          <p className="">
            Choose the unit that this conversation is associated with and hit
            confirm. If the conversation is not associated with a unit, please
            hit skip.
          </p>
        </div>

        <div>
          {!data ? (
            <Skeleton className="w-24 h-4" />
          ) : (
            <p className="text-sm font-semibold">Conversation ID: {data.id}</p>
          )}
        </div>
      </div>

      <ConversationUnitsTable />
    </div>
  );
};
