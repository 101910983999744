import { useEffect, useState, useCallback, useMemo } from "react";
import * as React from "react";
import {
  Button,
  FormikTextareaField,
  Form,
  Text,
  LegacyCardFlatList,
  Typography,
  FormikSubmit,
} from "@smartrent/ui";
import { compact, uniq } from "lodash-es";
import { Refresh } from "@smartrent/icons";
import { Link } from "react-router-dom";
import { View, StyleSheet } from "react-native";
import { Formik } from "formik";
import * as Yup from "yup";

import Box from "@/common/Box";
import AdvancedHubPrepModal from "@/common/modals/advanced-hubprep-modal/AdvancedHubPrepModal";
import helpers from "@/lib/helpers";
import { useApi, useInterval } from "@/lib/hooks";
import Layout from "@/layout/Layout";
import { useGlobalContext } from "@/layout/Context";
import { useHubPrep } from "@/pages/hub-prep/HubPrepContext";
import { HubPrepSidebarActions } from "@/pages/hub-prep/HubPrepSidebarActions";
import { HubPrepActionsInfo } from "@/pages/hub-prep/HubPrepActionsInfo";
import { HubPrepVirtualGrid } from "@/pages/hub-prep/HubPrepVirtualGrid";
import { useDocumentTitle } from "@/hooks/use-document-title";
import {
  HubPrepAction,
  HubPrepLog as HubPrepLogProp,
  ToastType,
} from "@/types";

import HubPrepLog from "./HubPrepLog";

export default function HubPrepBatch() {
  useDocumentTitle("Hub Prep Batch");
  const { hubPrepLogs, setHubPrepLogs } = useHubPrep();
  const [action, setAction] = useState<HubPrepAction>("procure");
  const [allLogsVisible, setAllLogsVisible] = useState(false);
  const [showAdvancedHubPrep, setShowAdvancedHubPrep] = useState(false);
  const { history, location, pageLoading, setPageLoading, setToast } =
    useGlobalContext();

  const filters = helpers.qsFromLocation(location);

  const serials =
    filters.serials && typeof filters.serials === "string"
      ? filters.serials.split(",")
      : [];

  const activeSerials = serials.filter((serial) => {
    return /disabled_/gi.test(serial) === false;
  });

  useEffect(() => {
    if (pageLoading) {
      setPageLoading(false);
      setHubPrepLogs([]);
    }
  }, [setPageLoading, setHubPrepLogs, pageLoading]);

  const { response, reFetch } = useApi({
    url: `/hub-prep-logs?${helpers.toQS({
      serials: activeSerials,
      limit: filters.limit || 100,
      sort: filters.sort || "updated_at",
      dir: filters.dir || "DESC",
      type: filters.type,
      actionRequired: filters.actionRequired,
    })}`,
    trigger: [!!serials.length],
  });

  useEffect(() => {
    if (response?.data?.records) {
      const hubPrepLogsWithStatus = response?.data?.records?.length
        ? response?.data?.records.map((hubPrepLog: HubPrepLogProp) => {
            return { ...hubPrepLog, status: helpers.hubPrepStatus(hubPrepLog) };
          })
        : [];
      setHubPrepLogs(hubPrepLogsWithStatus);
    }
  }, [setHubPrepLogs, response]);

  const { preppedHubs, preppingHubs, failedHubs } = useMemo(() => {
    let preppedHubs: HubPrepLogProp[] = [];
    let preppingHubs: HubPrepLogProp[] = [];
    let failedHubs: HubPrepLogProp[] = [];

    if (hubPrepLogs?.length) {
      preppedHubs = hubPrepLogs.filter(({ status }) => status === "Complete");
      preppingHubs = hubPrepLogs.filter(
        ({ status }) => status === "In Progress"
      );
      failedHubs = hubPrepLogs.filter(({ status }) => status === "Failed");
    }

    return { preppedHubs, preppingHubs, failedHubs };
  }, [hubPrepLogs]);

  const handleAdvancedPress = () => {
    setAction("advanced");
    setShowAdvancedHubPrep(!showAdvancedHubPrep);
  };

  useInterval(
    () => {
      if (reFetch) {
        reFetch();
      }
    },
    preppingHubs.length ? 20000 : 40000
  );

  const memoizedRenderItem = useCallback(
    ({ item }: { item: HubPrepLogProp }) => <HubPrepLog hubPrepLog={item} />,
    []
  );

  const toggleLogsVisible = useCallback(
    () => setAllLogsVisible(!allLogsVisible),
    [allLogsVisible]
  );

  const initialValues = {
    serials: serials.join("\n"),
  };

  return (
    <>
      <Layout>
        <View style={styles.batchContainer}>
          <Link className="a u-mbottom12" to="/hub-prep">
            {"<"} Back
          </Link>

          <div className="u-flex u-flex-wrap u-flex-justify-between u-mtop24">
            <div className="u-mright16">
              <Box style={styles.batchForm}>
                <Formik
                  initialErrors={{
                    serials: "",
                  }}
                  initialValues={initialValues}
                  validationSchema={Yup.object({
                    serials: Yup.string().required(
                      "Please enter a batch of hub serial numbers"
                    ),
                  })}
                  onSubmit={async (values, actions) => {
                    try {
                      actions.setSubmitting(true);

                      // Using _.compact to fix an issue where a newline is scanned and we get an empty string
                      // which shows up in the UI as a hub being processed even though no backend processing is
                      // occurring.
                      // Using _.uniq to prevent processing and UI rendering of the same input twice in a row if it was
                      // accidentally scanned twice in a row.
                      const serials: string[] = compact(
                        uniq(
                          values.serials.split("\n").map((serial: string) => {
                            return serial ? serial.toUpperCase() : null;
                          })
                        )
                      );

                      helpers.updateQS({
                        history,
                        update: { serials: serials.join(",") },
                        location,
                      });

                      await helpers.hubPrep
                        .batch({
                          serials: serials.filter(
                            (serial) => /disabled_/gi.test(serial) === false
                          ),
                          action,
                          advancedOptions: {
                            ...values,
                          },
                        })
                        .then(({ success, errors, error }) => {
                          if (errors) {
                            console.log(errors);
                          }
                          setHubPrepLogs(success);
                          const toastType: ToastType =
                            !!error || (errors && errors?.length)
                              ? "error"
                              : "success";
                          let toastMessage: React.ReactNode;

                          if (!!error) {
                            toastMessage = (
                              <div>
                                <div className="u-mtop8 u-sans-bold">
                                  {error}
                                </div>
                              </div>
                            );
                          } else {
                            toastMessage = (
                              <div>
                                Prepping {success.length.toLocaleString()} hub
                                {success.length !== 1 ? "s" : ""}{" "}
                                {errors && errors.length ? (
                                  <div className="u-mtop8 u-sans-bold">
                                    Unable to prep {errors.length} hubs:
                                    {errors.map(({ error, serial }) => {
                                      return (
                                        <div
                                          className="u-mtop4 u-font10 u-text-symbolic-error u-line14"
                                          key={serial}
                                        >
                                          {serial}: {error}
                                        </div>
                                      );
                                    })}
                                  </div>
                                ) : null}
                              </div>
                            );
                          }

                          setToast({
                            type: toastType,
                            title: "Hub Prep",
                            message: toastMessage,
                          });
                        });
                    } catch (error) {
                      console.error(error);
                      setToast({
                        type: "error",
                        title: "Hub Prep Error",
                        message:
                          "An unknown error occurred, please try again later",
                      });
                      actions.setSubmitting(false);
                    }
                  }}
                >
                  {({ values }) => (
                    <Form>
                      <FormikTextareaField
                        name="serials"
                        label="Serial Numbers"
                        required
                      />

                      <View style={styles.separator} />

                      <HubPrepSidebarActions
                        action={action}
                        setAction={setAction}
                        handleAdvancedPress={handleAdvancedPress}
                      />
                      <HubPrepActionsInfo action={action} />
                      <AdvancedHubPrepModal
                        visible={showAdvancedHubPrep}
                        onHide={() => setShowAdvancedHubPrep(false)}
                        values={values}
                      />
                      <FormikSubmit
                        label="Start Batch"
                        submittingLabel="Starting"
                      />
                    </Form>
                  )}
                </Formik>
              </Box>
            </div>

            <div className="u-flex-grow2 u-flex-shrink1">
              <HubPrepVirtualGrid serials={serials} hubPrepLogs={hubPrepLogs} />
              <Button onPress={toggleLogsVisible} variation="plain">
                <Typography>
                  {allLogsVisible ? "Hide" : "Show"} {hubPrepLogs?.length || 0}{" "}
                  Rows
                </Typography>
              </Button>

              {hubPrepLogs && allLogsVisible && (
                <View>
                  <div className="u-flex u-flex-wrap u-flex-align-items-center u-flex-justify-between">
                    <Text type="title2">
                      In Progress ({preppingHubs.length.toLocaleString()})
                    </Text>
                    <div onClick={reFetch} className="cursor--pointer">
                      <Refresh size={14} /> Last Update{" "}
                      {helpers.formatDate(new Date(), "hh:mm:ss")}
                    </div>
                  </div>
                  <View style={styles.hubPrepContainer}>
                    {preppingHubs.map((hubPrepLog) =>
                      hubPrepLog ? (
                        <HubPrepLog
                          key={hubPrepLog.serial}
                          hubPrepLog={hubPrepLog}
                        />
                      ) : null
                    )}
                    {!preppingHubs.length && <Text type="bodyLarge">None</Text>}
                  </View>

                  <View style={styles.separator} />
                  <Text type="title2">
                    Failed ({failedHubs.length.toLocaleString()})
                  </Text>
                  <View style={styles.hubPrepContainer}>
                    {failedHubs.map((hubPrepLog) =>
                      hubPrepLog ? (
                        <HubPrepLog
                          key={hubPrepLog.serial}
                          hubPrepLog={hubPrepLog}
                        />
                      ) : null
                    )}
                    {!failedHubs.length && <Text type="bodyLarge">None</Text>}
                  </View>

                  <View style={styles.separator} />
                  <Text type="title2">
                    Complete ({preppedHubs.length.toLocaleString()})
                  </Text>
                  <View style={styles.hubPrepContainer}>
                    <LegacyCardFlatList
                      title={null}
                      subtitle={null}
                      data={preppedHubs}
                      keyExtractor={(item) => item.serial}
                      renderItem={memoizedRenderItem}
                    />

                    {!preppedHubs.length && <Text type="bodyLarge">None</Text>}
                  </View>
                </View>
              )}

              {!hubPrepLogs && (
                <View>
                  <Text>
                    No work started. Please specify hub serial numbers to
                    continue.
                  </Text>
                </View>
              )}
            </div>
          </div>
        </View>
      </Layout>
    </>
  );
}

const styles = StyleSheet.create({
  batchForm: {
    padding: 16,
  },
  separator: {
    marginTop: 32,
  },
  hubPrepContainer: {
    padding: 8,
  },
  batchContainer: {
    marginVertical: 16,
    marginHorizontal: 24,
  },
});
