import { TouchableOpacity, View, useWindowDimensions } from "react-native";
import { Text, useTheme } from "@smartrent/ui";

type Screens = {
  key: string;
  label: string;
};

interface Props {
  onChange: any;
  currentScreen: string;
  screens: Screens[];
  style?: Object;
}

const RadioFilters = ({ onChange, currentScreen, screens, style }: Props) => {
  const { colors, fonts } = useTheme();
  const { width } = useWindowDimensions();

  return (
    <View
      style={[
        styles.root,
        {
          flexDirection: width > 700 ? "row" : "column",
          backgroundColor: colors.pageBackground,
          borderColor: colors.gray300,
        },
        style,
      ]}
    >
      {screens.map((screen) => (
        <TouchableOpacity
          key={`screen_filter_${screen.key}`}
          onPress={() => onChange(screen.key)}
          style={[
            styles.label,
            screen.key === currentScreen && {
              backgroundColor: colors.primary,
            },
          ]}
        >
          <Text
            style={[
              {
                color: colors.primary,
                ...fonts.semibold,
              },
              screen.key === currentScreen && {
                color: colors.white,
              },
            ]}
          >
            {screen.label}
          </Text>
        </TouchableOpacity>
      ))}
    </View>
  );
};

const styles = {
  root: {
    justifyContent: "space-around",
    alignItems: "center",
    paddingTop: 16,
    paddingBottom: 16,
  },
  label: {
    borderRadius: 14,
    paddingVertical: 2,
    paddingHorizontal: 20,
  },
};

export default RadioFilters;
