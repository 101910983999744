import { useEffect, useState } from "react";
import { Row } from "@tanstack/react-table";
import { useHistory } from "react-router-dom";

import { DataTable } from "@/_v2/components/composites/data-table/data-table";
import { useDataTablePagination } from "@/_v2/components/composites/data-table/data-table-pagination";
import { useDataTableSorting } from "@/_v2/components/composites/data-table/data-table-sorting";
import { useDataTableFilters } from "@/_v2/components/composites/data-table/data-table-filters";
import { useDataTableSelection } from "@/_v2/components/composites/data-table/data-table-selection";
import { DataTableFiltersQuickSearch } from "@/_v2/components/composites/data-table/data-table-filters/data-table-filters-quick-search";
import { Button } from "@/_v2/components/ui/button";

import { useDebouncedValue } from "@/_v2/utils/hooks/use-debounced-value";

import { UnitResponse, useUnitsQuery } from "@/_v2/api/units";
import {
  useConversationUpdateMutation,
  useCurrentConversation,
} from "@/_v2/api/intercom/conversation";
import { useGroupsQuery } from "@/_v2/api/groups";

import { columns } from "./conversation-units-table-columns";

export const ConversationUnitsTable = () => {
  const { data: conversation } = useCurrentConversation();

  const pagination = useDataTablePagination();
  const sorting = useDataTableSorting([]);
  const selection = useDataTableSelection({});
  const [filters, setFilters] = useDataTableFilters([]);

  useEffect(() => {
    if (conversation) {
      const groupId = conversation?.custom_attributes?.property_ids;

      setFilters([{ id: "group_uuid", value: groupId }]);
    }
  }, [conversation, setFilters]);

  const queryFilters = useDebouncedValue(filters);

  const { data, isFetching } = useUnitsQuery(
    {
      page: pagination[0]?.pageIndex,
      limit: pagination[0]?.pageSize,
      sortBy: sorting[0][0]?.id,
      sortDir: sorting[0][0]?.desc ? "desc" : "asc",
      filters: queryFilters,
    },
    {
      enabled: queryFilters.length >= 1,
    }
  );

  return (
    <DataTable
      columns={columns}
      data={data?.records || []}
      rowCount={data?.total_records || 0}
      getRowId={(row) => row.id}
      loading={isFetching}
      pagination={pagination}
      sorting={sorting}
      selection={selection}
      filters={[filters, setFilters]}
      enableMultiRowSelection={false}
      onRowClick={(row) => row.toggleSelected(!row.getIsSelected())}
    >
      {(table) => (
        <div className="flex justify-between space-x-2">
          <DataTableFiltersQuickSearch
            table={table}
            columnId="marketing_name"
            placeholder="Search by name..."
          />

          <div className="flex-1" />

          <SkipButton />
          <ConfirmButton row={table.getSelectedRowModel().rows[0]} />
        </div>
      )}
    </DataTable>
  );
};

const ConfirmButton = ({ row }: { row?: Row<UnitResponse> }) => {
  const history = useHistory();
  const { data: conversation } = useCurrentConversation();
  const [updateConveration] = useConversationUpdateMutation();
  const [loading, setLoading] = useState(false);

  return (
    <Button
      onClick={async () => {
        if (!conversation?.id || !row) {
          return;
        }

        setLoading(true);
        try {
          const redirectUrl = `/units/${row.original.id}?conversation_id=${conversation.id}`;
          await updateConveration({
            conversationId: conversation.id,
            unit_id: row.original.id,
            unit_name: row.original.marketing_name,
            supportrent_url: window.location.origin + redirectUrl,
          });

          history.replace(redirectUrl);
          setLoading(false);
        } catch (e) {
          console.error(e);
          setLoading(false);
        }
      }}
      disabled={!conversation?.id || !row?.original.id}
      loading={loading}
    >
      Confirm
    </Button>
  );
};

const SkipButton = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [updateConveration] = useConversationUpdateMutation();
  const { data: conversation } = useCurrentConversation();
  const { data: groups } = useGroupsQuery(
    {
      filters: [
        {
          id: "group_uuid",
          value: conversation?.custom_attributes?.property_ids,
        },
      ],
    },
    { enabled: !!conversation?.custom_attributes?.property_ids }
  );

  return (
    <Button
      variant="outline"
      loading={loading}
      disabled={!conversation?.id || !groups?.records[0]?.id}
      onClick={async () => {
        if (!conversation?.id || !groups?.records[0]?.id) {
          return;
        }

        setLoading(true);
        try {
          const redirectUrl = `/groups/${groups?.records[0]?.id}?conversation_id=${conversation?.id}`;
          await updateConveration({
            conversationId: conversation.id,
            supportrent_url: window.location.origin + redirectUrl,
          });

          history.replace(redirectUrl);
          setLoading(false);
        } catch (e) {
          console.error(e);
          setLoading(false);
        }
      }}
    >
      Skip
    </Button>
  );
};
