import * as React from "react";
import { format } from "date-fns";
import { Calendar as CalendarIcon, X as CloseIcon } from "lucide-react";
import { DateRange } from "react-day-picker";

import { cn } from "@/_v2/utils";
import { Button } from "@/_v2/components/ui/button";
import { Calendar } from "@/_v2/components/ui/calendar";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/_v2/components/ui/popover";

interface DatePickerProps {
  className?: string;
  startDate: Date;
  endDate: Date;
  onDatesChange: (dates: DateRange) => void;
  defaultStartDate?: Date;
  defaultEndDate?: Date;
}

export function DatePickerWithRange({
  className,
  startDate,
  endDate,
  onDatesChange,
  defaultStartDate,
  defaultEndDate,
}: DatePickerProps) {
  const [date, setDate] = React.useState<DateRange | undefined>({
    from: startDate,
    to: endDate,
  });

  const handleSelect = (dates: DateRange) => {
    if (dates) {
      onDatesChange(dates);
    }

    setDate(dates);
  };

  const resetDates = () => {
    const defaultDate = { from: defaultStartDate, to: defaultEndDate };
    onDatesChange(defaultDate);
    setDate(defaultDate);
  };

  return (
    <div className={cn("gap-2", className)}>
      <Popover>
        <div>
          <PopoverTrigger asChild>
            <Button
              id="date"
              variant="outline"
              className={cn(
                "w-[300px] justify-start text-left font-normal",
                !date && "text-muted-foreground"
              )}
            >
              <CalendarIcon />
              {date?.from ? (
                date.to ? (
                  <>
                    {format(date.from, "LLL dd, y")} -{" "}
                    {format(date.to, "LLL dd, y")}
                  </>
                ) : (
                  format(date.from, "LLL dd, y")
                )
              ) : (
                <span>Pick a date</span>
              )}
            </Button>
          </PopoverTrigger>
          <Button
            variant="ghost"
            className="-ml-9 rounded-full w-6 h-6 p-0"
            onClick={resetDates}
          >
            <CloseIcon />
          </Button>
        </div>
        <PopoverContent className="w-auto p-0" align="start">
          <Calendar
            initialFocus
            fixedWeeks={true}
            mode="range"
            captionLayout="dropdown-buttons"
            fromYear={new Date().getFullYear() - 5}
            toYear={new Date().getFullYear() + 1}
            selected={date}
            onSelect={handleSelect}
            numberOfMonths={2}
            className="rounded-md border"
            defaultMonth={date?.from}
          />
        </PopoverContent>
      </Popover>
    </div>
  );
}
