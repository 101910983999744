import { SupportRentLogo } from "@/common/supportrent-logo";

import { HeaderMenuBar } from "./header-menu-bar";
import { Search } from "./layout-header-search/layout-header-search";
import { LayoutSidenav } from "./layout-sidenav/layout-sidenav";
import { HeaderSettingsDropdown } from "./header-settings-dropdown";
import { HeaderManualVerifications } from "./header-manual-verifications";

export const Header = () => {
  return (
    <div className="h-10 border-solid border-border border-b flex space-x-3 pl-3 pr-2 items-center w-full">
      <LayoutSidenav />
      <SupportRentLogo size={20} />
      <Search />
      <HeaderMenuBar />

      <div className="flex-1" />

      <div className="flex space-x-1">
        <HeaderManualVerifications />
        <HeaderSettingsDropdown />
      </div>
    </div>
  );
};
