import { useEffect } from "react";

export function LoginRedirect() {
  useEffect(() => {
    const url = localStorage.getItem("redirectUrl");
    localStorage.removeItem("redirectUrl");
    if (!url) {
      window.location.replace("/");
      return;
    }

    try {
      const validatedUrl = new URL(url, window.location.origin);
      if (validatedUrl.origin === window.location.origin) {
        window.location.replace(validatedUrl.href);
      } else {
        window.location.replace("/");
      }
    } catch (e) {
      window.location.replace("/");
    }
  }, []);

  return null;
}
