import React from "react";
import { useHistory } from "react-router-dom";

import {
  ColumnDef,
  ColumnFiltersState,
  OnChangeFn,
  Row,
  RowSelectionState,
  SortingState,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";

import {
  Table,
  TableBody,
  TableCell,
  TableEmptyResults,
  TableHead,
  TableHeader,
  TableLoading,
  TableRow,
} from "@/_v2/components/ui/table";
import { cn } from "@/_v2/utils";

import { DataTablePagination, PaginationState } from "./data-table-pagination";
import { DataTableColumnHeader } from "./data-table-column-header";
import { Table as TTable } from "./data-table-filters/data-table-filters-types";

interface DataTableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[];
  data: TData[];
  pagination?: [PaginationState, OnChangeFn<PaginationState>];
  sorting?: [SortingState, OnChangeFn<SortingState>];
  selection?: [RowSelectionState, OnChangeFn<RowSelectionState>];
  filters?: [ColumnFiltersState, OnChangeFn<ColumnFiltersState>];
  /** All children will be treated as table filters and receive `table` as a prop */
  children?: (table: TTable<TData>) => React.ReactNode;
  className?: string;
  loading?: boolean;
  rowCount?: number;
  getRowHref?: (row: Row<TData>) => string;
  onRowClick?: (row: Row<TData>) => void;
  getRowId?: (row: TData) => string;
  enableMultiRowSelection?: boolean;
}

export function DataTable<TData, TValue>({
  columns,
  data,
  pagination,
  sorting,
  selection,
  filters,
  children,
  className,
  loading,
  rowCount,
  getRowHref,
  onRowClick,
  getRowId,
  enableMultiRowSelection = false,
}: DataTableProps<TData, TValue>) {
  const history = useHistory();

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    manualSorting: true,
    rowCount,
    getRowId,
    enableMultiRowSelection,
    onPaginationChange: pagination?.[1],
    onSortingChange: sorting?.[1],
    onRowSelectionChange: selection?.[1],
    onColumnFiltersChange: filters?.[1],
    state: {
      pagination: pagination?.[0],
      sorting: sorting?.[0],
      rowSelection: selection?.[0] || {},
      columnFilters: filters?.[0],
    },
  });

  const rowLength = table.getRowModel().rows?.length;

  return (
    <div className="flex flex-col space-y-2">
      {children && children(table)}
      <div className={cn("rounded-md border", className)}>
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead key={header.id}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            typeof header.column.columnDef.header ===
                              "function" ? (
                              header.column.columnDef.header
                            ) : (
                              <DataTableColumnHeader
                                title={header.column.columnDef.header}
                                {...header}
                              />
                            ),
                            // header.column.columnDef.header,
                            header.getContext()
                          )}
                    </TableHead>
                  );
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {loading && <TableLoading rows={pagination?.[0]?.pageSize} />}
            {!loading && !rowLength && <TableEmptyResults />}
            {!loading && rowLength
              ? table.getRowModel().rows.map((row) => (
                  <TableRow
                    key={row.id}
                    data-state={row.getIsSelected() && "selected"}
                    onClick={
                      onRowClick
                        ? () => onRowClick(row)
                        : getRowHref
                          ? () => {
                              history.push(getRowHref(row));
                            }
                          : undefined
                    }
                  >
                    {row.getVisibleCells().map((cell) => (
                      <TableCell key={cell.id}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                ))
              : null}
          </TableBody>
        </Table>
      </div>
      {pagination && <DataTablePagination table={table} />}
    </div>
  );
}
