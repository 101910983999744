import { useCallback, useRef, useState } from "react";
import * as React from "react";
import { CSVLink } from "react-csv";
import { Button } from "@smartrent/ui";
import { Download } from "@smartrent/icons";

import {
  HubStatusReportRow,
  useDownloadHubStatusReportMutation,
} from "@/api/reports";
import { useGroup } from "@/pages/group/GroupContext";

const csvHeaders = [
  { label: "Property Name", key: "Property Name" },
  { label: "Property ID", key: "Property ID" },
  { label: "Organization Name", key: "Organization Name" },
  { label: "Unit Name", key: "Unit Name" },
  { label: "Unit ID", key: "Unit ID" },
  { label: "Unit Status", key: "Unit Status" },
  { label: "Unit Occupied", key: "Unit Occupied" },
  { label: "Unit Floor", key: "Unit Floor" },
  { label: "Unit Building", key: "Unit Building" },
  { label: "Unit Code", key: "Unit Code" },
  { label: "Serial #", key: "Serial #" },
  { label: "Firmware Version", key: "Firmware Version" },
  { label: "ZWave SDK Version", key: "ZWave SDK Version" },
  { label: "Online", key: "Online" },
  { label: "Last Offline At", key: "Last Offline At" },
  { label: "Uptime Past 24 Hours", key: "Uptime Past 24 Hours" },
  { label: "Type", key: "Type" },
  { label: "Regions", key: "Regions" },
  { label: "Install Date", key: "Install Date" },
];

export const GroupReportsHubStatusReportButton: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  const { group } = useGroup();
  const [reportLoading, setReportLoading] = useState(false);
  const [reportData, setReportData] = useState<HubStatusReportRow[]>([]);
  const csvLinkEl = useRef<any>(null);

  const [downloadReportMutation] = useDownloadHubStatusReportMutation();

  const downloadDeviceReport = useCallback(async () => {
    setReportLoading(true);
    const data = await downloadReportMutation({
      groupId: group.id,
    });
    if (!data) {
      setReportLoading(false);
      return;
    }
    setReportData(data);
    setReportLoading(false);

    // This is a hack to get the CSVLink to download the file.
    // The CSVLink component doesn't have a way to trigger the download asynchronously.
    // https://github.com/react-csv/react-csv/issues/341
    setTimeout(() => {
      csvLinkEl.current.link.click();
    }, 0);
  }, [downloadReportMutation, group.id]);

  return (
    <>
      <CSVLink
        data={reportData}
        asyncOnClick={true}
        headers={csvHeaders}
        filename={`hub-status-report-${
          group.marketing_name
        }-${new Date().toISOString()}.csv`}
        ref={csvLinkEl}
      ></CSVLink>
      <Button
        iconLeft={Download}
        isLoading={reportLoading}
        onPress={downloadDeviceReport}
      >
        Hub Status Report
      </Button>
    </>
  );
};
