import { useMemo } from "react";
import { CheckIcon } from "@radix-ui/react-icons";

import { useUser } from "@/layout/Context";
import { useUpsertRoleMutation } from "@/api";
import { useRolesQuery } from "@/api/roles";
import { Role } from "@/types";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandLoading,
  CommandSeparator,
} from "@/_v2/components/ui/command";

const RoleSwitcher = () => {
  const { role_id } = useUser();
  const [upsertRole] = useUpsertRoleMutation();

  const { data: roles, isLoading } = useRolesQuery();

  const handleRoleChange = (name: string) => {
    upsertRole({ name: name as Role });
    window.location.replace("/");
  };

  const selectedRole = useMemo(
    () => roles?.find((role) => role.id === role_id),
    [roles, role_id]
  );

  const remainingRoles = useMemo(
    () =>
      (roles || [])
        .filter((role) => role.id !== role_id)
        .sort((a, b) => a.name.localeCompare(b.name)),
    [roles, role_id]
  );

  return (
    <Command>
      <CommandInput
        placeholder="Search roles..."
        autoFocus={true}
        className="h-9"
      />
      <CommandList>
        <CommandEmpty>No role found.</CommandEmpty>
        {isLoading && <CommandLoading>Hang on…</CommandLoading>}
        <CommandGroup>
          {selectedRole ? (
            <>
              <CommandItem key={selectedRole.name} value={selectedRole.name}>
                {selectedRole.name}
                <CheckIcon />
              </CommandItem>
              <CommandSeparator />
            </>
          ) : null}
          {remainingRoles.map((role) => (
            <CommandItem
              key={role.name}
              value={role.name}
              onSelect={(value) => {
                handleRoleChange(value);
              }}
            >
              {role.name}
            </CommandItem>
          ))}
        </CommandGroup>
      </CommandList>
    </Command>
  );
};

export default RoleSwitcher;
