import { useState, useContext } from "react";
import * as React from "react";
import { FlatList, StyleSheet, TouchableOpacity } from "react-native";
import { Text, Typography, useTheme } from "@smartrent/ui";

import Context from "@/layout/Context";

import { CardActionItemProps } from "../types";

import FormikModal from "./formik/Modal";
import Box from "./Box";

type Props = {
  actions: CardActionItemProps[];
  onClick: () => void;
};

export default function CardActionSheet({ actions, onClick }: Props) {
  const { colors } = useTheme();
  const { isDesktop } = useContext(Context);
  const [modalItem, setModalItem] = useState(null);

  const renderLabel = ({ item }: { item: CardActionItemProps }) => {
    return (
      <TouchableOpacity
        onPress={() => {
          if (item.modal) {
            setModalItem(item);
          } else {
            item.onSubmit(item);
          }
        }}
        style={styles.actionSheetItem}
      >
        <Text style={styles.actionSheetItemText}>{item.label}</Text>
      </TouchableOpacity>
    );
  };

  return (
    <div
      style={isDesktop ? actionSheetStyleDesktop : actionSheetStyleMobile}
      className="border"
    >
      <div
        style={
          isDesktop
            ? { display: "none" }
            : {
                backgroundColor: "rgba(0,0,0,0.5)",
                left: 0,
                top: 0,
                position: "fixed",
                height: "100vh",
                width: "100vw",
              }
        }
      />
      <Box style={{ padding: 0, width: "100%" }}>
        <Typography
          font="regular"
          style={{
            paddingVertical: 8,
            paddingHorizontal: 16,
            fontSize: 14,
            textAlign: "left",
            backgroundColor: colors.pageBackground,
          }}
        >
          Actions
        </Typography>
        <FlatList
          data={actions}
          renderItem={renderLabel}
          keyExtractor={(item: any) => item.label}
          style={{ width: "100%" }}
        />
        <TouchableOpacity
          onPress={() => onClick()}
          style={
            isDesktop
              ? { display: "none" }
              : [styles.actionSheetItem, { backgroundColor: "#00AC9F" }]
          }
        >
          <Text
            style={[
              styles.actionSheetItemText,
              { color: "white", paddingBottom: 24 },
            ]}
          >
            Cancel
          </Text>
        </TouchableOpacity>
      </Box>
      {modalItem && (
        <FormikModal
          title={modalItem.modal.title}
          toggleModal={() => setModalItem(null)}
          initialValues={modalItem.modal.formik.initialValues}
          onSubmit={(formValues) => {
            setModalItem(null);
            return modalItem.modal.formik.onSubmit(formValues);
          }}
          validationSchema={modalItem.modal.formik.validationSchema}
          confirmText={modalItem.modal.confirmText}
        >
          {modalItem.modal.body}
        </FormikModal>
      )}
    </div>
  );
}

const actionSheetStyleDesktop: React.CSSProperties = {
  position: "absolute",
  alignItems: "center",
  backgroundColor: "white",
  padding: 0,
  zIndex: 12,
  right: -8,
  top: 48,
  width: 350,
};

const actionSheetStyleMobile: React.CSSProperties = {
  alignItems: "center",
  backgroundColor: "white",
  padding: 0,
  zIndex: 10,
  bottom: 0,
  position: "fixed",
  right: 0,
  width: "100%",
};

const styles = StyleSheet.create({
  actionSheetItem: {
    borderTopWidth: 1,
    borderTopColor: "#dadada",
    cursor: "pointer",
    padding: 18,
    position: "relative",
    width: "100%",
  },
  actionSheetItemText: {
    fontSize: 18,
    fontWeight: "600",
    textAlign: "center",
  },
});
