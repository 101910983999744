import { ThemeMode, useTheme as useSKTheme } from "@smartrent/ui";

import { Theme, useTheme } from "@/_v2/components/theme-provider";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "@/_v2/components/ui/select";

export const LayoutSidenavThemeSelect = () => {
  const { setTheme, theme } = useTheme();
  const { setMode } = useSKTheme();

  const onValueChange = (value: Theme) => {
    setTheme(value);
    setMode(value as ThemeMode);
  };

  return (
    <Select value={theme} onValueChange={onValueChange}>
      <SelectTrigger className="">
        <SelectValue placeholder="Select Theme" />
      </SelectTrigger>
      <SelectContent>
        <SelectGroup>
          <SelectLabel>Theme</SelectLabel>
          <SelectItem value="light">Light</SelectItem>
          <SelectItem value="dark">Dark (beta)</SelectItem>
          {/* <SelectItem value="system">System</SelectItem> */}
        </SelectGroup>
      </SelectContent>
    </Select>
  );
};
