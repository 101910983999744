import { Box } from "@smartrent/ui";

import JobHeader from "@/common/jobs/JobHeader";
import { JobComments } from "@/common/jobs/JobComments/JobComments";
import { JobManagementNotes } from "@/common/jobs/JobManagementNotes/JobManagementNotes";
import { TasksList } from "@/common/jobs/Tasks/TasksList";
import Layout from "@/layout/Layout";
import { useDocumentTitle } from "@/hooks/use-document-title";

interface Props {
  id: string;
}

export default function Job({ id }: Props) {
  useDocumentTitle("Alloy Install Job");
  return (
    <Layout>
      <Box>
        <JobHeader id={id} />
      </Box>
      <JobComments id={id} />
      <JobManagementNotes id={id} />
      <TasksList id={id} />
    </Layout>
  );
}
