import { ReactNode } from "react";
import { View } from "react-native";

import { useTheme } from "@smartrent/ui";

type Props = {
  children: ReactNode;
  style?: Object;
};

export default function Box({ children, style }: Props) {
  const { colors, shadows } = useTheme();
  return (
    <View
      style={[
        style,
        { backgroundColor: colors.raisedSurfaces },
        shadows.regular,
      ]}
    >
      {children}
    </View>
  );
}
