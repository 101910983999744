import { FC, useMemo } from "react";
import { StyleSheet, View, Image, TouchableHighlight } from "react-native";
import { last } from "lodash-es";

import { Typography, useTheme, Button } from "@smartrent/ui";
import {
  Question,
  Auto,
  SmartHome,
  FlagSolid,
  CheckCircleSolid,
  ExclamationCircleSolid,
} from "@smartrent/icons";

import { TaskUser, JobTask } from "@/types";
import { formatDate } from "@/utils";

import { useImageViewer } from "./TaskImageViewer";
import { useTaskFlagModal } from "./TaskFlagModal";
import { useResolveTaskFlagModal } from "./ResolveTaskFlagModal";

type TaskListItemProps = {
  task: JobTask;
  numColumns: number;
};

type TaskStatusLabel =
  | "Cannot Complete"
  | "Completed"
  | "In Progress"
  | "Unknown";

type TaskStatus = {
  color: string;
  Icon: FC<React.PropsWithChildren<unknown>>;
  label: TaskStatusLabel;
};

function useStatusFromTask(task: JobTask): TaskStatus {
  const { colors } = useTheme();

  if (task.completed_at !== null && task.cannot_complete_reason !== null) {
    return {
      label: "Cannot Complete",
      color: colors.warning,
      Icon: () => (
        <ExclamationCircleSolid color={colors.warning} strokeWidth={2} />
      ),
    };
  }

  if (task.completed_at !== null) {
    return {
      label: "Completed",
      color: colors.green,
      Icon: () => <CheckCircleSolid color={colors.green} />,
    };
  }

  if (task.completed_at === null) {
    return {
      label: "In Progress",
      color: colors.green,
      Icon: () => <Auto color={colors.green} strokeWidth={2} />,
    };
  }

  return {
    label: "Unknown",
    color: colors.gray200,
    Icon: () => <Question color={colors.gray200} />,
  };
}

const NoImage = () => {
  return (
    <View style={styles.noImage}>
      <SmartHome size={64} />
    </View>
  );
};

function toFullName(user: TaskUser) {
  return `${user.first_name} ${user.last_name}`;
}

const StatusByUser = (task: JobTask) => {
  const { colors } = useTheme();

  if (
    task.completed_at !== null &&
    task.cannot_complete_reason !== null &&
    task.completed_by !== null
  ) {
    return (
      <View>
        <Typography
          style={[styles.statusByUserLabel, { color: colors.gray400 }]}
        >
          Marked By:{" "}
        </Typography>
        <Typography>{toFullName(task.completed_by)}</Typography>
      </View>
    );
  }

  if (task.completed_at !== null && task.completed_by !== null) {
    return (
      <View>
        <Typography
          style={[styles.statusByUserLabel, { color: colors.gray400 }]}
        >
          Completed By:{" "}
        </Typography>
        <Typography>{toFullName(task.completed_by)}</Typography>
      </View>
    );
  }

  if (task.completed_at === null) {
    return (
      <View>
        <Typography
          style={[styles.statusByUserLabel, { color: colors.gray400 }]}
        >
          Created By:{" "}
        </Typography>
        <Typography>{toFullName(task.created_by)}</Typography>
      </View>
    );
  }

  return null;
};

export const TaskListItem = ({ task, numColumns }: TaskListItemProps) => {
  const { shadows, colors } = useTheme();
  const taskStatus = useStatusFromTask(task);
  const { openWith } = useImageViewer();
  const { openCreateModal } = useTaskFlagModal();
  const { openModal: openResolveTaskFlagModal } = useResolveTaskFlagModal();
  const Icon = taskStatus.Icon;

  const image = task.meta?.images?.[task.meta?.images?.length - 1]?.url;
  const lastTaskFlag = useMemo(() => last(task.task_flags), [task.task_flags]);

  return (
    <View
      style={[
        styles.taskListItem,
        shadows.light,
        { backgroundColor: colors.background, flex: 1 / numColumns },
      ]}
    >
      <View style={styles.taskInformation}>
        <Typography style={[styles.title, { color: colors.textSecondary }]}>
          {task.name}
        </Typography>
        <View style={styles.status}>
          <Icon />
          <Typography style={[styles.statusLabel, { color: taskStatus.color }]}>
            {taskStatus.label}
          </Typography>
        </View>
        <StatusByUser {...task} />
        {!lastTaskFlag ||
        (lastTaskFlag && lastTaskFlag.completed_at !== null) ? (
          <Button
            variation="outlined"
            iconLeft={FlagSolid}
            onPress={() =>
              openCreateModal(task.id, task.name, last(task.task_flags) ?? null)
            }
          >
            Flag Task
          </Button>
        ) : null}

        {lastTaskFlag && lastTaskFlag.completed_at === null ? (
          <Button
            variation="default"
            iconLeft={FlagSolid}
            style={{ backgroundColor: colors.error }}
            onPress={() =>
              openResolveTaskFlagModal({
                taskFlagId: lastTaskFlag.id,
                completedByEmail: lastTaskFlag.completed_by_email,
                createdByEmail: lastTaskFlag.created_by_email,
                createdOn: lastTaskFlag.inserted_at,
                reason: lastTaskFlag.reason,
                completedOn: lastTaskFlag.completed_at,
              })
            }
          >
            Task Flagged
          </Button>
        ) : null}

        {lastTaskFlag ? (
          <View style={styles.reasonContainer}>
            <Typography>Flag: {lastTaskFlag.reason}</Typography>
          </View>
        ) : null}

        {task.cannot_complete_reason !== null ? (
          <Typography>Reason: {task.cannot_complete_reason}</Typography>
        ) : null}
      </View>
      <View style={styles.taskPicture}>
        {image ? (
          <TouchableHighlight
            onPress={() => openWith(image, task?.completed_at)}
            style={styles.touchableImage}
          >
            <>
              <Image
                style={styles.imageHeight}
                source={{ uri: image }}
                resizeMode="cover"
              />
              <View
                style={{
                  width: "100%",
                  backgroundColor: colors.background,
                  position: "absolute",
                  bottom: 0,
                }}
              >
                {formatDate({
                  date: task?.completed_at || "",
                  pattern: "P p",
                })}
              </View>
            </>
          </TouchableHighlight>
        ) : (
          <NoImage />
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  taskListItem: {
    flexDirection: "row",
    borderRadius: 8,
    minHeight: 220,
    maxHeight: 400,
    margin: 12,
  },
  taskInformation: {
    flex: 1,
    padding: 16,
    flexDirection: "column",
    justifyContent: "space-between",
  },
  taskPicture: {
    flex: 1,
    height: "100%",
  },
  title: {
    fontSize: 18,
    fontWeight: "bold",
  },
  status: {
    flexDirection: "row",
    marginVertical: 8,
  },
  statusLabel: {
    fontWeight: "bold",
  },
  noImage: {
    alignItems: "center",
    justifyContent: "center",
    marginVertical: "auto",
  },
  statusByUserLabel: {
    fontSize: 14,
  },
  imageHeight: {
    height: "100%",
  },
  touchableImage: {
    width: "100%",
    height: "100%",
  },
  reasonContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
});
