import React, { useState, useCallback } from "react";
import { CheckCircledIcon, CopyIcon } from "@radix-ui/react-icons";

import { Button } from "@/_v2/components/ui/button";
import { cn } from "@/_v2/utils";

export interface CopyTextButtonProps {
  text: string;
  className?: string;
  iconClassName?: string;
}

export const CopyTextButton: React.FC<
  React.PropsWithChildren<CopyTextButtonProps>
> = ({ text, className, iconClassName, children }) => {
  const [successState, setSuccessState] = useState<boolean>(false);

  const copyText = useCallback<() => void>(async () => {
    try {
      await navigator.clipboard.writeText(text);
      setSuccessState(true);
      setTimeout(() => {
        setSuccessState(false);
      }, 2000);
    } catch (e) {
      alert("Failed to copy text. You might try updating your browser.");
    }
  }, [text]);

  const icon = successState ? (
    <CheckCircledIcon className={cn("size-4", iconClassName)} />
  ) : (
    <CopyIcon className={cn("size-4", iconClassName)} />
  );

  return (
    <>
      <Button
        variant="invisible"
        size="icon"
        onClick={copyText}
        className={className}
      >
        {children ? (
          <div className="flex items-center">
            {children}
            {icon}
          </div>
        ) : (
          icon
        )}
      </Button>
    </>
  );
};
