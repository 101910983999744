import { useQuery } from "@tanstack/react-query";

import { instance, publicInstance } from "@/lib/hooks";

interface FeatureFlagParams {
  name: string;
}

interface FeatureFlagResponse {
  enabled: boolean;
}

const DEFAULT_RESPONSE: FeatureFlagResponse = { enabled: false };

/** Use a supportrent feature flag
 * @params {FeatureFlagParams} params - The feature flag to check. Can be scoped to a group or organization.
 * @params {React Query Option} options - Options to pass to the query.
 */
export const useSRFeatureFlag = (params: FeatureFlagParams) => {
  return useQuery(
    ["feature-flag", params],
    async () => {
      try {
        const { data } = await instance.get<FeatureFlagResponse>(
          `/sr-feature-flags/${params.name}`
        );
        return data;
      } catch (e) {
        return DEFAULT_RESPONSE;
      }
    },
    {
      placeholderData: DEFAULT_RESPONSE,
    }
  );
};

export const usePublicSRFeatureFlag = (params: FeatureFlagParams) => {
  return useQuery(
    ["feature-flag", params],
    async () => {
      try {
        const { data } = await publicInstance.get<FeatureFlagResponse>(
          `/sr-feature-flags/${params.name}`
        );
        return data;
      } catch (e) {
        return DEFAULT_RESPONSE;
      }
    },
    {
      placeholderData: DEFAULT_RESPONSE,
    }
  );
};
