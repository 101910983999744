import { InstallProjectProps } from "@smartrent/install";
import { DrawerContent, DrawerHeader, VStack } from "@smartrent/ui";

import { ProjectCompletionReport } from "./reports/ProjectCompletionReport";

type ProjectMeta = {
  reports: {
    completion_report: string;
  };
};
type ProjectWithUrl = InstallProjectProps & { meta: ProjectMeta };

type Props = {
  project: ProjectWithUrl;
};

export function ProjectReportsDrawer({ project }: Props) {
  return (
    <>
      <DrawerHeader title={`${project.name} Reports`} />
      <DrawerContent>
        <VStack spacing={8}>
          {project?.meta?.reports?.completion_report ? (
            <ProjectCompletionReport projectId={project.id} />
          ) : null}
        </VStack>
      </DrawerContent>
    </>
  );
}
