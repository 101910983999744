import { useContext } from "react";
import * as React from "react";
import { View, StyleSheet } from "react-native";
import { map, find } from "lodash-es";
import { VStack } from "@smartrent/ui";
import { useWindowDimensionsQuery } from "@smartrent/hooks";

import { AccessControlDevice, DeviceProps } from "@/types";
import Layout from "@/layout/Layout";
import Context from "@/layout/Context";
import { Accordion } from "@/common/Accordion";
import { ManagerQuickActions } from "@/common/links/SmartLinks";
import { useDocumentTitle } from "@/hooks/use-document-title";

// Unit Components
import UnitJobsCard from "../common/jobs/UnitJobsCard";

import { UnitInfo } from "./unit/UnitInfo";
import { Hub } from "./unit/Hub";
import { TourCard } from "./unit/TourCard";
import Activity from "./unit/Activity";
import AccessCodes from "./unit/AccessCodes";
import WorkOrders from "./unit/WorkOrders";
import Residents from "./unit/Residents";
import Headlines from "./unit/Headlines";
import Devices from "./unit/Devices";
import { AccessControlDeviceCard } from "./unit/AccessControlDeviceCard";
import DeletedDevices from "./unit/DeletedDevices";
import { DeletedAccessControlDevices } from "./unit/DeletedAccessControlDevices";
import { AccessCredentials } from "./unit/AccessCredentials";
import { RetryStuckAccessCodes } from "./unit/RetryStuckAccessCodes";
import { useUnit } from "./unit/UnitContext";
import { UnitWifiCard } from "./unit/UnitWifiCard";
import { RingDoorbell } from "./unit/RingDoorbell";

export const Unit: React.FC = () => {
  useDocumentTitle("Unit Details");
  const { user } = useContext(Context);
  const { small, medium } = useWindowDimensionsQuery();

  const {
    unit,
    residents,
    deletedDevices,
    devices,
    accessCodes,
    accessControlDevices,
    ringDoorbells,
    deletedAccessControlDevices,
    organization,
  } = useUnit();

  const locks: DeviceProps[] = [];
  const thermostats: DeviceProps[] = [];
  const otherDevices: DeviceProps[] = [];

  devices.forEach((d) => {
    if (["entry_control"].includes(d.type)) {
      locks.push(d);
    } else if (["thermostat"].includes(d.type)) {
      thermostats.push(d);
    } else {
      otherDevices.push(d);
    }
  });

  const accessControlEntryDevices: AccessControlDevice[] = [];
  const accessControlThermostatDevices: AccessControlDevice[] = [];
  const accessControlOtherDevices: AccessControlDevice[] = [];

  accessControlDevices.forEach((d) => {
    if (["entry_control"].includes(d.device_type)) {
      accessControlEntryDevices.push(d);
    } else if (["thermostat"].includes(d.device_type)) {
      accessControlThermostatDevices.push(d);
    } else {
      accessControlOtherDevices.push(d);
    }
  });

  return (
    <Layout>
      <Headlines />
      <View style={styles.content}>
        {user.permissions.core_platform_access && unit?.id && unit?.group_id ? (
          <ManagerQuickActions
            smartLinks={[
              {
                title: "Unit Overview",
                href: `/manager/groups/${unit.group_id}/units/${unit.id}/overview`,
                organizationUrl: organization.url,
              },
              {
                title: "Device Pairing",
                href: `/manager/groups/${unit.group_id}/units/${unit.id}/installer/device-setup/add-new-zwave-device`,
                organizationUrl: organization.url,
              },
            ]}
          />
        ) : null}

        <VStack spacing={16}>
          <View style={small || medium ? styles.flexCol : styles.flexRow}>
            <UnitInfo />
            <Hub />
            <UnitWifiCard />
          </View>

          <VStack spacing={16}>
            <Accordion
              isDisabled={residents.length === 0}
              title={`Residents (${residents.length.toLocaleString()})`}
            >
              <Residents />
            </Accordion>

            <Accordion
              isDisabled={!locks.length && !accessControlDevices.length}
              title={`Locks (${(
                locks.length + accessControlEntryDevices.length
              ).toLocaleString()})`}
            >
              <View style={styles.accordionCards}>
                <Devices devices={locks} />

                {accessControlEntryDevices.map((device) => {
                  return (
                    <View key={`acd-${device.id}`}>
                      <AccessControlDeviceCard device={device} />
                    </View>
                  );
                })}
              </View>
            </Accordion>
            <Accordion
              isDisabled={thermostats.length === 0}
              title={`Thermostats (${(
                thermostats.length + accessControlThermostatDevices.length
              ).toLocaleString()})`}
            >
              <View style={styles.accordionCards}>
                <Devices devices={thermostats} />

                {accessControlThermostatDevices.map((device) => {
                  return (
                    <View key={`acd-${device.id}`}>
                      <AccessControlDeviceCard device={device} />
                    </View>
                  );
                })}
              </View>
            </Accordion>
            <Accordion
              isDisabled={
                otherDevices.length +
                  accessControlOtherDevices.length +
                  ringDoorbells.length ===
                0
              }
              title={`Other Devices/Sensors (${(
                otherDevices.length +
                accessControlOtherDevices.length +
                ringDoorbells.length
              ).toLocaleString()})`}
            >
              <View style={styles.accordionCards}>
                <Devices devices={otherDevices} />
                {accessControlOtherDevices.map((device) => {
                  return (
                    <View key={`acd-${device.id}`}>
                      <AccessControlDeviceCard device={device} />
                    </View>
                  );
                })}
                {ringDoorbells.map((device) => (
                  <View key={`rd-${device.id}`}>
                    <RingDoorbell device={device} />
                  </View>
                ))}
              </View>
            </Accordion>

            <Accordion
              isDisabled={
                deletedDevices.length === 0 &&
                deletedAccessControlDevices.length === 0
              }
              title={`Deleted Devices (${(
                deletedAccessControlDevices.length + deletedDevices.length
              ).toLocaleString()})`}
              initialOpenState={false}
            >
              <View style={styles.accordionCards}>
                <DeletedDevices />
                <DeletedAccessControlDevices />
              </View>
            </Accordion>
          </VStack>

          {unit.can_be_toured ? <TourCard /> : null}

          <AccessCredentials />

          <RetryStuckAccessCodes unitId={unit.id} />
          {map(accessCodes, (acs, device_id) => (
            <AccessCodes
              key={device_id}
              access_codes={acs}
              device={find(devices, { id: Number(device_id) })}
            />
          ))}

          {user.permissions.view_installs ? <UnitJobsCard /> : null}

          {user.permissions.work_orders ? <WorkOrders /> : null}

          <Activity />
        </VStack>
      </View>
    </Layout>
  );
};

const styles = StyleSheet.create({
  accordionCards: {
    flexDirection: "row",
    flexWrap: "wrap",
    padding: 8,
  },
  content: { margin: 16 },
  flexRow: {
    width: "100%",
    flexDirection: "row",
    gap: 16,
  },
  flexCol: {
    width: "100%",
    flexDirection: "column",
    gap: 16,
  },
});
