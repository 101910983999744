import * as React from "react";
import { FlatList, StyleSheet } from "react-native";

import { camelCase, isPlainObject, startCase } from "lodash-es";

import { Typography, VStack } from "@smartrent/ui";

import { CredentialsListItem } from "./CredentialsListItem";

interface CredentialsListProps {
  listKey: string;
  credentials: Record<string, Record<string, string>>;
  size: "large" | "small";
}

export const CredentialsList: React.FC<
  React.PropsWithChildren<CredentialsListProps>
> = ({ listKey, credentials, size }) => {
  const newListKey = `CredentialsList-${listKey}`;

  return (
    <FlatList
      key={newListKey}
      testID={newListKey}
      keyExtractor={([key], index) => `${key}-${index}`}
      data={Object.entries(credentials ? credentials : {})}
      ListEmptyComponent={
        <Typography color="mutedBackgroundGrayscale" type="bodySmall">
          No Credentials
        </Typography>
      }
      renderItem={({ item: [key, value], ...props }) => {
        return isPlainObject(value) ? (
          <VStack spacing={8} style={styles.nestedListContainer}>
            <Typography
              color="textPrimary"
              type={size === "large" ? "title2" : "title5"}
            >
              {startCase(camelCase(key))}:
            </Typography>

            <FlatList
              key={`FlatList-${key}-${newListKey}`}
              testID={`FlatList-${key}-${newListKey}`}
              keyExtractor={([key], index) => `${key}-${index}`}
              style={styles.nestedList}
              data={Object.entries(value ? value : {})}
              ListEmptyComponent={
                <Typography color="mutedBackgroundGrayscale" type="bodySmall">
                  No Credentials
                </Typography>
              }
              renderItem={(props) => (
                <CredentialsListItem size={size} {...props} />
              )}
            />
          </VStack>
        ) : (
          <CredentialsListItem
            style={styles.listContainer}
            item={[key, value]}
            size={size}
            {...props}
          />
        );
      }}
    />
  );
};

const styles = StyleSheet.create({
  listContainer: { paddingVertical: 2 },
  nestedListContainer: { paddingVertical: 8 },
  nestedList: { marginLeft: 8 },
});
