import { useCallback } from "react";
import * as React from "react";
import { StyleSheet } from "react-native";
import type { QueryKey } from "@tanstack/react-query";

import { Typography, Table, useTableQuery, ColumnOptions } from "@smartrent/ui";

import {
  formatDateToBeHumanReadable,
  formatPhone,
  getUserRoleName,
  userEmployeeRoleOptions,
  userLastActiveOptions,
  userStatusOptions,
} from "@/utils";
import { instance } from "@/lib/hooks";
import { TableFilters, UserProps } from "@/types";

import { UserAccountAccepted } from "@/common/users/UserStatus";

interface UsersApiResponse extends Partial<UserProps> {
  id: number;
  name: string;
  phone_number?: string | null;
  invitation_sent_at?: string | null;
  employee_role?: string | null;
  updated_at?: string | null;
  inserted_at?: string | null;
}

const hiddenTableFilterColumns = [
  {
    name: "last_active",
    header: "Last Active",
    filterType: {
      type: "selectField",
      options: userLastActiveOptions,
    },
    render: () => null,
    hidden: true,
  },
  // Note: We have 2 columns with 'role' information
  //   1. role          --> this is raw value of users.role from db
  //   2. employee_role --> this is a custom queryFilter in CMW controller
  //
  // We use #2, employee_role, because that's how CMW has been doing it for last couple years
  {
    name: "employee_role",
    header: "Employee Role",
    filterType: {
      type: "selectField",
      options: userEmployeeRoleOptions,
    },
    render: () => null,
    hidden: true,
  },
  {
    name: "status",
    header: "Status",
    filterType: {
      type: "selectField",
      options: userStatusOptions,
    },
    render: () => null,
    hidden: true,
  },
  {
    name: "organization_id",
    header: "Organization ID",
    filterType: {
      type: "numberInputField",
    },
    render: () => null,
    hidden: true,
  },
  {
    name: "group_id",
    header: "Group ID",
    filterType: {
      type: "numberInputField",
    },
    render: () => null,
    hidden: true,
  },
  {
    name: "service_account",
    header: "Service Account",
    filterType: {
      type: "radioGroup",
      items: [
        { label: "Yes", value: "true" },
        { label: "No", value: "false" },
      ],
    },
    render: () => null,
    hidden: true,
  },
] as ColumnOptions<UsersApiResponse>[];

export const UsersTable: React.VFC = () => {
  const defaultPageSize = 25;

  // useTableQuery hook from js-shared
  const tableProps = useTableQuery<any, UsersApiResponse, any>({
    fetch: async ({ queryKey }: { queryKey: QueryKey }) => {
      const [, , params] = queryKey as [
        string,
        Record<string, unknown>,
        TableFilters,
      ];
      const { data } = await instance.get<UsersApiResponse>("/users", {
        params,
      });
      return data;
    },
    getQueryKey: ({ filters, page, pageSize, sortColumn, sortDirection }) => [
      "users-query-key",
      {},
      {
        ...filters,
        page,
        limit: pageSize,
        sort: sortColumn,
        dir: sortDirection,
      },
    ],
    defaultPageSize,
    columns: [
      // placing hidden filters first, so they come first in TableFilter dropdown
      ...hiddenTableFilterColumns,
      {
        name: "name",
        header: "Name",
        render: ({ row: { first_name, last_name } }) => (
          <Typography numberOfLines={1}>
            {first_name} {last_name}
          </Typography>
        ),
        sortable: true,
        filterType: {
          type: "textInputField",
        },
        style: styles.column,
        relativeWidth: columnWidths.name,
      },
      {
        name: "phone_number",
        header: "Phone",
        render: ({ row: { phone_number } }) => (
          <Typography>{formatPhone({ phone: phone_number })}</Typography>
        ),
        sortable: false,
        filterType: {
          type: "textInputField",
        },
        style: styles.column,
        relativeWidth: columnWidths.phone,
      },
      {
        name: "email",
        header: "Email",
        render: ({ row: { email } }) => (
          <Typography numberOfLines={1}>{email}</Typography>
        ),
        sortable: true,
        filterType: {
          type: "textInputField",
        },
        style: styles.column,
        relativeWidth: columnWidths.email,
      },
      {
        name: "role",
        header: "Role",
        render: ({ row: { role, employee_role } }) => (
          <Typography numberOfLines={1}>
            {getUserRoleName({ role, employee_role })}
          </Typography>
        ),
        sortable: true,
        style: styles.column,
        relativeWidth: columnWidths.role,
      },
      {
        name: "inserted_at",
        header: "Created",
        render: ({ row: { inserted_at } }) => (
          <Typography numberOfLines={1}>
            {formatDateToBeHumanReadable({ date: inserted_at })}
          </Typography>
        ),
        sortable: true,
        style: styles.column,
        relativeWidth: columnWidths.time,
      },
      {
        name: "last_sign_in_at",
        header: "Signed In",
        render: ({ row: { last_sign_in_at } }) => (
          <Typography numberOfLines={1}>
            {formatDateToBeHumanReadable({ date: last_sign_in_at })}
          </Typography>
        ),
        sortable: true,
        style: styles.column,
        relativeWidth: columnWidths.time,
      },
      {
        name: "account_accepted",
        header: "Account Accepted",
        render: ({ row }) => <UserAccountAccepted {...row} />,
        sortable: false,
        style: styles.columnAccepted,
        relativeWidth: columnWidths.accepted,
      },
    ],
  });

  const handleGetRowHref = useCallback(
    ({ id }: UsersApiResponse) => `/users/${id}`,
    []
  );

  return (
    <Table<UsersApiResponse>
      title="Users"
      noRecordsText="No Users"
      getRowHref={handleGetRowHref}
      showClearFiltersButton
      {...tableProps}
    />
  );
};

// relative widths that looked nicer than default ones
const columnWidths = {
  name: 80,
  email: 100,
  phone: 45,
  role: 50,
  time: 50,
  accepted: 40,
};

const styles = StyleSheet.create({
  column: {
    alignItems: "center",
  },

  // This column has icons that offset the widths a bit
  // & no sort icon in the header, which messes with the 'neatness'
  // ..so we hack it a bit to make it look nicer
  columnAccepted: {
    alignItems: "center",
    justifyContent: "flex-start",
    textAlign: "left",
    marginLeft: 12,
  },
});
