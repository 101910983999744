import * as React from "react";
import { StyleSheet, View } from "react-native";

import Layout from "@/layout/Layout";

import { useServerConfigHook } from "@/pages/hub-refurbish/hooks/useServerConfigHook";
import { HubRefurbishBreadcrumbs } from "@/pages/hub-refurbish/HubRefurbishBreadcrumbs";
import { HubRefurbishKillSwitchOn } from "@/pages/hub-refurbish/HubRefurbishKillSwitchOn";

import { useDocumentTitle } from "@/hooks/use-document-title";

import { HubRefurbishSteps } from "./steps/HubRefurbishSteps";

/*
  Hub Refurb Steps:
  1. Hubs Online
  2. Hubs Offline
  3. Reset Hubs
  4. Hubs Online With SD Card
  5. Procure Hubs (Hub Prep)

  To change the numbered order edit the `HubRefurbishStep` enum in `HubRefurbishProps.tsx`
*/

export const HubRefurbish: React.FC = () => {
  useDocumentTitle("Hub Refurbish");
  const { isKillSwitchOn } = useServerConfigHook();

  if (isKillSwitchOn) {
    return <HubRefurbishKillSwitchOn />;
  }

  return (
    <Layout>
      <View style={styles.container}>
        <HubRefurbishBreadcrumbs />

        <HubRefurbishSteps />
      </View>
    </Layout>
  );
};

const styles = StyleSheet.create({
  container: {
    marginVertical: 16,
    marginHorizontal: 20,
  },
});
