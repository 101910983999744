import * as React from "react";
import classNames from "classnames";
import AriaModal from "react-aria-modal";
import { Formik } from "formik";
import { Cross1Icon } from "@radix-ui/react-icons";

import { Button } from "@/_v2/components/ui/button";
import { ScrollArea } from "@/_v2/components/ui/scroll-area";

type FormikModalProps = {
  title?: string;
  toggleModal: () => void;
  className?: string;
  style?: Object;
  children: React.ReactNode;
  cancelText?: string;
  confirmText?: string;
  confirmEnabled?: boolean;
  onSubmit: (formikBag: Object) => void;
  validationSchema?: Object; // a form can be submitted without inputs
  initialValues?: Object; // a form can be submitted without inputs
};

/**
 * A modal that opens above other page content to display important information or user actions.
 */
export default function FormikModal({
  title,
  toggleModal,
  className,
  style,
  children,
  cancelText,
  confirmText,
  confirmEnabled,
  onSubmit,
  validationSchema,
  initialValues,
}: FormikModalProps) {
  return (
    <AriaModal
      titleText={title}
      onExit={() => toggleModal()}
      underlayStyle={{
        display: "flex",
        flexFlow: "column",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 1,
      }}
      dialogClass=""
      focusDialog={true}
    >
      <div
        className={classNames(
          "bg-popover shadow-lg border border-border",
          className
        )}
        style={{
          ...{
            display: "flex",
            position: "relative",
            flexDirection: "column",
            justifyContent: "flex-start",
            margin: "0 auto",
            flexGrow: 1,
            maxHeight: "70vh",
            overflow: "hidden",
          },
          ...style,
        }}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <Formik
          initialValues={initialValues || {}}
          onSubmit={onSubmit}
          validateOnBlur={true}
          validationSchema={validationSchema}
        >
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <header className="flex-none flex flex-row flex-nowrap justify-between items-center w-full py-3 px-6 pl-6 mx-auto text-left border-b border-border">
                <div>
                  <h4 className={classNames("h4")} style={classes.title}>
                    {title}
                  </h4>
                </div>
                <div>
                  <Button
                    variant="ghost"
                    size="icon"
                    aria-label="Close"
                    onClick={() => toggleModal()}
                  >
                    <Cross1Icon className="size-6" />
                  </Button>
                </div>
              </header>
              <ScrollArea className="px-4 py-6">{children}</ScrollArea>
              <footer className="flex-none flex flex-row flex-nowrap justify-end items-center w-full p-2 bg-popover border-t border-border space-x-2">
                <Button
                  type="button"
                  variant="ghost"
                  onClick={() => toggleModal()}
                >
                  {cancelText || "Cancel"}
                </Button>
                <Button
                  type="submit"
                  disabled={confirmEnabled !== null ? confirmEnabled : true}
                >
                  {confirmText || "Confirm"}
                </Button>
              </footer>
            </form>
          )}
        </Formik>
      </div>
    </AriaModal>
  );
}

const classes = {
  dialog: {
    position: "relative",
    maxHeight: "90vh",
  },
  title: {
    display: "flex",
    flexFlow: "row nowrap",
    justifyContent: "flex-start",
    alignItems: "center",

    "& svg": {
      marginRight: "8px",
    },
  },
};
