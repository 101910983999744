import * as React from "react";
import { View, StyleSheet } from "react-native";

import Layout from "@/layout/Layout";
import { useGroupQuery, useParkingPropertyQuery } from "@/api";
import { useIsDesktop } from "@/lib/hooks";

import { PropertyDetailPanel } from "@/common/parking/PropertyDetailPanel";
import { PropertyHeader } from "@/common/parking/PropertyHeader";
import { SignsTable } from "@/common/parking/SignsTable";
import { DecalTable } from "@/common/parking/DecalTable";
import { SensorTable } from "@/common/parking/SensorTable";
import { EnableParkingPanel } from "@/common/parking/EnableParkingPanel";
import { GuestPaymentsPanel } from "@/common/parking/GuestPaymentsPanel";
import { ParkingMapPanel } from "@/common/parking/ParkingMapPanel";
import { useUser } from "@/layout/Context";
import { useDocumentTitle } from "@/hooks/use-document-title";

interface ParkingPropertyDetailsProps {
  groupId: number;
}

export const ParkingPropertyDetails: React.FC<
  React.PropsWithChildren<ParkingPropertyDetailsProps>
> = ({ groupId }) => {
  useDocumentTitle("Parking Property Details");
  const isDesktop = useIsDesktop();

  const { data: group } = useGroupQuery(groupId);
  const { permissions } = useUser();

  const { data: parkingProperty } = useParkingPropertyQuery(
    group?.parking_enabled ? group?.uuid : null
  );

  return (
    <Layout>
      <PropertyHeader group={group} />
      {group && parkingProperty ? (
        <View style={isDesktop ? desktopStyles.root : mobileStyles.root}>
          {group?.parking_enabled ? (
            <>
              <View style={desktopStyles.container}>
                <PropertyDetailPanel groupId={groupId} />
                {parkingProperty?.guest_parking_payments_module_enabled &&
                permissions.manage_parking_payments ? (
                  <>
                    <View style={desktopStyles.verticalSpacer} />
                    <View style={desktopStyles.container}>
                      <GuestPaymentsPanel propertyId={parkingProperty.id} />
                    </View>
                  </>
                ) : null}
                {parkingProperty?.maps_module_enabled ? (
                  <>
                    <View style={desktopStyles.verticalSpacer} />
                    <ParkingMapPanel propertyId={parkingProperty.id} />
                  </>
                ) : null}
              </View>
              <View
                style={
                  isDesktop
                    ? desktopStyles.horizontalSpacer
                    : desktopStyles.verticalSpacer
                }
              />
              <View style={desktopStyles.tables}>
                {permissions.manage_parking ? (
                  <>
                    <DecalTable
                      propertyId={parkingProperty.id}
                      groupId={groupId}
                    />
                    <View style={desktopStyles.verticalSpacer} />
                    <SensorTable
                      propertyId={parkingProperty.id}
                      groupId={groupId}
                    />
                    <View style={desktopStyles.verticalSpacer} />
                  </>
                ) : null}
                {parkingProperty?.guest_parking_module_enabled ? (
                  <SignsTable groupId={groupId} propertyId={group?.uuid} />
                ) : null}
              </View>
            </>
          ) : (
            <EnableParkingPanel groupId={groupId} propertyId={group?.uuid} />
          )}
        </View>
      ) : null}
    </Layout>
  );
};

const mobileStyles = StyleSheet.create({
  root: {
    paddingHorizontal: 24,
    paddingBottom: 24,
  },
});

const desktopStyles = StyleSheet.create({
  root: {
    flexDirection: "row",
    paddingHorizontal: 24,
    paddingBottom: 24,
    justifyContent: "space-around",
  },
  verticalSpacer: { height: 24 },
  horizontalSpacer: { width: 24 },
  container: { flex: 1, maxWidth: 360 },
  tables: { flex: 2 },
});
