import { Link, useLocation } from "react-router-dom";

import { KabobSolid } from "@smartrent/icons";

import {
  useIsDesktop,
  useIsLgDesktop,
  useIsXLDesktop,
} from "@/hooks/breakpoints";

import {
  Menubar,
  MenubarContent,
  MenubarItem,
  MenubarMenu,
  MenubarTrigger,
} from "@/_v2/components/ui/menubar";
import { navigationMenuTriggerStyle } from "@/_v2/components/ui/navigation-menu";
import { cn } from "@/_v2/utils";

import {
  NavigationItem,
  NavigationLink,
  useNavigationLinks,
} from "./navigation-links";

// TODO: turn this into a user preference later
const showIcons = false;

export const HeaderMenuBar = () => {
  const navigationLinks = useNavigationLinks();

  const isXLDesktop = useIsXLDesktop();
  const isLgDesktop = useIsLgDesktop();
  const isDesktop = useIsDesktop();
  let largeMenuLinks = navigationLinks;
  let smallMenuLinks: NavigationItem[] = [];

  if (!isDesktop || navigationLinks.length === 0) {
    return null;
  } else if (isLgDesktop && navigationLinks.length > 4) {
    largeMenuLinks = navigationLinks.slice(0, 4);
    smallMenuLinks = navigationLinks.slice(4);
  } else if (isXLDesktop && navigationLinks.length > 8) {
    largeMenuLinks = navigationLinks.slice(0, 7);
    smallMenuLinks = navigationLinks.slice(7);
  }

  return (
    <div className="flex items-center">
      {largeMenuLinks.map((link) => {
        return <HeaderMenuLink key={link.to} {...link} />;
      })}
      {smallMenuLinks.length > 0 && (
        <HeaderMenuDropdown
          {...{
            to: "",
            icon: KabobSolid,
            links: smallMenuLinks,
          }}
        />
      )}
    </div>
  );
};

const HeaderMenuLink = ({ to, label, icon: Icon }: NavigationItem) => {
  const location = useLocation();
  const isActive = to === location.pathname;

  return (
    <Link
      to={to}
      className={cn(navigationMenuTriggerStyle(), { "bg-accent": isActive })}
    >
      {showIcons && Icon && (
        <span className="mr-1">
          <Icon size={16} />
        </span>
      )}
      {label}
    </Link>
  );
};

const HeaderMenuDropdown = ({ icon: Icon, links }: NavigationItem) => {
  return (
    <Menubar className="border-none">
      <MenubarMenu>
        <MenubarTrigger>
          <Icon size={18} />
        </MenubarTrigger>
        <MenubarContent>
          {links?.map((link) => (
            <HeaderMenuDropdownItem key={link.to} link={link} />
          ))}
        </MenubarContent>
      </MenubarMenu>
    </Menubar>
  );
};
const HeaderMenuDropdownItem = ({
  link: { to, icon: Icon, label },
}: {
  link: NavigationLink;
}) => {
  return (
    <MenubarItem>
      <Link to={to} className="flex items-center w-full">
        {showIcons && Icon && (
          <span className="mr-2">
            <Icon size={16} />
          </span>
        )}
        {label}
      </Link>
    </MenubarItem>
  );
};
