import { useState, useEffect, useRef } from "react";
import { PlatformProvider } from "@smartrent/ui";

import CompleteOnboarding from "@/pages/accounts/CompleteOnboarding";

import { usePageTracking } from "@/hooks/use-page-tracking";

import Helpers from "@/lib/helpers";

import { AccountProps } from "@/types";

import Context from "./Context";

export default function Provider({ children, match, location, history }) {
  usePageTracking();
  const [sidenavOpen, setSidenavOpen] = useState(false);
  const [flashMessage, setFlashMessage] = useState(null);
  const [toast, setToast] = useState(null);
  const scrollViewRef = useRef(null);

  const [user, setUser] = useState<AccountProps>(Helpers.defaultUser);
  const [pageLoading, setPageLoading] = useState(false);

  const userId = user?.id;
  useEffect(() => {
    if (!userId) {
      Helpers.auth.getUser().then(setUser);
    }
  }, [userId]);

  if (userId && !user.role_id) {
    return <CompleteOnboarding />;
  }

  return (
    <Context.Provider
      value={{
        pageLoading: !userId ? true : pageLoading,
        setPageLoading,
        setUser,
        user,
        sidenavOpen,
        setSidenavOpen,
        match,
        location,
        history,
        isDesktop: window.outerWidth > 480,
        setFlashMessage: ({ type, message }) =>
          setFlashMessage({ type, message }),
        flashMessage,
        toast,
        setToast,
        scrollViewRef,
      }}
    >
      <PlatformProvider>{children}</PlatformProvider>
    </Context.Provider>
  );
}
