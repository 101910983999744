import * as React from "react";
import { useState } from "react";
import { StyleSheet, View } from "react-native";
import { groupBy, upperCase } from "lodash-es";

import {
  Button,
  DrawerContent,
  DrawerHeader,
  Typography,
  VStack,
  useTheme,
  HStack,
  Checkbox,
  CheckedType,
  TextInputField,
} from "@smartrent/ui";

import { useDrawerNav } from "@/common/AppDrawer";
import { PERMISSIONS } from "@/lib/permissions";
import { CopyText } from "@/common/CopyText";

export const CreateRole: React.FC = () => {
  const [roleName, setRoleName] = useState("");

  const [selectedPermissions, setSelectedPermissions] = useState({});
  const { colors } = useTheme();
  const { pop } = useDrawerNav();

  const groupedPermissions = groupBy(
    PERMISSIONS,
    (permission) => permission.type
  );

  const PermissionGroup = ({ type, permissions }) => {
    return (
      <View style={styles.permissionGroup}>
        <View
          style={[
            styles.permissionHeader,
            { borderBottomColor: colors.border },
          ]}
        >
          <Typography type="title4" color="textSecondary">
            {upperCase(type)}
          </Typography>
        </View>
        <View>
          {permissions.map(({ name, type, label }) => {
            return (
              <View key={`${name}-${type}`} style={styles.alignCheckbox}>
                <View style={styles.alignCheckbox}>
                  <Checkbox
                    label={label}
                    name={name}
                    checked={selectedPermissions[name]}
                    onChangeChecked={function (checked: CheckedType): void {
                      if (checked) {
                        setSelectedPermissions({
                          ...selectedPermissions,
                          [name]: true,
                        });
                      } else {
                        const newObjectWithDeletedPermission = {
                          ...selectedPermissions,
                        };
                        delete newObjectWithDeletedPermission[name];

                        setSelectedPermissions(newObjectWithDeletedPermission);
                      }
                    }}
                  />
                </View>
              </View>
            );
          })}
        </View>
      </View>
    );
  };

  return (
    <>
      <DrawerHeader
        title="Create New Role"
        subtitle="Use this tool to generate the permissions for the new role."
      />

      <DrawerContent>
        <VStack
          spacing={16}
          justify="space-between"
          style={styles.contentHeight}
        >
          <VStack spacing={16}>
            <TextInputField
              label="Role Name"
              textInputProps={{
                value: roleName,
                onChangeText: setRoleName,
              }}
              required
            />
            {Object.entries(groupedPermissions).map(([key, value]) => (
              <PermissionGroup key={key} type={key} permissions={value} />
            ))}
          </VStack>

          <VStack spacing={8}>
            <HStack spacing={16}>
              <Button
                onPress={() => {
                  setSelectedPermissions(
                    PERMISSIONS.reduce((acc, { name }) => {
                      acc[name] = true;
                      return acc;
                    }, {})
                  );
                }}
              >
                Select All Permissions
              </Button>
              <Button
                onPress={() => {
                  setSelectedPermissions({});
                }}
              >
                Clear All Permissions
              </Button>
            </HStack>
            <View
              style={{
                width: "100%",
                height: "auto",
              }}
            >
              <CopyText
                text={JSON.stringify(
                  { name: roleName, ...selectedPermissions },
                  null,
                  2
                )}
                style={{
                  position: "absolute",
                  top: 8,
                  right: 8,
                }}
                iconSize={24}
                iconColor={colors.tertiary}
              />
              <div
                style={{
                  padding: 16,
                  overflowY: "auto",
                  whiteSpace: "pre-wrap",
                  wordWrap: "break-word",
                  fontFamily: "monospace",
                  backgroundColor: colors.gray800,
                  color: colors.white,
                  borderRadius: 4,
                  lineHeight: 1.25,
                }}
              >
                {JSON.stringify(
                  { name: roleName, ...selectedPermissions },
                  null,
                  2
                )}
              </div>
            </View>
            <HStack justify="space-between">
              <Button onPress={pop} variation="plain">
                Close
              </Button>
            </HStack>
          </VStack>
        </VStack>
      </DrawerContent>
    </>
  );
};

const styles = StyleSheet.create({
  contentHeight: { height: "100%" },
  permissionGroup: {
    margin: 8,
    width: 325,
    flexWrap: "wrap",
  },
  permissionHeader: {
    borderBottomWidth: 1,
  },
  alignCheckbox: {
    flexDirection: "row",
    alignItems: "center",
  },
});
