import { instance } from "@/lib/hooks";
import { tableQueryToQS } from "@/lib/helpers";

import { createAxiosQuery } from "@/hooks/react-query";
import { GroupProps } from "@/types";

import { PaginatedResponse, TableQuery } from "../types/api";

export type GroupResponse = Pick<GroupProps, "id" | "marketing_name" | "uuid">;
export interface GroupsResponseProps extends PaginatedResponse<GroupResponse> {}

export const useGroupsQuery = createAxiosQuery(
  "groups",
  async (filters?: TableQuery) => {
    const query = tableQueryToQS(filters);

    return instance.get<GroupsResponseProps>(`/v2/groups?${query}`);
  }
);
