/* Dev Notes: Paginated Table is deprecated, please use sr-table/Table.tsx */

import { useCallback } from "react";

import * as React from "react";
import { View, ScrollView, StyleSheet, FlatList } from "react-native";

import { Typography, useTheme } from "@smartrent/ui";

import { useIsDesktop } from "@/lib/hooks";

import NoRecords from "../NoRecords";

import PaginatedTablePaginator from "./PaginatedTablePaginator";
import PaginatedTableColumnHeader, {
  PaginatedTableColumn,
} from "./PaginatedTableColumnHeader";
import PaginatedTableRowSkeleton from "./PaginatedTableRowSkeleton";
import PaginatedTableRow from "./PaginatedTableRow";

interface PaginatedTableProps {
  isLoading: boolean;
  noRecordsText: string;
  results: any[];
  columns: PaginatedTableColumn[];
  onRowClick?(r: any): void;
  title?: string;
  totalResults: number;
  currentPage: number;
  totalPages: number;
  uniqueKey?: string | ((item: any, index: number) => string);
}

const PaginatedTable: React.FC<
  React.PropsWithChildren<PaginatedTableProps>
> = ({
  isLoading,
  results,
  columns,
  noRecordsText,
  title,
  totalResults,
  currentPage,
  totalPages,
  onRowClick,
  uniqueKey,
}) => {
  const { colors } = useTheme();
  const isEmpty = !results || results.length === 0;
  const isDesktop = useIsDesktop();

  const renderRow = useCallback(
    (item: any, index: any) => (
      <PaginatedTableRow
        columns={columns}
        item={item}
        row={index}
        onRowPress={onRowClick}
      />
    ),
    [columns, onRowClick]
  );

  return (
    <>
      {title && (
        <View style={styles.titleContainer}>
          <Typography color="primary" font="bold" style={styles.title}>
            {title}
          </Typography>
        </View>
      )}
      <View
        style={[
          styles.header,
          {
            backgroundColor: colors.pageBackground,
          },
          !isDesktop && { flexDirection: "column" },
        ]}
      >
        {columns
          .filter((column) => !column.hidden)
          .map((item) => (
            <PaginatedTableColumnHeader
              key={`${item.field}_header`}
              {...item}
            />
          ))}
      </View>
      <View style={styles.tableContainer}>
        {!isLoading && results && isEmpty ? (
          <NoRecords title={noRecordsText} style={{ marginTop: 24 }} />
        ) : (
          <ScrollView>
            {isLoading ? (
              <FlatList
                data={new Array(25).fill({ id: 0 })}
                keyExtractor={(_item: any, index) => String(index)}
                renderItem={({ index }) => (
                  <PaginatedTableRowSkeleton columns={columns} row={index} />
                )}
                style={[
                  styles.table,
                  {
                    borderTopColor: colors.border,
                  },
                ]}
              />
            ) : (
              <FlatList
                data={results}
                keyExtractor={(item: any, index) => {
                  if (uniqueKey && typeof uniqueKey === "string") {
                    return String(item[uniqueKey]);
                  } else if (typeof uniqueKey === "function") {
                    return uniqueKey(item, index);
                  }

                  return String(index);
                }}
                renderItem={({ item, index }) => renderRow(item, index)}
                style={[
                  styles.table,
                  {
                    borderTopColor: colors.border,
                  },
                ]}
                extraData={columns}
              />
            )}
          </ScrollView>
        )}
      </View>
      {!!totalPages && (
        <PaginatedTablePaginator
          totalRecords={totalResults}
          currentPage={currentPage}
          totalPages={totalPages}
          isLoading={isLoading}
          isEmpty={isEmpty}
        />
      )}
    </>
  );
};

const styles = StyleSheet.create({
  tableContainer: { marginBottom: 73.5 },
  header: {
    flexDirection: "row",
    paddingVertical: 24,
    marginTop: 24,
    zIndex: 10,
  },
  titleContainer: {
    alignItems: "center",
  },
  title: {
    paddingVertical: 10,
  },
  table: {
    borderTopWidth: 1,
  },
});

export default PaginatedTable;
