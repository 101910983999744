import { DataTable } from "@/_v2/components/composites/data-table/data-table";

import { useSRFeatureFlagsQuery } from "@/_v2/api/sr-feature-flags";

import { columns } from "./sr-feature-flags-columns";

export const SRFeatureFlagsTable = () => {
  const { data } = useSRFeatureFlagsQuery({});

  return (
    <DataTable
      columns={columns}
      data={data || []}
      rowCount={data?.length || 0}
    />
  );
};
