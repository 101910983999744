import { useState } from "react";

import { Button } from "@smartrent/ui";
import { Download } from "@smartrent/icons";

type Props = {
  projectId: string;
};

export function ProjectCompletionReport({ projectId }: Props) {
  const [disabled, setDisabled] = useState(false);

  const handleDownload = () => {
    setDisabled(true);
    setTimeout(() => {
      setDisabled(false);
    }, 5000);
  };

  const downloadUrl = `${import.meta.env.REACT_APP_BASE_API_URL}/api/alloy-install/projects/${projectId}/reports/completion_report`;

  return (
    <a href={downloadUrl} download="report.csv">
      <Button iconLeft={Download} disabled={disabled} onPress={handleDownload}>
        Project Completion Report
      </Button>
    </a>
  );
}
