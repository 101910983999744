import { CheckboxIcon, PersonIcon, SwitchIcon } from "@radix-ui/react-icons";
import {
  Calendar,
  ClipboardFilled,
  Community,
  HighRiseBuilding,
  Home,
  List,
  Map,
  ShieldPerson,
  Stopwatch,
  Users,
  WaterDrop,
} from "@smartrent/icons";

import { PermissionsProp } from "@/types";
import { useSRFeatureFlag } from "@/hooks/sr-feature-flags";
import { useUser } from "@/layout/Context";

export interface NavigationLink {
  to: string;
  label?: string;
  icon?: any;
  permission?: PermissionsProp;
  hidden?: boolean;
}

export interface NavigationItem extends NavigationLink {
  links?: NavigationLink[];
}

export const useNavigationLinks = (): NavigationItem[] => {
  const { permissions } = useUser();

  const { data: accountsV2FF, isFetched } = useSRFeatureFlag({
    name: "accounts_v2",
  });

  if (!isFetched) {
    return [];
  }

  const navigationLinks: NavigationItem[] = [
    { to: "/", label: "Home", icon: Home },
    {
      to: "/organizations",
      label: "Organizations",
      icon: Community,
      permission: "view_organizations",
    },
    {
      to: "/groups",
      label: "Groups",
      icon: HighRiseBuilding,
      permission: "view_groups",
    },
    {
      to: "/users",
      label: "Users",
      icon: Users,
      permission: "view_users",
    },
    { to: "/tours", label: "Tours", icon: Map },
    {
      to: "/alloy-install",
      label: "Installs",
      icon: Calendar,
      permission: "view_installs",
    },
    {
      to: "/hub-prep",
      label: "Hub Prep",
      icon: Stopwatch,
      permission: "hub_prep",
    },
    {
      to: "/leak-report",
      label: "Leaks",
      icon: WaterDrop,
    },
    {
      to: "/reporting",
      label: "Reports",
      icon: List,
      permission: "reporting",
    },
    {
      to: "/scope-of-work",
      label: "SOW",
      icon: ClipboardFilled,
      permission: "view_sow",
    },
    {
      to: "/feature-flags",
      label: "CMW Feature Flags",
      icon: CheckboxIcon,
      permission: "feature_flags",
    },
    {
      to: "/accounts",
      label: "Accounts",
      icon: ShieldPerson,
      permission: "modify_permissions",
      hidden: accountsV2FF?.enabled,
    },
    {
      to: "/v2/accounts",
      label: "Accounts",
      icon: PersonIcon,
      permission: "modify_permissions",
      hidden: !accountsV2FF?.enabled,
    },
    {
      to: "/v2/sr-feature-flags",
      label: "SR Feature Flags",
      icon: SwitchIcon,
      permission: "sr_feature_flags_view",
    },
  ];

  return navigationLinks.filter(
    (link) =>
      (!link.permission || !!permissions[link.permission]) && !link.hidden
  );
};
