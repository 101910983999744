import {
  FlatList,
  ListItemLoadingContainer,
  ListItemLoadingValue,
  Typography,
  useTheme,
} from "@smartrent/ui";
import { StyleSheet, View } from "react-native";

import { EmptyList } from "@/common/EmptyList";

import { useTasksFlatList } from "../../../api/tasks";

import { ManagementNotesItem } from "./JobManagementNotesItem";

export interface JobManagementNotesProps {
  id: string;
}

export const JobManagementNotes = ({ id: jobId }: JobManagementNotesProps) => {
  const { fonts, shadows } = useTheme();
  const flatListProps = useTasksFlatList(jobId, { type: "management-notes" });

  return (
    <View style={styles.container}>
      <Typography style={[styles.headerTitle, fonts.semibold]}>
        Management Notes
      </Typography>
      <View style={[styles.listContainer, shadows.regular]}>
        <FlatList
          style={styles.flatlist}
          keyExtractor={(item) => item.id}
          ItemComponent={ManagementNotesItem}
          LoadingItemComponent={ListItemLoading}
          ListEmptyComponent={() => <EmptyList>No Management Notes</EmptyList>}
          {...flatListProps}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    marginTop: 32,
    paddingHorizontal: 32,
  },
  headerTitle: {
    fontSize: 18,
  },
  flatlist: {
    borderRadius: 8,
  },
  listContainer: {
    marginTop: 16,
    flex: 1,
    borderRadius: 8,
  },
});

const ListItemLoading = () => (
  <ListItemLoadingContainer>
    <ListItemLoadingValue />
  </ListItemLoadingContainer>
);
