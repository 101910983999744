import * as React from "react";
import { StyleSheet, View } from "react-native";

import { Breadcrumbs } from "@smartrent/ui";

import Layout from "@/layout/Layout";

import { useGlobalContext } from "@/layout/Context";

import { ScopeOfWorkTable } from "@/pages/scope-of-work/ScopeOfWorkTable";
import { useDocumentTitle } from "@/hooks/use-document-title";

export const ScopeOfWork: React.VFC = () => {
  useDocumentTitle("Scopes of Work");
  const { location } = useGlobalContext();
  return (
    <Layout>
      <View style={styles.container}>
        <Breadcrumbs
          items={[
            {
              label: "Home",
              to: location?.href?.replaceAll("/props", "") || "/",
            },
            { label: "Scope of Work", to: "/" },
          ]}
        />
      </View>

      <ScopeOfWorkTable style={styles.table} />
    </Layout>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: 24,
  },
  table: {
    margin: 24,
  },
});
